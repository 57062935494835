import React from "react";
import { Box, Grid, Link } from "@mui/material";
import { LinkedIn } from "@mui/icons-material";
import Copyright from "../components/Copyright";

// import landing1 from "./../images/Asset1.svg";
import landing1 from "./../images/Funding1.svg";
import landing2 from "./../images/Artboard4.png";
import ismail from "./../images/ismail.jpg";
import yehya1 from "./../images/IMG-0539.jpg";
import katie1 from "./../images/image1.jpeg";
import logosmall from "./../images/Asset5@4x.png";
import LandingLayout from "../components/LandingLayout";
import EmailForm from "forms/EmailForm";
import { sendFundingEmail } from "../api";
import LandingFooter from "../components/LandingFooter";
{
  /* <a href="https://www.freepik.com/free-vector/set-with-doctor-medical-people-working-research-online-cartoon-charactor-flat-illustration-medical-concept_13330900.htm">Image by jcomp</a> on Freepik Set with doctor and medical people working or research online in cartoon charactor, flat illustration, medical concept*/
}
{
  /* <a href="https://www.freepik.com/free-vector/medical-good-team-hospital-staff-doctors-nurse-illustration_13400062.htm">Image by macrovector</a> on Freepik Medical good team. hospital staff doctors and nurse. illustration */
}

export default function FundingPage({}) {
  return (
    <LandingLayout>
      <Grid
        container
        padding={"none"}
        sx={{
          //   mt: 5,
          mt: 9,
          width: "100%",
        }}
      >
        <Grid container item xs={12} md={12} lg={12}>
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            sx={{
              maxHeight: "inherit",
            }}
          >
            <Box
              bgcolor={"primary.main"}
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              <img
                src={landing1}
                alt="Leaflit"
                style={{
                  marginLeft: "45px",
                  width: "100%",
                  height: "100%",
                  maxHeight: "inherit",
                  marginTop: 2,
                  paddingBottom: 1,
                }}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            sx={{
              maxHeight: "inherit",
            }}
          >
            <Box
              bgcolor={"#f2f2f2"}
              sx={{
                width: "100%",
                maxHeight: "inherit",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  m: { xs: 0, sm: 0, md: 3 },
                }}
              >
                <EmailForm
                  emailApi={sendFundingEmail}
                  title={"For investment inquiries contact us below"}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <LandingFooter />
        <Grid item>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Grid>
      </Grid>
    </LandingLayout>
  );
}
