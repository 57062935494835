import React from "react";
import {
  groupModel,
  ingredientGroupModel,
  ingredientModel,
  manufacturerModel,
  brandNameModel,
  doseFormModel,
  sideEffectGroupModel,
  sideEffectModel,
  targetModel,
  drugPackModel,
  legendModel,
  sourceModel,
  moAModel,
  scheduleModel,
  clinicalDrugModel,
  labelModel,
  beersCriteriaModel,
  patientModel,
} from "../models";
import { Props } from "helpers/types";
import FindByModelPage from "pages/FindByModelPage";
import ViewPage from "pages/ViewPage";
import SideEffectsViewPage from "pages/SideEffectsViewPage";
import PatientViewPage from "pages/PatientViewPage";
import PatientFindPage from "pages/PatientFindPage";
function mockFunction(props: Props) {
  return <></>;
}
const ViewAllDocPage = mockFunction;
const viewRoutes = {
  path: "view",
  children: [
    {
      path: "legend",
      children: [
        {
          path: "",
          element: <ViewAllDocPage model={legendModel} />,
        },
        {
          path: ":id",
          element: <ViewPage model={legendModel} />,
        },
      ],
    },
    {
      path: "source",
      children: [
        {
          path: "",
          element: <ViewAllDocPage model={sourceModel} />,
        },
        {
          path: ":id",
          element: <ViewPage model={sourceModel} />,
        },
      ],
    },
    {
      path: "group",
      children: [
        {
          path: "",
          element: <FindByModelPage model={groupModel} />,
        },
        {
          path: ":id",
          element: <ViewPage model={groupModel} />,
        },
      ],
    },
    {
      path: "ingredientgroup",
      children: [
        {
          path: "",
          element: <FindByModelPage model={ingredientGroupModel} />,
        },
        {
          path: ":id",
          element: <ViewPage model={ingredientGroupModel} />,
        },
      ],
    },
    {
      path: "ingredient",
      children: [
        {
          path: "",
          element: <FindByModelPage model={ingredientModel} />,
        },
        {
          path: "sideeffects",
          children: [
            {
              path: ":id",
              element: (
                <SideEffectsViewPage
                  model={ingredientModel}
                  secondaryModel={sideEffectModel}
                />
              ),
            },
          ],
        },
        {
          path: ":id",
          element: <ViewPage model={ingredientModel} />,
        },
      ],
    },
    {
      path: "manufacturer",
      children: [
        {
          path: "",
          element: <FindByModelPage model={manufacturerModel} />,
        },
        {
          path: ":id",
          element: <ViewPage model={manufacturerModel} />,
        },
      ],
    },
    {
      path: "brandname",
      children: [
        {
          path: "",
          element: <FindByModelPage model={brandNameModel} />,
        },
        {
          path: "sideeffects",
          children: [
            {
              path: ":id",
              element: (
                <SideEffectsViewPage
                  model={brandNameModel}
                  secondaryModel={sideEffectModel}
                />
              ),
            },
          ],
        },
        {
          path: ":id",
          element: <ViewPage model={brandNameModel} />,
        },
      ],
    },
    {
      path: "doseform",
      children: [
        {
          path: "",
          element: <FindByModelPage model={doseFormModel} />,
        },
        {
          path: "sideeffects",
          children: [
            {
              path: ":id",
              element: (
                <SideEffectsViewPage
                  model={doseFormModel}
                  secondaryModel={sideEffectModel}
                />
              ),
            },
          ],
        },
        {
          path: ":id",
          element: <ViewPage model={doseFormModel} />,
        },
      ],
    },
    {
      path: "sideeffectgroup",
      children: [
        {
          path: "",
          element: <FindByModelPage model={sideEffectGroupModel} />,
        },
        {
          path: ":id",
          element: <ViewPage model={sideEffectGroupModel} />,
        },
      ],
    },
    {
      path: "sideeffect",
      children: [
        {
          path: "",
          element: <FindByModelPage model={sideEffectModel} />,
        },
        {
          path: ":id",
          element: <ViewPage model={sideEffectModel} />,
        },
      ],
    },
    {
      path: "target",
      children: [
        {
          path: "",
          element: <FindByModelPage model={targetModel} />,
        },
        {
          path: ":id",
          element: <ViewPage model={targetModel} />,
        },
      ],
    },
    {
      path: "drugpack",
      children: [
        {
          path: "",
          element: <FindByModelPage model={drugPackModel} />,
        },
        {
          path: "sideeffects",
          children: [
            {
              path: ":id",
              element: (
                <SideEffectsViewPage
                  model={drugPackModel}
                  secondaryModel={sideEffectModel}
                />
              ),
            },
          ],
        },
        {
          path: ":id",
          element: <ViewPage model={drugPackModel} />,
        },
      ],
    },
    {
      path: "moa",
      children: [
        {
          path: "",
          element: <FindByModelPage model={moAModel} />,
        },
        {
          path: ":id",
          element: <ViewPage model={moAModel} />,
        },
      ],
    },
    {
      path: "schedule",
      children: [
        {
          path: "",
          element: <FindByModelPage model={scheduleModel} />,
        },
        {
          path: ":id",
          element: <ViewPage model={scheduleModel} />,
        },
      ],
    },
    {
      path: "clinicaldrug",
      children: [
        {
          path: "",
          element: <FindByModelPage model={clinicalDrugModel} />,
        },
        {
          path: "sideeffects",
          children: [
            {
              path: ":id",
              element: (
                <SideEffectsViewPage
                  model={clinicalDrugModel}
                  secondaryModel={sideEffectModel}
                />
              ),
            },
          ],
        },
        {
          path: ":id",
          element: <ViewPage model={clinicalDrugModel} />,
        },
      ],
    },
    {
      path: "label",
      children: [
        {
          path: "",
          element: <FindByModelPage model={labelModel} />,
        },
        {
          path: ":id",
          element: <ViewPage model={labelModel} />,
        },
      ],
    },
    {
      path: "beerscriteria",
      children: [
        {
          path: "",
          element: <FindByModelPage model={beersCriteriaModel} />,
        },
        {
          path: ":id",
          element: <ViewPage model={beersCriteriaModel} />,
        },
      ],
    },
    {
      path: "patient",
      children: [
        {
          path: "",
          element: <PatientFindPage model={patientModel} />,
        },
        {
          path: ":id",
          element: <PatientViewPage model={patientModel} />,
        },
      ],
    },
  ],
};
export default viewRoutes;
