import { idMapValidation } from "../schema/commonSchema";
import {
  SideEffectGroup,
  Label,
  Ingredient,
  Target,
  SideEffect,
} from "./basicModels";

SideEffect.relations = [
  /* -------------------------------------------------------------------------- */
  /*                             Outgoing Relations                             */
  /* -------------------------------------------------------------------------- */
  {
    relatedModel: SideEffectGroup,
    name: "PrimarySOC",
    displayName: "Has Primary SOC",
    properties: [],
    schema: idMapValidation,
  },
  /* -------------------------------------------------------------------------- */
  /*                             Incoming Relations                             */
  /* -------------------------------------------------------------------------- */
  {
    relatedModel: Label,
    name: "SideEffectOfLabel",
    displayName: "Caused In",
    properties: [],
    schema: idMapValidation,
  },
  {
    relatedModel: Ingredient,
    name: "SideEffectOfIngredient",
    displayName: "Caused By",
    properties: [],
    schema: idMapValidation,
  },
  {
    relatedModel: Target,
    name: "IsTarget",
    displayName: "Is",
    properties: [],
    schema: idMapValidation,
  },
  {
    relatedModel: SideEffectGroup,
    name: "IsPTOf",
    displayName: "PT Of",
    properties: [],
    schema: idMapValidation,
  },
  /* -------------------------------------------------------------------------- */
  /*                               Self Relations                               */
  /* -------------------------------------------------------------------------- */
  {
    relatedModel: SideEffect,
    name: "HasLLT",
    displayName: "Has LLT",
    properties: [],
    schema: idMapValidation,
  },
  {
    relatedModel: SideEffect,
    name: "IsLLT",
    displayName: "Is LLT",
    properties: [],
    schema: idMapValidation,
  },
];
export const sideEffectModel = SideEffect;
