import { titleCase } from "helpers/common";
import Table from "./Table";
import { Box, Typography } from "@mui/material";
import { typeCheck } from "helpers/typeCheck";
import { List as MuiList, ListItem, ListItemText } from "@mui/material";
export default function DynamicDisplay({
  dataKey,
  value,
  filterList,
}: {
  dataKey?: string;
  value: any;
  filterList?: string[];
}) {
  if (!value) return <></>;
  if (
    typeCheck("String", value) ||
    typeCheck("Number", value) ||
    typeCheck("Boolean", value)
  )
    return (
      <Typography key={dataKey} component="h1" variant="body1">
        {`${titleCase(dataKey)}: ${value.toString()}`}
      </Typography>
    );
  if (typeCheck("[String]", value))
    return (
      <MuiList sx={{ overflow: "auto", maxHeight: "400px" }}>
        {value.map((val: string, index: number) => (
          <ListItem key={`${index}-${value}`}>
            <ListItemText primary={`${index + 1} - ${val}`} />
          </ListItem>
        ))}
      </MuiList>
    );
  if (typeCheck("[ObjectKeyString]", value)) {
    const keys = Object.keys(value[0]);
    const tableKeys = keys
      .map((key) => {
        if (filterList && filterList.includes(key)) return null;
        return {
          key,
          display: titleCase(key),
        };
      })
      .flatMap((fl) => (fl === null ? [] : fl));
    return (
      <Table
        searchOn={false}
        fillEmpty={false}
        tableKeys={tableKeys}
        tableName={dataKey ? titleCase(dataKey) : undefined}
        data={value}
      />
    );
  }
  if (typeCheck("Object", value)) {
    return (
      <>
        {Object.keys(value)
          .map((key, index) => {
            const val = value[key];
            if (filterList && filterList.includes(key)) return null;
            return (
              <DynamicDisplay
                dataKey={key}
                value={val}
                key={`${index}-${key}`}
              />
            );
          })
          .flatMap((fl) => (fl === null ? [] : fl))}
      </>
    );
  }
  console.warn(`Could not render ${dataKey} with value ${value}`);
  return <></>;
}
