import Popover from "@mui/material/Popover";
import { useState } from "react";
import { Props } from "helpers/types";

export default function BasicPopover({
  children,
  button,
  onClick,
  anchorOrigin,
  transformOrigin,
}: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    if (onClick) onClick();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      {button(handleClick)}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={
          anchorOrigin
            ? anchorOrigin
            : {
                vertical: "bottom",
                horizontal: "right",
              }
        }
        transformOrigin={
          transformOrigin
            ? transformOrigin
            : {
                vertical: "top",
                horizontal: "right",
              }
        }
      >
        {typeof children === "function" ? children() : children}
      </Popover>
    </>
  );
}
