import { Data } from "../helpers/types";
import { getReq, postReq } from "./instance";

export const getClinicalDrug = async (clinicalDrugId: string, params = {}) => {
  return getReq(`/ClinicalDrug/${clinicalDrugId}`, params);
};
export const getClinicalDrugSideEffects = async (
  properties: {},
  params = {},
) => {
  return postReq(`ClinicalDrug/sideeffects`, properties, params);
};

export const getClinicalDrugSideEffectPaths = async (
  sideEffectId: string,
  properties: Data,
  params = {},
) => {
  return postReq(
    `ClinicalDrug/sideeffects/${sideEffectId}`,
    properties,
    params,
  );
};
