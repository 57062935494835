import { Box, Paper } from "@mui/material";
import Path from "components/Path";
import Title from "components/Title";

export default function PathView({
  paths,
  title,
}: {
  paths: any[];
  title?: string;
}) {
  if (paths.length === 0) return <></>;
  return (
    <Paper
      elevation={1}
      sx={{ height: "700px", margin: "50px", overflow: "auto", p: 1 }}
    >
      {title && (
        <Box sx={{ m: 3 }}>
          <Title>{title}</Title>
        </Box>
      )}
      {paths.map((path, index) => {
        return <Path path={path} key={index} />;
      })}
    </Paper>
  );
}
