import { Data } from "../helpers/types";
import { getReq, postReq } from "./instance";

export const getLabel = async (labelId: string, params = {}) => {
  return getReq(`/Label/${labelId}`, params);
};
export const getLabelSideEffects = async (properties: {}, params = {}) => {
  return postReq(`Label/sideeffects`, properties, params);
};

export const getLabelRelations = async (id: string, params = {}) => {
  return postReq(`/Label/${id}`, params);
};

export const getLabelSideEffectPaths = async (
  sideEffectId: string,
  properties: Data,
  params = {},
) => {
  return postReq(`Label/sideeffects/${sideEffectId}`, properties, params);
};
