import React, { useContext, useEffect, useState } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import {
  Logout,
  Settings,
  ExpandLess,
  ExpandMore,
  QueryStats,
  AccountBox,
} from "@mui/icons-material";
import { logOut } from "../api/auth";
import { Link, useNavigate } from "react-router-dom";
import { isAdmin, setGlobal } from "../helpers/globals";
import { Collapse, Divider } from "@mui/material";
import { AllListItemLinks } from "./ListItemsLinks";
import { PrefixContext } from "context";

export const MainListItems = ({ open }: { open: boolean }) => {
  const { prefix } = useContext(PrefixContext);
  const [expandedSearch, setExpandedSearch] = useState(open);
  const closeAll = () => {
    setExpandedSearch(false);
  };
  useEffect(() => {
    if (open === false) {
      closeAll();
    }
  }, [open]);
  const {
    quickSearch,
    dashboard,
    dataEntryList,
    searchList,
    findBy,
    patients,
    addPatients,
  } = AllListItemLinks();
  const adminList = (
    <>
      {dashboard}
      {quickSearch}
      {findBy}
      {patients}
      {addPatients}
      <ListItemButton
        onClick={() => {
          closeAll();
          setExpandedSearch(!expandedSearch);
        }}
      >
        <ListItemIcon>
          <QueryStats />
        </ListItemIcon>
        <ListItemText primary={"Search"} />
        {expandedSearch ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <Collapse in={expandedSearch}>
        <Divider sx={{ my: 1 }} />
        {searchList}
        <Divider sx={{ my: 1 }} />
      </Collapse>
    </>
  );
  const standardList = (
    <>
      {dashboard}
      {quickSearch}
      {findBy}
      {patients}
      {addPatients}
      <ListItemButton
        onClick={() => {
          closeAll();
          setExpandedSearch(!expandedSearch);
        }}
      >
        <ListItemIcon>
          <QueryStats />
        </ListItemIcon>
        <ListItemText primary={"Search"} />
        {expandedSearch ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <Collapse in={expandedSearch}>
        <Divider sx={{ my: 1 }} />
        {searchList}
        <Divider sx={{ my: 1 }} />
      </Collapse>
    </>
  );
  let list;
  switch (prefix) {
    case "/admin":
      list = adminList;
      break;
    case "/standard":
      list = standardList;
      break;
    case "/guest":
      list = standardList;
      break;
    case "/dataentry":
      list = dataEntryList;
      break;
    default:
      list = <></>;
  }

  return <React.Fragment>{list}</React.Fragment>;
};

export const SecondaryListItems = () => {
  const { prefix } = useContext(PrefixContext);
  const isUserAdmin = isAdmin();
  const navigate = useNavigate();
  const userSettings = (
    <ListItemButton component={Link} to={`${prefix}/users`}>
      <ListItemIcon>
        <Settings />
      </ListItemIcon>
      <ListItemText primary="User Settings" />
    </ListItemButton>
  );
  const userProfile = (
    <ListItemButton component={Link} to={`${prefix}/user`}>
      <ListItemIcon>
        <AccountBox />
      </ListItemIcon>
      <ListItemText primary="Profile" />
    </ListItemButton>
  );
  const logout = (
    <ListItemButton
      component={Link}
      to={`/login`}
      onClick={async (e) => {
        e.preventDefault();
        await logOut();
        setGlobal("accessToken", "");
        navigate("/login");
      }}
    >
      <ListItemIcon>
        <Logout />
      </ListItemIcon>
      <ListItemText primary="Logout" />
    </ListItemButton>
  );
  return (
    <React.Fragment>
      <ListSubheader component="div" inset>
        Settings
      </ListSubheader>
      {isUserAdmin && userSettings}
      {prefix !== "/admin" && userProfile}
      {logout}
    </React.Fragment>
  );
};
