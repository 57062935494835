import { Edit, Stop } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import GetDate from "./GetDate";
import useConfirm from "hooks/useConfirm";

export default function DateStopped({
  dateStopped,
  onClick,
}: {
  dateStopped: number;
  onClick: (date: number) => void;
}) {
  const [getConfirmation, Confirmation] = useConfirm<Promise<number>>(
    (onChange) => <GetDate onConfirm={onChange} />,
  );

  const getDate = async (): Promise<number> => {
    return await getConfirmation(`Choose date prescription was stopped`);
  };
  return (
    <Box>
      <IconButton
        sx={{ height: "100%" }}
        size="small"
        onClick={async () => {
          const date = await getDate();
          if (date) onClick(date);
        }}
      >
        {dateStopped ? <Edit fontSize="small" /> : <Stop fontSize="small" />}
      </IconButton>
      <Confirmation />
    </Box>
  );
}
