import { ReactNode, useState } from "react";
import { Box, Dialog } from "@mui/material";
import Title from "components/Title";

function createPromise<T>(): [Promise<T>, (value: any) => void] {
  let resolver = (value: any) => {};
  return [
    new Promise((resolve, reject) => {
      resolver = resolve;
    }),
    resolver,
  ];
}
export function useConfirm<T>(
  children?: (onChange: any) => ReactNode,
): [(title: string) => Promise<T>, () => JSX.Element] {
  const [open, setOpen] = useState(false);
  const [label, setLabel] = useState("");
  const [resolver, setResolver] = useState<{
    resolve: (value: any) => void;
  } | null>(null);

  const getConfirmation = async (title: string) => {
    setLabel(title);
    setOpen(true);
    const [promise, resolve] = createPromise<T>();
    setResolver({ resolve: resolve });
    return promise;
  };

  const onClick = async (status: boolean) => {
    setOpen(false);
    if (resolver) resolver.resolve(status);
  };

  const Confirmation = () => (
    <Dialog open={open}>
      <Box sx={{ p: 2 }}>
        <Title>{label}</Title>
        {children && children(onClick)}
      </Box>
    </Dialog>
  );
  return [getConfirmation, Confirmation];
}

export default useConfirm;
