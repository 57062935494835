import ProOptionsPage from "pages/ProOptionsPage";
import PricingPage from "../pages/PricingPage";
import UserProfilePage from "../pages/UserProfilePage";

export const userRoutes = [
  {
    path: "",
    element: <UserProfilePage />,
  },
  {
    path: "payment",
    element: <PricingPage />,
  },
  {
    path: "prooptions",
    element: <ProOptionsPage />,
  },
  { path: "subscription", element: <UserProfilePage /> },
];
