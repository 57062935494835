import { Data } from "../helpers/types";
import { getReq, postReq } from "./instance";

export const getDrugPack = async (drugPackId: string, params = {}) => {
  return getReq(`/DrugPack/${drugPackId}`, params);
};
export const getDrugPackSideEffects = async (properties: {}, params = {}) => {
  return postReq(`DrugPack/sideeffects`, properties, params);
};

export const getDrugPackSideEffectPaths = async (
  sideEffectId: string,
  properties: Data,
  params = {},
) => {
  return postReq(`DrugPack/sideeffects/${sideEffectId}`, properties, params);
};
