import { Navigate } from "react-router-dom";
import LoginPage from "pages/LoginPage";
import SignUpPage from "pages/SignUpPage";
import ResetPasswordPage from "pages/ResetPasswordPage";
import LandingPage from "pages/LandingPage";
import FundingPage from "pages/FundingPage";
import SubscriptionSuccess from "pages/SubscriptionSuccess";
import TesterRequestPage from "pages/TesterRequestPage";
import TermsOfServicePage from "pages/TermsOfServicePage";
import { loginLoader } from "loaders";

export const rootRoutes = [
  {
    path: "",
    element: <LandingPage />,
  },
  {
    path: "funding",
    element: <FundingPage />,
  },
  {
    path: "login",
    element: <LoginPage />,
    loader: loginLoader,
  },
  {
    path: "landing",
    element: <LandingPage />,
  },
  {
    path: "signup",
    element: <SignUpPage />,
  },
  {
    path: "resetpassword",
    element: <ResetPasswordPage />,
  },
  { path: "subsuccess", element: <SubscriptionSuccess /> },
  { path: "testerrequest", element: <TesterRequestPage /> },
  { path: "termsofservice", element: <TermsOfServicePage /> },
  { path: "/", element: <Navigate to="/login" /> },
];
