import { Box, Button, Grid, Typography } from "@mui/material";

export default function MultipleChoiceInput({
  label,
  valueOptions,
  onClick,
}: {
  label: string;
  valueOptions: { display: string; value: number }[];
  onClick: Function;
}) {
  return (
    <Box>
      <Typography
        component="h1"
        variant="h4"
        textAlign={"center"}
        sx={{ m: 2 }}
      >
        {label}
      </Typography>
      <Grid container>
        {valueOptions.map((vo, index) => {
          return (
            <Grid
              key={`${vo.value}-${index}`}
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                sx={{ width: "60vw", m: 1 }}
                onClick={() => onClick(vo.value)}
              >
                {vo.display}
              </Button>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
