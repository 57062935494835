import * as React from "react";
import { allGlobals } from "../helpers/globals";
import { Container, Paper, Typography } from "@mui/material";
// If using TypeScript, add the following snippet to your file as well.
declare global {
  namespace JSX {
    interface IntrinsicElements {
      "stripe-pricing-table": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}
function PricingPage() {
  const { stripeId: userId, email } = allGlobals().user;
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper sx={{ pb: 6 }}>
        <Container
          disableGutters
          maxWidth="sm"
          component="main"
          sx={{ pt: 8, pb: 6 }}
        >
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
          >
            Pricing
          </Typography>
        </Container>
        <stripe-pricing-table
          pricing-table-id={process.env.REACT_APP_PRICING_TABLE_ID}
          publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
          customer-email={email}
          client-reference-id={userId}
          customer-id={userId}
          customer-creation="if-required"
        ></stripe-pricing-table>
      </Paper>
    </Container>
  );
}

export default PricingPage;
