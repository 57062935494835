import { Route } from "@mui/icons-material";
import { Box, IconButton, useTheme } from "@mui/material";
import Container, { DefaultDraw } from "components/Archer";
import ModelCard from "components/ModelCard";
import RelationshipList from "components/RelationshipList";
import RelationshipBox from "components/RelationshipsBox";
import { ListKeys } from "helpers/types";
import _ from "lodash";
import PathView from "./PathView";
import PathBox from "components/PathBox";

export default function SideEffectsView({
  result,
  listKeys,
  expandedResultsListKeys,
  expandedResults,
  onClick,
}: {
  result: any;
  listKeys: ListKeys;
  expandedResultsListKeys: ListKeys;
  expandedResults?: any;
  onClick: (id: string) => void;
}) {
  const { relations } = listKeys;
  const { relations: expandedRelations } = expandedResultsListKeys;
  const theme = useTheme();
  const elements = relations.map((listKey) => ({
    id: listKey.name,
    arrow: <Box sx={{ mt: "-20px" }}>{listKey.displayName}</Box>,
    children: (
      <RelationshipBox listKey={listKey}>
        <RelationshipList
          relationships={result[listKey.name]}
          listKey={listKey}
          tableElements={(row) => (
            <IconButton onClick={() => onClick(row.id)}>
              <Route />
            </IconButton>
          )}
        />
      </RelationshipBox>
    ),
    relation: result.id,
  }));
  const expandedElements = expandedResults.id
    ? expandedRelations
        .filter((listKey) => expandedResults[listKey.name].length !== 0)
        .map((listKey) => ({
          id: listKey.name,
          arrow: <Box sx={{ mt: "-20px" }}>{listKey.displayName}</Box>,
          children: (
            <PathBox listKey={listKey}>
              <PathView
                paths={expandedResults[listKey.name]}
                title={listKey.displayName}
              />
            </PathBox>
          ),
          relation: expandedResults.id,
        }))
    : [];
  return (
    <div style={{ height: "700px", margin: "50px" }}>
      <Container strokeColor={theme.palette.primary.main}>
        <DefaultDraw
          root={[
            {
              id: result.id,
              children: <ModelCard modelElement={result} />,
              rootRelations:
                expandedResults && expandedResults.id
                  ? [
                      {
                        targetId: expandedResults.id,
                        targetAnchor: "top",
                        sourceAnchor: "bottom",
                        style: {
                          strokeWidth: 1,
                          strokeDasharray: "10",
                        },
                        label: <Box sx={{ mt: "-20px" }}>SideEffect</Box>,
                      },
                    ]
                  : [],
            },
          ]}
          elements={elements}
        />
        {expandedResults && expandedResults.id && (
          <DefaultDraw
            root={[
              {
                id: expandedResults.id,
                children: <ModelCard modelElement={expandedResults} />,
              },
            ]}
            elements={expandedElements}
            style={{
              strokeColor: theme.palette.secondary.main,
              strokeWidth: 1,
              strokeDasharray: "5",
            }}
          />
        )}
      </Container>
    </div>
  );
}
