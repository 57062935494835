import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { resetPasswordValidationSchema } from "../schema/signupSchema";
import { resetPassword } from "../api";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import ApiStatus from "../components/ApiStatus";
import useApiHook from "../hooks/useApiHook";
type Inputs = {
  email: string;
  password: string;
  newPassword: string;
  confirmNewPassword: string;
};
export default function ResetPassword() {
  const [user, resetPassUser] = useApiHook(null, resetPassword);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
  } = useForm<Inputs>({ resolver: yupResolver(resetPasswordValidationSchema) });
  const onSubmit: SubmitHandler<Inputs> = async (values: Inputs) => {
    const { email, password, newPassword } = values;
    const userLogin = await resetPassUser(email, password, newPassword);

    if (userLogin) {
      navigate("/login");
    }
  };
  return (
    <Container component="main" maxWidth="xs">
      <ApiStatus error={user.error} />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            autoComplete="email"
            autoFocus
            {...register("email")}
            error={touchedFields.email && Boolean(errors.email)}
            helperText={touchedFields.email && errors.email?.message}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            {...register("password")}
            error={touchedFields.password && Boolean(errors.password)}
            helperText={touchedFields.password && errors.password?.message}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="New Password"
            type="password"
            id="newPassword"
            autoComplete="current-password"
            {...register("newPassword")}
            error={touchedFields.newPassword && Boolean(errors.newPassword)}
            helperText={
              touchedFields.newPassword && errors.newPassword?.message
            }
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Confirm New Password"
            type="password"
            id="confirmNewPassword"
            autoComplete="current-password"
            {...register("confirmNewPassword")}
            error={
              touchedFields.confirmNewPassword &&
              Boolean(errors.confirmNewPassword)
            }
            helperText={
              touchedFields.confirmNewPassword &&
              errors.confirmNewPassword?.message
            }
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link variant="body2" component={RouterLink} to="/resetpassword">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link variant="body2" component={RouterLink} to="/signup">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
