import * as yup from "yup";
import { idMapValidation } from "../schema/commonSchema";
import { Ingredient, Group, BeersCriteria } from "./basicModels";

BeersCriteria.relations = [
  /* -------------------------------------------------------------------------- */
  /*                             Outgoing Relations                             */
  /* -------------------------------------------------------------------------- */
  {
    relatedModel: Ingredient,
    name: "AffectedByIngredient",
    displayName: "Affected by",
    properties: [
      {
        key: "Notes",
        property: "notes",
        validation: yup.string(),
        type: "string",
        default: "",
      },
    ],
    schema: idMapValidation,
  },
  {
    relatedModel: Group,
    name: "AffectedByGroup",
    displayName: "Affected by",
    properties: [
      {
        key: "Notes",
        property: "notes",
        validation: yup.string(),
        type: "string",
        default: "",
      },
    ],
    schema: idMapValidation,
  },
  {
    relatedModel: Ingredient,
    name: "ExcludeIngredient",
    displayName: "Excludes",
    properties: [
      {
        key: "Notes",
        property: "notes",
        validation: yup.string(),
        type: "string",
        default: "",
      },
    ],
    schema: idMapValidation,
  },
  {
    relatedModel: Group,
    name: "GroupInteractionTwo",
    displayName: "Group two",
    properties: [
      {
        key: "Notes",
        property: "notes",
        validation: yup.string(),
        type: "string",
        default: "",
      },
    ],
    schema: idMapValidation,
  },
  {
    relatedModel: Ingredient,
    name: "IngredientInteractionTwo",
    displayName: "group two",
    properties: [
      {
        key: "Notes",
        property: "notes",
        validation: yup.string(),
        type: "string",
        default: "",
      },
    ],
    schema: idMapValidation,
  },
  /* -------------------------------------------------------------------------- */
  /*                             Incoming Relations                             */
  /* -------------------------------------------------------------------------- */
  {
    relatedModel: Group,
    name: "GroupInteractionOneIn",
    displayName: "Group One",
    properties: [],
    schema: idMapValidation,
  },
  {
    relatedModel: Ingredient,
    name: "IngredientInteractionOneIn",
    displayName: "Group One",
    properties: [],
    schema: idMapValidation,
  },
  /* -------------------------------------------------------------------------- */
  /*                               Self Relations                               */
  /* -------------------------------------------------------------------------- */
];
export const beersCriteriaModel = BeersCriteria;
