import React from "react";
import { Box, Typography, Grid, Avatar, Link } from "@mui/material";
import {
  Biotech,
  Facebook,
  Instagram,
  LinkedIn,
  AccountTree,
  BubbleChart,
} from "@mui/icons-material";
import Copyright from "../components/Copyright";

import landing1 from "./../images/Asset1.svg";
import landing2 from "./../images/Artboard4.png";
import ismail from "./../images/ismail.jpeg";
import yehya1 from "./../images/IMG-0539.jpg";
import katie1 from "./../images/image1.jpeg";
import logosmall from "./../images/Asset5@4x.png";
import LandingLayout from "components/LandingLayout";
import LandingFooter from "components/LandingFooter";
{
  /* <a href="https://www.freepik.com/free-vector/set-with-doctor-medical-people-working-research-online-cartoon-charactor-flat-illustration-medical-concept_13330900.htm">Image by jcomp</a> on Freepik Set with doctor and medical people working or research online in cartoon charactor, flat illustration, medical concept*/
}
{
  /* <a href="https://www.freepik.com/free-vector/medical-good-team-hospital-staff-doctors-nurse-illustration_13400062.htm">Image by macrovector</a> on Freepik Medical good team. hospital staff doctors and nurse. illustration */
}

export default function LandingPage({}) {
  return (
    <LandingLayout>
      <Grid
        container
        padding={"none"}
        sx={{
          mt: { xs: 9, sm: 9, md: 8 },
          width: "100%",
        }}
      >
        <Grid container item xs={12} md={12} lg={12}>
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            bgcolor={"primary.main"}
            sx={{
              maxHeight: "inherit",
            }}
          >
            <Box
              bgcolor={"primary.main"}
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              <img
                src={landing1}
                alt="Leaflit"
                style={{
                  marginLeft: "45px",
                  width: "80%",
                  height: "100%",
                  maxHeight: "inherit",
                  marginTop: 2,
                  paddingBottom: 1,
                }}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            sx={{
              maxHeight: "inherit",
            }}
          >
            <Box
              bgcolor={"#f2f2f2"}
              sx={{
                width: "100%",
                maxHeight: "inherit",
                display: { xs: "none", sm: "none", md: "flex" },
                justifyContent: "center",
              }}
            >
              <img
                src={landing2}
                alt="Leaflit"
                style={{
                  height: "100%",
                  maxHeight: "600px",
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid
          item
          container
          sx={{
            m: 4,
          }}
        >
          <Grid item container xs={12} md={4} lg={4}>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
            >
              <AccountTree
                sx={{
                  height: "100%",
                  width: "100px",
                  opacity: "0.3",
                  margin: "0 auto",
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ maxHeight: "80px" }}>
              <Typography
                variant="h4"
                component={"h5"}
                color={"primary"}
                textAlign={"center"}
              >
                Optimise Time
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ m: 4 }}>
              <Typography variant="body1" component={"h5"} textAlign={"center"}>
                {" "}
                Reduce workload spent on reviewing side effects, allergies, drug
                ineractions, dosing and much more
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} md={4} lg={4}>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
            >
              <Biotech
                sx={{
                  height: "100%",
                  width: "100px",
                  opacity: "0.3",
                  margin: "0 auto",
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ maxHeight: "80px" }}>
              <Typography
                variant="h4"
                component={"h5"}
                color={"primary"}
                textAlign={"center"}
              >
                Manage polypharmacy
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ m: 4 }}>
              <Typography variant="body1" component={"h5"} textAlign={"center"}>
                {" "}
                Identify potential interactions and side effects associated with
                patients taking multiple medications
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} md={4} lg={4}>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
            >
              <BubbleChart
                sx={{
                  height: "100%",
                  width: "100px",
                  opacity: "0.3",
                  margin: "0 auto",
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ maxHeight: "80px" }}>
              <Typography
                variant="h4"
                component={"h5"}
                color={"primary"}
                textAlign={"center"}
              >
                Comprehensive database
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ m: 4 }}>
              <Typography variant="body1" component={"h5"} textAlign={"center"}>
                {" "}
                Eliminate the need for multiple search platforms. Incorporating
                an all inclusive patient information analysis
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container bgcolor={"primary.main"}>
          <Grid item xs={12} md={12} lg={12} sx={{ maxHeight: "80px", m: 2 }}>
            <Typography
              variant="h3"
              component={"h5"}
              color={"white"}
              textAlign={"center"}
            >
              Meet the Founders
            </Typography>
          </Grid>
          <Grid item container xs={12} md={4} lg={4}>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <Avatar
                alt="Founder 1"
                src={yehya1}
                sx={{ backgroundColor: "white", width: 200, height: 200, m: 1 }}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ maxHeight: "80px" }}>
              <Typography
                variant="h4"
                component={"h5"}
                color={"white"}
                textAlign={"center"}
              >
                Yehya Kuraydli (CEO)
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ m: 4 }}>
              <Typography
                variant="caption"
                component={"h5"}
                textAlign={"center"}
                color="white"
                sx={{ minHeight: "300px" }}
              >
                {" "}
                Yehya’s path began with Life Sciences and an interest in
                Biology, and then continued into the world of business. He
                graduated with a Msc. in Biotechnology and Management from the
                University of Glasgow. Yehya always gravitates into a position
                of leadership, and in all areas of his life, he is the one that
                people come to for business advice whether its taxes, finances,
                or legal issues. At Leaflit Care Inc, Yehya is responsible for
                managing the company’s overall operations as the Chief Executive
                Officer, and is the acting Chief Financial Officer.
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} md={4} lg={4}>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <Avatar
                alt="Founder 2"
                src={katie1}
                sx={{ backgroundColor: "white", width: 200, height: 200, m: 1 }}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ maxHeight: "80px" }}>
              <Typography
                variant="h4"
                component={"h5"}
                color={"white"}
                textAlign={"center"}
              >
                Katie Peden (CSO)
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ m: 4 }}>
              <Typography
                variant="caption"
                component={"h5"}
                textAlign={"center"}
                color="white"
                sx={{ minHeight: "300px" }}
              >
                {" "}
                Katie has always been a science driven individual, receiving her
                MSc in Biotechnology and Management from Glasgow University. She
                also holds a joint honours degree, majoring in Biochemistry and
                Pharmacology. As Chief Science Officer of Leaflit Care, Katie’s
                responsibilities include the management and development of our
                scientific data.
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} md={4} lg={4}>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <Avatar
                alt="Founder 3"
                src={ismail}
                sx={{ backgroundColor: "white", width: 200, height: 200, m: 1 }}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ maxHeight: "80px" }}>
              <Typography
                variant="h4"
                component={"h5"}
                color={"white"}
                textAlign={"center"}
              >
                Ismail Kuraydli (CTO)
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ m: 4 }}>
              <Typography
                variant="caption"
                component={"h5"}
                textAlign={"center"}
                color="white"
                sx={{ minHeight: "300px" }}
              >
                {" "}
                Ismail has been in the tech industry for around seven years. He
                has held roles in writing code, managing teams, and
                consultation. Working across different industries like password
                management, logistics, banking, insuretech and web3, his
                experience ranges across most layers of implementation, from
                front-end, back-end, to architecture. As Chief Technology
                Officer, Ismail is responsible for building and maintaning the
                platform.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <LandingFooter />
        <Grid item>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Grid>
      </Grid>
    </LandingLayout>
  );
}
