import CssBaseline from "@mui/material/CssBaseline";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import { Props } from "../helpers/types";
const isDev = process.env.NODE_ENV === "development";
let theme = createTheme({
  palette: {
    primary: {
      main: isDev ? "#d72836" : "#2196f3",
    },
    secondary: {
      main: "#9aca3c",
    },
    background: {
      default: "#f5f5f5",
    },
  },
  typography: {},
});
theme = responsiveFontSizes(theme);
export default function MyContainer({ children }: Props) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {typeof children === "function" ? children() : children}
    </ThemeProvider>
  );
}
