import { Data } from "../helpers/types";
import { deleteReq, getReq, postReq, putReq } from "./instance";

export const getUser = async (userId: string) => {
  return getReq(`/users/${userId}`);
};

export const getAllUsers = async () => {
  return getReq("/users");
};

export const getPaymentSecret = async () => {
  return getReq("/users/secret");
};
export const acceptTermsAndConditions = async () => {
  return postReq("/users/acceptterms", {});
};
export const getBillingPortal = async () => {
  return getReq("/users/billingportal");
};

export const createGuestRequest = async (properties: {}) => {
  return postReq("/users/guestrequest", properties);
};

export const editUser = async (userId: string, properties: {}) => {
  return putReq(`/users/${userId}`, properties);
};

export const deleteUser = async (userId: string) => {
  return deleteReq(`/users/${userId}`);
};

export const getByConfig = async (params = {}) => {
  return getReq(`/users/getbyconfig`, params);
};

export const sendFundingEmail = async (properties: Data) => {
  return postReq(`/users/fundingemail`, properties);
};

export const sendTestingRequestEmail = async (properties: Data) => {
  return postReq(`/users/testerrequest`, properties);
};

export const earlyAccessRequest = async (properties: Data) => {
  return postReq(`/users/signuprequest`, properties);
};
