import * as api from "../api";
import * as yup from "yup";
import { Model } from "../helpers/types";
import dayjs from "dayjs";
import ageCalculator from "age-calculator";

export const User: Model = {
  findBy: ["id"],
  label: "User",
  displayName: "Health Care Provider",
  color: "undefined",
  api: {
    getOne: api.getUser,
  },
  schema: [
    {
      key: "email",
      validation: yup.string().required("Ingredient Group email is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "terms_of_service",
      validation: yup
        .string()
        .required("Ingredient Group terms_of_service is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "name",
      validation: yup.string().required("Ingredient Group name is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "access_level",
      validation: yup
        .string()
        .required("Ingredient Group access_level is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "password",
      validation: yup
        .string()
        .required("Ingredient Group password is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "accessLevel",
      validation: yup
        .string()
        .required("Ingredient Group accessLevel is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "stripe_id",
      validation: yup
        .string()
        .required("Ingredient Group stripe_id is required"),
      required: true,
      type: "string",
      default: "",
    },
  ],
};
export const Target: Model = {
  findBy: ["id"],
  label: "Target",
  displayName: "Target",
  color: "#FF933D",
  api: {
    getOne: api.getTarget,
  },
  schema: [
    {
      key: "description",
      validation: yup
        .string()
        .required("Ingredient Group description is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "source_id",
      validation: yup
        .string()
        .required("Ingredient Group source_id is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "name",
      validation: yup.string().required("Ingredient Group name is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "class_details",
      validation: yup
        .string()
        .required("Ingredient Group class_details is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "class_type",
      validation: yup
        .string()
        .required("Ingredient Group class_type is required"),
      required: true,
      type: "string",
      default: "",
    },
  ],
};
export const Ingredient: Model = {
  findBy: ["rxcui", "id"],
  label: "Ingredient",
  displayName: "Ingredient",
  color: "#00DF61",
  api: {
    getOne: api.getIngredient,
    getRelated: api.getIngredientRelations,
    sideEffects: api.getIngredientSideEffects,
    sideEffect: api.getIngredientSideEffectPaths,
  },
  schema: [
    {
      key: "unii_code",
      validation: yup
        .string()
        .required("Ingredient Group unii_code is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "description",
      validation: yup
        .string()
        .required("Ingredient Group description is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "name",
      validation: yup.string().required("Ingredient Group name is required"),
      required: true,
      type: "string",
      default: "",
    },
  ],
};
export const ClinicalDrug: Model = {
  findBy: ["rxcui", "id"],
  label: "ClinicalDrug",
  displayName: "ClinicalDrug",
  color: "#0433FF",
  api: {
    getOne: api.getClinicalDrug,
    sideEffects: api.getClinicalDrugSideEffects,
    sideEffect: api.getClinicalDrugSideEffectPaths,
  },
  schema: [
    {
      key: "name",
      validation: yup.string().required("Ingredient Group name is required"),
      required: true,
      type: "string",
      default: "",
    },
  ],
};
export const SideEffect: Model = {
  findBy: ["meddra_id", "id"],
  label: "SideEffect",
  displayName: "SideEffect",
  color: "#F00436",
  api: {
    getOne: api.getSideEffect,
  },
  schema: [
    {
      key: "meddra_type",
      validation: yup
        .string()
        .required("Ingredient Group meddra_type is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "is_llt",
      validation: yup.string().required("Ingredient Group is_llt is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "intensity",
      validation: yup
        .string()
        .required("Ingredient Group intensity is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "meddra_id",
      validation: yup
        .string()
        .required("Ingredient Group meddra_id is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "other_names",
      validation: yup
        .string()
        .required("Ingredient Group other_names is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "name",
      validation: yup.string().required("Ingredient Group name is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "pt_meddra_id",
      validation: yup
        .string()
        .required("Ingredient Group pt_meddra_id is required"),
      required: true,
      type: "string",
      default: "",
    },
  ],
};
export const Manufacturer: Model = {
  findBy: ["id"],
  label: "Manufacturer",
  displayName: "Manufacturer",
  color: "#BA00FF",
  api: {
    getOne: api.getManufacturer,
  },
  schema: [
    {
      key: "daily_med_id",
      validation: yup
        .string()
        .required("Ingredient Group daily_med_id is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "names",
      validation: yup.string().required("Ingredient Group names is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "description",
      validation: yup
        .string()
        .required("Ingredient Group description is required"),
      required: true,
      type: "string",
      default: "",
    },
  ],
};
export const BrandName: Model = {
  findBy: ["rxcui", "id"],
  label: "BrandName",
  displayName: "BrandName",
  color: "#0398FF",
  api: {
    getOne: api.getBrandName,
    sideEffects: api.getBrandNameSideEffects,
    sideEffect: api.getBrandNameSideEffectPaths,
    getRelated: api.getBrandNameRelations,
  },
  schema: [
    {
      key: "name",
      validation: yup.string().required("Ingredient Group name is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "trademark_name",
      validation: yup
        .string()
        .required("Ingredient Group trademark_name is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "manufacturer",
      validation: yup
        .string()
        .required("Ingredient Group manufacturer is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "min_age",
      validation: yup.string().required("Ingredient Group min_age is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "max_age",
      validation: yup.string().required("Ingredient Group max_age is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "additional_notes",
      validation: yup
        .string()
        .required("Ingredient Group additional_notes is required"),
      required: true,
      type: "string",
      default: "",
    },
  ],
};
export const IngredientGroup: Model = {
  findBy: ["id"],
  label: "IngredientGroup",
  displayName: "IngredientGroup",
  color: "#4F8F00",
  api: {
    getOne: api.getIngredientGroup,
  },
  schema: [
    {
      key: "name",
      validation: yup.string().required("Ingredient Group name is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "description",
      validation: yup
        .string()
        .required("Ingredient Group description is required"),
      required: true,
      type: "string",
      default: "",
    },
  ],
};
export const Group: Model = {
  findBy: ["id"],
  label: "Group",
  displayName: "Group",
  color: "#E26E3A",
  api: {
    getOne: api.getGroup,
  },
  schema: [
    {
      key: "description",
      validation: yup
        .string()
        .required("Ingredient Group description is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "source_id",
      validation: yup
        .string()
        .required("Ingredient Group source_id is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "name",
      validation: yup.string().required("Ingredient Group name is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "class_details",
      validation: yup
        .string()
        .required("Ingredient Group class_details is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "class_type",
      validation: yup
        .string()
        .required("Ingredient Group class_type is required"),
      required: true,
      type: "string",
      default: "",
    },
  ],
};
export const MoA: Model = {
  findBy: ["id"],
  label: "MoA",
  displayName: "MoA",
  color: "#FFED00",
  api: {
    getOne: api.getMoA,
  },
  schema: [
    {
      key: "source_id",
      validation: yup
        .string()
        .required("Ingredient Group source_id is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "description",
      validation: yup
        .string()
        .required("Ingredient Group description is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "name",
      validation: yup.string().required("Ingredient Group name is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "class_details",
      validation: yup
        .string()
        .required("Ingredient Group class_details is required"),
      required: true,
      type: "string",
      default: "",
    },
  ],
};
export const Schedule: Model = {
  findBy: ["id"],
  label: "Schedule",
  displayName: "Schedule",
  color: "#945200",
  api: {
    getOne: api.getSchedule,
  },
  schema: [
    {
      key: "source_id",
      validation: yup
        .string()
        .required("Ingredient Group source_id is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "name",
      validation: yup.string().required("Ingredient Group name is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "description",
      validation: yup
        .string()
        .required("Ingredient Group description is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "class_details",
      validation: yup
        .string()
        .required("Ingredient Group class_details is required"),
      required: true,
      type: "string",
      default: "",
    },
  ],
};
export const DrugPack: Model = {
  findBy: ["rxcui", "id"],
  label: "DrugPack",
  displayName: "DrugPack",
  color: "#FF8AD8",
  api: {
    getOne: api.getDrugPack,
    sideEffects: api.getDrugPackSideEffects,
    sideEffect: api.getDrugPackSideEffectPaths,
  },
  schema: [
    {
      key: "name",
      validation: yup.string().required("Ingredient Group name is required"),
      required: true,
      type: "string",
      default: "",
    },
  ],
};
export const DoseForm: Model = {
  findBy: ["rxcui", "id"],
  label: "DoseForm",
  displayName: "DoseForm",
  color: "#942193",
  api: {
    getOne: api.getDoseForm,
    sideEffects: api.getDoseFormSideEffects,
    sideEffect: api.getDoseFormSideEffectPaths,
  },
  schema: [
    {
      key: "name",
      validation: yup.string().required("Ingredient Group name is required"),
      required: true,
      type: "string",
      default: "",
    },
  ],
};
export const Label: Model = {
  findBy: ["id", "set_id"],
  label: "Label",
  displayName: "Label",
  color: "#5E5E5E",
  api: {
    getOne: api.getLabel,
    getRelated: api.getLabelRelations,
  },
  schema: [
    {
      key: "fda_approved",
      validation: yup
        .string()
        .required("Ingredient Group fda_approved is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "set_id",
      validation: yup.string().required("Ingredient Group set_id is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "name",
      validation: yup.string().required("Ingredient Group name is required"),
      required: true,
      type: "string",
      default: "",
    },
  ],
};
export const BeersCriteria: Model = {
  findBy: ["id"],
  label: "BeersCriteria",
  displayName: "BeersCriteria",
  color: "#005494",
  api: {
    getOne: api.getBeersCriteria,
  },
  schema: [
    {
      key: "risk_rationale",
      validation: yup
        .string()
        .required("Ingredient Group risk_rationale is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "sex",
      validation: yup.string().required("Ingredient Group sex is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "strength_of_recommendation",
      validation: yup
        .string()
        .required("Ingredient Group strength_of_recommendation is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "recommendation",
      validation: yup
        .string()
        .required("Ingredient Group recommendation is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "interaction",
      validation: yup
        .string()
        .required("Ingredient Group interaction is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "quality_of_evidence",
      validation: yup
        .string()
        .required("Ingredient Group quality_of_evidence is required"),
      required: true,
      type: "string",
      default: "",
    },
  ],
};
export const SideEffectGroup: Model = {
  findBy: ["meddra_id", "id"],
  label: "SideEffectGroup",
  displayName: "SideEffectGroup",
  color: "#BB3030",
  api: {
    getOne: api.getSideEffectGroup,
  },
  schema: [
    {
      key: "meddra_id",
      validation: yup
        .string()
        .required("Ingredient Group meddra_id is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "name",
      validation: yup.string().required("Ingredient Group name is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "meddra_type",
      validation: yup
        .string()
        .required("Ingredient Group meddra_type is required"),
      required: true,
      type: "string",
      default: "",
    },
  ],
};
export const NDC: Model = {
  findBy: ["id"],
  label: "NDC",
  displayName: "NDC",
  color: "#FFBA81",
  api: {
    getOne: api.getNDC,
  },
  schema: [
    {
      key: "packaging",
      validation: yup
        .string()
        .required("Ingredient Group packaging is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "name",
      validation: yup.string().required("Ingredient Group name is required"),
      required: true,
      type: "string",
      default: "",
    },
  ],
};
export const GuestRequest: Model = {
  findBy: ["name"],
  label: "GuestRequest",
  displayName: "Guest Request",
  color: "inherit",
  api: {},
  schema: [
    {
      key: "type",
      validation: yup.string().required("Type required"),
      required: true,
      type: "select",
      valueOptions: ["Standard Health Professional", "Data Entry User"],
      default: "Standard Health Professional",
    },
    {
      key: "describeYourself",
      validation: yup.string(),
      type: "string",
      default: "",
    },
  ],
};
export const Legend: Model = {
  findBy: ["label"],
  label: "Legend",
  displayName: "Legend",
  color: "inherit",
  api: {
    getOne: api.getLegend,
  },
  schema: [
    {
      key: "label",
      validation: yup.string().required("Legend label is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "nodeDescription",
      validation: yup.string(),
      type: "string",
      default: "",
    },
    {
      key: "nodeDescriptionShort",
      validation: yup.string(),
      type: "string",
      default: "",
    },
  ],
};
export const Source: Model = {
  findBy: ["name"],
  label: "Source",
  displayName: "Source",
  color: "inherit",
  api: {
    getOne: api.getSource,
  },
  schema: [
    {
      key: "name",
      validation: yup.string().required("Source name is required"),
      required: true,
      type: "string",
      default: "",
    },
    {
      key: "link",
      validation: yup.string(),
      type: "string",
      default: "",
    },
  ],
};

export const CommonObject = {
  label: "CommonObject",
  api: {
    getOne: api.getSource,
  },
  initialValuesMapper: (data: { source?: string; rxcui?: string }) => {
    return {
      source: data.source || "",
      rxcui: data.rxcui || "",
    };
  },
  schema: [
    {
      key: "source",
      validation: yup.string().required("Source name is required"),
      required: true,
      type: "select",
      multiple: true,
      default: [],
      valueOptions: api.getAllSources,
      value: "id",
      display: "name",
      labels: [
        "Relationship",
        "Target",
        "Ingredient",
        "ClinicalDrug",
        "SideEffect",
        "Manufacturer",
        "BrandName",
        "IngredientGroup",
        "Group",
        "MoA",
        "Schedule",
        "DrugPack",
        "DoseForm",
        "Label",
        "BeersCriteria",
        "SideEffectGroup",
        "NDC",
      ],
    },
    {
      key: "rxcui",
      validation: yup.string().optional(),
      required: false,
      type: "string",
      default: "",
      labels: [
        "Ingredient",
        "ClinicalDrug",
        "BrandName",
        "DrugPack",
        "DoseForm",
      ],
    },
  ],
};

export const Patient: Model = {
  findBy: ["name"],
  label: "Patient",
  displayName: "Patient",
  color: "inherit",
  api: {
    getOne: api.getPatient,
    createOne: api.createPatient,
    editOne: api.editPatient,
    deleteOne: api.deletePatient,
    custom: {
      addPrescription: api.patientPrescribed,
      checkPrescription: api.patientCheckPrescribed,
      deletePrescription: api.deletePatientPrescribed,
      stopPrescription: api.stopPrescription,
      createRelation: api.createPatientRelation,
    },
  },
  mutate: (data: any) => {
    const {
      dob,
      HasDoctor = [],
      ethnicity,
      date_created,
      weight,
      ...rest
    } = data;
    const ageFromDate = new ageCalculator.AgeFromDate(new Date(dob)).age;
    return {
      ...rest,
      ethnicity: ethnicity.join ? ethnicity.join(", ") : ethnicity,
      weight,
      date_of_birth: `${dayjs(dob).format("L")} (${ageFromDate})`,
      "healthcare Provider": HasDoctor[0]?.name,
    };
  },
  schema: [
    {
      key: "name",
      validation: yup.string().required("Patient name is required"),
      type: "string",
      required: true,
      default: "",
    },
    {
      key: "dob",
      title: "Dob",
      validation: yup.number().required("Patient date of birth is required"),
      type: "datepicker",
      required: true,
      default: Date.now(),
    },
    {
      key: "sex",
      title: "Sex assigned at birth",
      validation: yup.string().required("Patient assigned sex is required"),
      type: "select",
      valueOptions: [
        { value: "male", display: "Male" },
        { value: "female", display: "Female" },
        { value: "decline", display: "Decline to answer" },
      ],
      required: true,
      default: "",
      mapped: true,
    },
    {
      key: "ethnicity",
      title: "Mark one or more ethnicity",
      validation: yup
        .array()
        .min(1, "Please select one or more")
        .required("Patient ethnicity is required"),
      type: "select",
      valueOptions: [
        "American Indian or Alaska Native",
        "Asian",
        "Black or African American",
        "Native Hawaiian or Other Pacific Islander",
        "White",
        "Decline to answer",
      ],
      multiple: true,
      required: true,
      default: [],
    },
    {
      key: "race",
      validation: yup.string().required("Patient race is required"),
      type: "select",
      valueOptions: [
        "Hispanic or Latino",
        "Not Hispanic or Latino",
        "Decline to answer",
      ],
      required: true,
      default: "",
    },
    {
      key: "weight",
      title: "Weight/BMI",
      validation: yup.string().required("Patient weight is required"),
      type: "select",
      required: true,
      valueOptions: [
        { value: "underweight", display: "Underweight = <18.5" },
        { value: "normal", display: "Normal weight = 18.5–24.9" },
        { value: "overweight", display: "Overweight = 25–29.9" },
        { value: "obese", display: "Obesity = BMI of 30 or greater " },
      ],
      default: "",
      mapped: true,
    },
  ],
};

export const modelByLabelMap = {
  [Target.label]: Target,
  [Ingredient.label]: Ingredient,
  [ClinicalDrug.label]: ClinicalDrug,
  [SideEffect.label]: SideEffect,
  [Manufacturer.label]: Manufacturer,
  [BrandName.label]: BrandName,
  [IngredientGroup.label]: IngredientGroup,
  [Group.label]: Group,
  [MoA.label]: MoA,
  [Schedule.label]: Schedule,
  [DrugPack.label]: DrugPack,
  [DoseForm.label]: DoseForm,
  [Label.label]: Label,
  [BeersCriteria.label]: BeersCriteria,
  [SideEffectGroup.label]: SideEffectGroup,
  [NDC.label]: NDC,
  [Patient.label]: Patient,
  [User.label]: User,
};
