import React, { useState } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import {
  Medication,
  Biotech,
  Sick,
  Healing,
  MedicalInformation,
  GroupWork,
  Circle,
  Gavel,
  Search,
  HubOutlined,
  SyncAltOutlined,
  ChecklistOutlined,
  DoNotDisturbOutlined,
  Person,
  PersonAddAlt1,
  Troubleshoot,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { PrefixContext } from "context";
import ProOverlay from "./ProOverlay";

export const getListItem = (link: string, text: string, icon?: any) => (
  <ListItemButton component={Link} to={link} key={`list-item-${text}-${link}`}>
    {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
    <ListItemText primary={text} />
  </ListItemButton>
);
export const AllListItemLinks = () => {
  const { prefix } = React.useContext(PrefixContext);
  const dataEntryDashboard = getListItem(
    `${prefix}/dashboard`,
    "Dashboard",
    <DashboardIcon />,
  );
  const medicationCreate = getListItem(
    `${prefix}/create/medication`,
    "Medications",
    <Medication />,
  );
  const ingredientCreate = getListItem(
    `${prefix}/create/ingredient`,
    "Ingredients",
    <Biotech />,
  );
  const sideEffectCreate = getListItem(
    `${prefix}/create/sideeffect`,
    "Side Effects",
    <Healing />,
  );
  const symptomCreate = getListItem(
    `${prefix}/create/symptom`,
    "Symptoms",
    <Sick />,
  );
  const targetCreate = getListItem(
    `${prefix}/create/target`,
    "Target Condition",
    <MedicalInformation />,
  );
  const groupCreate = getListItem(
    `${prefix}/create/group`,
    "Groups",
    <GroupWork />,
  );
  const typeCreate = getListItem(`${prefix}/create/type`, "Types", <Circle />);
  const beerLawCreate = getListItem(
    `${prefix}/create/beerlaw`,
    "Beers Law",
    <Gavel />,
  );
  const medicationEdit = getListItem(
    `${prefix}/edit/medication`,
    "Medications",
    <Medication />,
  );
  const ingredientEdit = getListItem(
    `${prefix}/edit/ingredient`,
    "Ingredients",
    <Biotech />,
  );
  const sideEffectEdit = getListItem(
    `${prefix}/edit/sideeffect`,
    "Side Effects",
    <Healing />,
  );
  const symptomEdit = getListItem(
    `${prefix}/edit/symptom`,
    "Symptoms",
    <Sick />,
  );
  const targetEdit = getListItem(
    `${prefix}/edit/target`,
    "Target Condition",
    <MedicalInformation />,
  );
  const groupEdit = getListItem(
    `${prefix}/edit/group`,
    "Groups",
    <GroupWork />,
  );
  const typeEdit = getListItem(`${prefix}/edit/type`, "Types", <Circle />);
  const beerLawEdit = getListItem(
    `${prefix}/edit/beerlaw`,
    "Beers Law",
    <Gavel />,
  );
  const medicationView = getListItem(
    `${prefix}/view/medication`,
    "Medications",
    <Medication />,
  );
  const ingredientView = getListItem(
    `${prefix}/view/ingredient`,
    "Ingredients",
    <Biotech />,
  );
  const sideEffectView = getListItem(
    `${prefix}/view/sideeffect`,
    "Side Effects",
    <Healing />,
  );
  const symptomView = getListItem(
    `${prefix}/view/symptom`,
    "Symptoms",
    <Sick />,
  );
  const targetView = getListItem(
    `${prefix}/view/target`,
    "Target Condition",
    <MedicalInformation />,
  );
  const groupView = getListItem(
    `${prefix}/view/group`,
    "Groups",
    <GroupWork />,
  );
  const beerLawView = getListItem(
    `${prefix}/view/beerlaw`,
    "Beers Law",
    <Gavel />,
  );
  const typeView = getListItem(`${prefix}/view/type`, "Types", <Circle />);
  const dataEntryList = <>{dataEntryDashboard}</>;
  const matchSETrg = getListItem(`${prefix}/matchsetrg`, "Match SE To Target");
  const createList = (
    <>
      {beerLawCreate}
      {/* {medicationCreate}
      {ingredientCreate}
      {sideEffectCreate}
      {symptomCreate}
      {targetCreate}
      {typeCreate}
      {groupCreate} */}
    </>
  );
  const editList = (
    <>
      {/* {medicationEdit} */}
      {ingredientEdit}
      {matchSETrg}
      {beerLawEdit}
      {/* {sideEffectEdit} */}
      {/* {symptomEdit} */}
      {/* {targetEdit} */}
      {/* {typeEdit} */}
      {/* {groupEdit} */}
    </>
  );
  const viewList = (
    <>
      {medicationView}
      {ingredientView}
      {sideEffectView}
      {beerLawView}
    </>
  );
  const dashboard = getListItem(
    `${prefix}/dashboard`,
    "Dashboard",
    <DashboardIcon />,
  );
  const quickSearch = getListItem(
    `${prefix}/quicksearch`,
    "Quick Search",
    <Troubleshoot />,
  );
  const findBy = getListItem(`${prefix}/findby`, "Find", <Search />);
  const patients = getListItem(
    `${prefix}/view/patient`,
    "Patients",
    <Person />,
  );
  const addPatients = getListItem(
    `${prefix}/add/patient`,
    "Add Patient",
    <PersonAddAlt1 />,
  );
  const search1 = getListItem(
    `${prefix}/ingFromMedSE`,
    "Possible Allergens causing side effects",
    <HubOutlined />,
  );
  const search5 = getListItem(
    `${prefix}/incompatMedFromList`,
    "Brand Name all Ingredient Interactions",
    <SyncAltOutlined />,
  );
  const search8 = getListItem(
    `${prefix}/incompatIngFromList`,
    "Active Ingredient Interactions",
    <SyncAltOutlined />,
  );
  const search9 = getListItem(
    `${prefix}/labelWithInactive`,
    "Labels that contain Inactive Ingredient",
    <ChecklistOutlined />,
  );
  const search10 = getListItem(
    `${prefix}/treatmentFind`,
    "Find Treatement without allergen",
    <DoNotDisturbOutlined />,
  );
  const searchList = (
    <>
      <ProOverlay>{search1}</ProOverlay>
      <ProOverlay>{search5}</ProOverlay>
      {search8}
      <ProOverlay>{search9}</ProOverlay>
      <ProOverlay>{search10}</ProOverlay>
    </>
  );
  const medSeSearch = getListItem(
    `${prefix}/search/medication/sideeffects`,
    "By Medication",
  );
  const ingSeSearch = getListItem(
    `${prefix}/search/ingredient/sideeffects`,
    "By Ingredient",
  );
  const cdSeSearch = getListItem(
    `${prefix}/search/clinicaldrug/sideeffects`,
    "By Clinical Drug",
  );
  const snSeSearch = getListItem(
    `${prefix}/search/serialnumber/sideeffects`,
    "By Serial Number",
  );
  const sideEffectSearchList = (
    <>
      {medSeSearch}
      {ingSeSearch}
      {cdSeSearch}
      {snSeSearch}
    </>
  );
  return {
    quickSearch,
    dashboard,
    search1,
    searchList,
    findBy,
    medicationCreate,
    ingredientCreate,
    sideEffectCreate,
    symptomCreate,
    targetCreate,
    groupCreate,
    typeCreate,
    medicationEdit,
    ingredientEdit,
    sideEffectEdit,
    symptomEdit,
    targetEdit,
    groupEdit,
    typeEdit,
    medicationView,
    ingredientView,
    sideEffectView,
    symptomView,
    targetView,
    groupView,
    typeView,
    createList,
    viewList,
    editList,
    dataEntryList,
    sideEffectSearchList,
    patients,
    addPatients,
  };
};
