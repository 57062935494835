import React from "react";
import {
  ingredientModel,
  brandNameModel,
  doseFormModel,
  sideEffectModel,
  drugPackModel,
  clinicalDrugModel,
} from "../models";
import { Props } from "helpers/types";

function mockFunction(props: Props) {
  return <></>;
}
const ListResultPage = mockFunction;
const searchByRoutes = {
  path: "search",
  children: [
    {
      path: "ingredient",
      children: [
        {
          path: "sideeffects",
          element: (
            <ListResultPage
              key={"ingredient"}
              configString={"pendingForDataEntryConfig"}
              model={ingredientModel}
              api={ingredientModel.api.sideEffects}
              apiSide={ingredientModel.api.sideEffect}
              secondaryModel={sideEffectModel}
            />
          ),
        },
      ],
    },
    {
      path: "medication",
      children: [
        {
          path: "sideeffects",
          element: (
            <ListResultPage
              key={"medication"}
              configString={"pendingForDataEntryConfig"}
              model={brandNameModel}
              api={brandNameModel.api.sideEffects}
              apiSide={brandNameModel.api.sideEffect}
              secondaryModel={sideEffectModel}
            />
          ),
        },
      ],
    },
    {
      path: "serialnumber",
      children: [
        {
          path: "sideeffects",
          element: (
            <ListResultPage
              key={"serialnumber"}
              configString={"pendingForDataEntryConfig"}
              model={doseFormModel}
              api={doseFormModel.api.sideEffects}
              apiSide={doseFormModel.api.sideEffect}
              secondaryModel={sideEffectModel}
            />
          ),
        },
      ],
    },
    {
      path: "variation",
      children: [
        {
          path: "sideeffects",
          element: (
            <ListResultPage
              key={"variation"}
              configString={"pendingForDataEntryConfig"}
              model={drugPackModel}
              api={drugPackModel.api.sideEffects}
              apiSide={drugPackModel.api.sideEffect}
              secondaryModel={sideEffectModel}
            />
          ),
        },
      ],
    },
    {
      path: "clinicaldrug",
      children: [
        {
          path: "sideeffects",
          element: (
            <ListResultPage
              key={"clinicaldrug"}
              configString={"pendingForDataEntryConfig"}
              model={clinicalDrugModel}
              api={clinicalDrugModel.api.sideEffects}
              apiSide={clinicalDrugModel.api.sideEffect}
              secondaryModel={sideEffectModel}
            />
          ),
        },
      ],
    },
  ],
};

export default searchByRoutes;
