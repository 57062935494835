import { Typography, Box } from "@mui/material";

export const TermsAndConditions = () => {
  return (
    <Box mt={3}>
      <Typography variant="h5" component="h2" gutterBottom>
        Terms and Conditions
      </Typography>
      <Typography variant="body1">
        Last Updated: Monday, October 16, 2023
      </Typography>

      <Typography variant="body1">
        These Website Terms and Conditions ("Terms") govern your use of this
        website. By accessing and using this website, you agree to be bound by
        these Terms. If you do not agree with these Terms, please refrain from
        using this website.
      </Typography>
      <ol>
        <li>
          Use of Website:
          <ul>
            <li>
              This website is intended for use by healthcare professionals only.
              If you are not a healthcare professional, you must refrain from
              using this website.
            </li>
            <li>
              You must be of legal age to access this website in your
              jurisdiction.
            </li>
            <li>
              You agree to use this website in compliance with all applicable
              laws and regulations.
            </li>
          </ul>
        </li>

        <li>
          Disclaimer:
          <ul>
            <li>
              The information provided on this website is for informational
              purposes and should not be considered as medical advice. Consult
              with a qualified healthcare professional for personalized
              guidance.
            </li>
            <li>
              We do not guarantee the accuracy, completeness, or reliability of
              the information provided on this website. The information may
              change or become outdated without notice.
            </li>
            <li>
              We disclaim any responsibility for any direct or indirect loss or
              damage resulting from your use of this website.
            </li>
          </ul>
        </li>
        <li>
          Intellectual Property
          <ul>
            <li>
              All content on this website, including text, images, logos, and
              trademarks, is the intellectual property of the website owner or
              its licensors and is protected by copyright and other intellectual
              property laws.
            </li>
            <li>
              You may not reproduce, distribute, or use the content on this
              website for any purpose without the prior written consent of the
              website owner.
            </li>
          </ul>
        </li>
        <li>
          Links to Third-Party Websites
          <ul>
            <li>
              This website may contain links to external websites or resources.
              We are not responsible for the content, policies, or practices of
              these external websites. The inclusion of these links does not
              imply endorsement or affiliation.
            </li>
          </ul>
        </li>
        <li>
          Privacy:
          <ul>
            <li>
              Your use of this website is also governed by our Privacy Policy,
              which outlines how we collect, use, and protect your personal
              information.
            </li>
          </ul>
        </li>
        <li>
          Changes to Terms:
          <ul>
            <li>
              We reserve the right to make changes to these Terms at any time.
              Any modifications will be effective immediately upon posting. You
              are responsible for reviewing these Terms regularly.
            </li>
          </ul>
        </li>
        <li>
          Termination:
          <ul>
            <li>
              We reserve the right to terminate or suspend your access to this
              website at our discretion, without notice, for any violation of
              these Terms.
            </li>
          </ul>
        </li>
        <li>
          Governing Law:
          <ul>
            <li>
              These Terms are governed by the laws of Delaware, and any disputes
              related to these Terms or your use of this website will be subject
              to the exclusive jurisdiction of the courts in Delaware.
            </li>
          </ul>
        </li>
        <li>
          Contact Information:
          <ul>
            <li>
              If you have any questions or concerns about these Terms, please
              contact us at leaflitcare@outlook.com .
            </li>
          </ul>
        </li>
      </ol>
    </Box>
  );
};

export default TermsAndConditions;
