import { modelByLabelMap } from "./basicModels";

export * from "./target";
export * from "./ingredient";
export * from "./clinicalDrug";
export * from "./sideEffect";
export * from "./manufacturer";
export * from "./brandName";
export * from "./ingredientGroup";
export * from "./group";
export * from "./moa";
export * from "./schedule";
export * from "./drugPack";
export * from "./doseForm";
export * from "./label";
export * from "./beersCriteria";
export * from "./sideEffectGroup";
export * from "./nDC";
export * from "./search";
export * from "./discover";
export * from "./guest";
export * from "./legend";
export * from "./source";
export * from "./patient";

export function getModelByLabel(label: string): any {
  const model = modelByLabelMap[label];
  return model;
}
