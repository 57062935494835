import { useParams } from "react-router-dom";
import { useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Paper } from "@mui/material";
import Title from "components/Title";
import { ListKeys, Model } from "helpers/types";
import useApiHook from "hooks/useApiHook";
import ApiStatus from "components/ApiStatus";
import { sideEffectListKeys } from "models";
import SideEffectsView from "views/SideEffectsView";
import CustomLoader from "components/CustomeLoader";
// TODO make this so that it can be used for any path request for one starter node
export default function ResultPage({
  model,
  secondaryModel,
}: {
  model: Model;
  secondaryModel: Model;
}) {
  const api = model.api.sideEffects;
  const apiSide = model.api.sideEffect;
  const { id: selectedId } = useParams();
  const [recievedResults, getResults] = useApiHook<{ [key: string]: any }>(
    {},
    api,
    (data: any) => {
      const { SideEffects } = data;
      const models = data[model.label] && data[model.label][0];
      return { ...models, SideEffects };
    },
  );
  const [recievedResultsSingle, getResultsSingle] = useApiHook<{
    [key: string]: any;
  }>(
    {},
    apiSide,
    (data: {
      [key: string]: any;
      paths: { name: string; results: any[] }[];
    }) => {
      const { paths, ...rest } = data;
      const listKey: Partial<ListKeys> = {
        properties: [
          {
            key: "name",
            display: "Name",
          },
        ],
        relations: [],
      };
      const returnObject = {
        ...rest,
      };
      paths.forEach((path) => {
        returnObject[path.name] = path.results;
        listKey.relations &&
          listKey.relations.push({
            name: path.name,
            displayName: path.name,
            relatedModel: {
              findBy: [],
              label: "Paths",
              displayName: "Paths",
              api: {},
              schema: [],
            },
            properties: [],
          });
      });
      return { ...returnObject, listKey };
    },
  );
  useEffect(() => {
    if (selectedId) getResults({ idList: [selectedId] });
  }, [model, selectedId]);
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <ApiStatus
        error={recievedResults.error}
        isLoading={recievedResults.isLoading}
        customLoader={
          <CustomLoader
            loaderText={
              "Searching through labels, and studies this might take some time"
            }
          ></CustomLoader>
        }
      />
      <ApiStatus
        error={recievedResultsSingle.error}
        isLoading={recievedResultsSingle.isLoading}
        customLoader={
          <CustomLoader
            loaderText={
              "Searching through labels, and studies to find all connections to this side effect this might take some time"
            }
          ></CustomLoader>
        }
      />
      {recievedResults.data.id && (
        <Grid container>
          <Grid item xs={12} md={12} lg={12}>
            <Paper
              elevation={1}
              sx={{
                p: 1,
                m: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Title> Side Effects for {recievedResults.data.name}</Title>
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Paper
              elevation={1}
              sx={{
                p: 1,
                m: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {recievedResults?.data?.SideEffects && (
                <SideEffectsView
                  result={recievedResults.data}
                  listKeys={sideEffectListKeys}
                  expandedResults={recievedResultsSingle.data}
                  expandedResultsListKeys={
                    recievedResultsSingle.data.listKey || {}
                  }
                  onClick={async (row: string) => {
                    await getResultsSingle(row, { idList: [selectedId] });
                  }}
                />
              )}
            </Paper>
          </Grid>
        </Grid>
      )}
      {recievedResults.data.SideEffects && !recievedResults.data.id && (
        <Grid item xs={12} md={12} lg={12}>
          <Paper
            elevation={1}
            sx={{
              p: 1,
              m: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Title>No Side Effects found</Title>
          </Paper>
        </Grid>
      )}
    </Container>
  );
}
