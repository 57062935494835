import "./App.css";
import { Outlet } from "react-router-dom";
import MyContainer from "components/Container";
import ErrorHandler from "components/ErrorBoundary";
import Layout from "components/Layout";
import { PrefixContext } from "context";
import { useEffect } from "react";
import { getAllSources } from "api/source";
import useApiHook from "hooks/useApiHook";
import { BasicData } from "helpers/types";
import { SourceContext } from "context/Sources";

function App({
  prefix,
  limited = false,
  withLayout,
}: {
  prefix: string;
  limited?: boolean;
  withLayout?: boolean;
}) {
  const [sources, getSources] = useApiHook<BasicData[]>([], getAllSources);
  useEffect(() => {
    getSources();
  }, []);
  return (
    <ErrorHandler>
      <PrefixContext.Provider value={{ prefix, limited }}>
        <SourceContext.Provider value={{ sources: sources.data }}>
          <MyContainer>
            {withLayout ? (
              <Layout>
                <Outlet />
              </Layout>
            ) : (
              <Outlet />
            )}
          </MyContainer>
        </SourceContext.Provider>
      </PrefixContext.Provider>
    </ErrorHandler>
  );
}

export default App;
