import { useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TableComponent from "../components/Table";
import {
  getAllUsers,
  editUser,
  getAccessLevels as getAccessLevelsApi,
} from "../api";
import ApiStatus from "../components/ApiStatus";
import useApiHook from "../hooks/useApiHook";
import { Button, MenuItem, Select } from "@mui/material";
import { Link } from "react-router-dom";

export default function AdminUserSettingsPage() {
  const [users, getUsers] = useApiHook<
    {
      email: string;
      id: string;
      access_level: number;
    }[]
  >([], getAllUsers);
  const [accessLevels, getAccessLevels] = useApiHook<any[]>(
    [],
    getAccessLevelsApi,
  );
  const [editedUser, editUserCall] = useApiHook(null, editUser);
  useEffect(() => {
    getUsers();
    getAccessLevels();
  }, []);
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <ApiStatus error={users.error} />
      <ApiStatus
        error={editedUser.error}
        isLoading={editedUser.isLoading}
        success={editedUser.success}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Paper>
            <Button component={Link} to={`/admin/signupuser`}>
              Signup User
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Paper>
            <TableComponent
              showTitle
              tableName={"Users"}
              data={users.data.map((user) => {
                return {
                  ...user,
                  dropdown: {
                    value: (
                      <Select
                        label="Access Level"
                        defaultValue={user.access_level}
                        // value={}
                        variant="standard"
                        fullWidth
                        onChange={async (e) => {
                          await editUserCall(user.id, {
                            accessLevel: e.target.value,
                          });
                          //   if (created.data) getUsers();
                        }}
                      >
                        <MenuItem value={0} disabled>
                          {accessLevels.data[0]}
                        </MenuItem>
                        {Object.keys(accessLevels.data).map((dt, index) => {
                          if (index === 0) return;
                          if (!accessLevels.data[index]) return;
                          return (
                            <MenuItem key={index} value={index}>
                              {accessLevels.data[index]}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    ),
                  },
                };
              })}
              tableKeys={[
                { key: "name", display: "name" },
                { key: "email", display: "email" },
                { key: "dropdown", display: "AccessLevel" },
              ]}
            />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
