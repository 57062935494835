import { useState } from "react";
import { Button, Container, Grid, Paper } from "@mui/material";
import {
  getInitialFromSchema,
  getValidationFromSchema,
} from "../schema/commonSchema";
import useApiHook from "../hooks/useApiHook";
import SearchResult from "views/SearchResult";
import ApiStatus from "../components/ApiStatus";
import InputByType from "../inputs/InputByType";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Data, FindSchema, Model } from "helpers/types";
import Title from "components/Title";

type Inputs = Data;
export default function FindByPage({ model }: { model: any }) {
  const { schema, searchInputs } = model;
  const { resultModels, api } = schema[0];
  const [findResults, findBy] = useApiHook<any[]>([], api);
  const emptyInitialValues = getInitialFromSchema(searchInputs);
  const validationSchema = getValidationFromSchema(searchInputs);
  const [showResults, setShowResults] = useState(false);
  const {
    control,
    formState: { isSubmitting, errors },
    trigger,
    handleSubmit,
  } = useForm<Inputs>({
    resolver: yupResolver(validationSchema),
    defaultValues: emptyInitialValues,
  });
  const onSubmit: SubmitHandler<Inputs> = async (values: Inputs) => {
    const valid = await trigger();
    if (!valid) return;
    const { filter, ...rest } = values;
    const results = await findBy({
      filter:
        filter && filter.length > 0 && filter !== "All" ? [filter] : undefined,
      ...rest,
    });
    if (results) setShowResults(true);
  };
  return (
    <Container
      maxWidth="lg"
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{ mt: 4, mb: 4, minWidth: "250px", width: "70vw" }}
    >
      <ApiStatus
        error={findResults.error}
        isLoading={isSubmitting || findResults.isLoading}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12} width={"100%"}>
          <Paper
            elevation={0}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Title>Find</Title>
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {searchInputs.map((sch: FindSchema, index: number) => {
                const { key, type } = sch;
                return (
                  <Grid
                    key={index}
                    item
                    xs={12}
                    md={type === "select" ? 3 : 6}
                    lg={type === "select" ? 3 : 6}
                    sx={{ p: 1 }}
                  >
                    <Controller
                      key={`${key}-${index}`}
                      control={control}
                      name={key}
                      render={({ field, fieldState }) => {
                        return (
                          <InputByType
                            key={sch.key}
                            schema={sch}
                            field={field}
                            fieldState={fieldState}
                            autoFocus={index === 0}
                          />
                        );
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={12}
              lg={12}
              direction="column"
              alignItems="right"
              justifyContent="right"
            >
              <Button
                variant="contained"
                type="submit"
                sx={{ textAlign: "right" }}
              >
                Find
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      {showResults &&
        resultModels &&
        resultModels.map((model: Model, index: number) => (
          <Grid key={index} item xs={12} md={12} lg={12}>
            <Paper
              sx={{
                p: 2,
                mt: 1,
                display: "flex",
                flexDirection: "column",
              }}
              elevation={0}
            >
              <SearchResult model={model} results={findResults.data} />
            </Paper>
          </Grid>
        ))}
    </Container>
  );
}
