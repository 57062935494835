import { useContext, useEffect } from "react";
import Container from "@mui/material/Container";
import { Grid } from "@mui/material";
import { PrefixContext } from "context";
import { LinkPaperCard } from "components/LinkPaperCard";
import ProOverlay from "components/ProOverlay";
import {
  ChecklistOutlined,
  DoNotDisturbOutlined,
  HubOutlined,
  PersonAddAlt1,
  Search,
  SyncAltOutlined,
  AccessTime,
} from "@mui/icons-material";
import Responsive from "components/Responsive";
import QuickSearch from "components/QuickSearch";

function DashboardContent() {
  const { prefix } = useContext(PrefixContext);
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3} lg={3}>
          <ProOverlay>
            <LinkPaperCard
              title="Add Patient"
              body={`Add Patient details, prescriptions, allergies, and conditions. Analyze previous prescriptions, check new prescriptions, and find the best prescription for the patient`}
              to={`${prefix}/add/patient`}
              buttonText="Add"
              icon={<PersonAddAlt1 sx={{ height: "70px", width: "70px" }} />}
            />
          </ProOverlay>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <LinkPaperCard
            title="Find by Name or RXCUI"
            body={`Use "Find" to search through our database using the name, RXCUI, Set Id, MedDRA Id`}
            to={`${prefix}/findby`}
            buttonText="Find"
            icon={<Search sx={{ height: "70px", width: "70px" }} />}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <LinkPaperCard
            title={`Find Active Ingredient Incompatibilities`}
            body={`Find incompatibilities by active ingredients,
            search by a list of brand names
            ingredients for active ingredients to find if there are any incompatibilities.`}
            to={`${prefix}/incompatIngFromList`}
            buttonText="Find Interactions"
            icon={<SyncAltOutlined sx={{ height: "70px", width: "70px" }} />}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <ProOverlay>
            <LinkPaperCard
              title={`Find Brand Name Prescription Incompatibilities`}
              body={`Find incompatibilities for prescriptions by brand name by all their ingredients (active and inactive),
            search by a list of
            brand names to find if there are any incompatibilities.`}
              to={`${prefix}/incompatMedFromList`}
              buttonText="Find Interactions"
              icon={<SyncAltOutlined sx={{ height: "70px", width: "70px" }} />}
            />
          </ProOverlay>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <ProOverlay>
            <LinkPaperCard
              title={`Find Possible Allergens causing Adverse Reactions`}
              body={`Choose prescribed medications and list the side effects of the
            patient. The search will display ingredients and medications with
            studies that might be the cause. It will also list if any
            medications in the list have incompatibilities`}
              to={`${prefix}/ingFromMedSE`}
              buttonText="Find Possible Allergens"
              icon={<HubOutlined sx={{ height: "70px", width: "70px" }} />}
            />
          </ProOverlay>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <ProOverlay>
            <LinkPaperCard
              title={`Find Labels that contain inactive ingredients`}
              body={`Choose Inactive ingredients and list of brand names and active ingredients to find 
              labels that do not have those inactive ingredients that a patient might be allergic too`}
              to={`${prefix}/labelWithInactive`}
              buttonText="Find Labels"
              icon={
                <ChecklistOutlined sx={{ height: "70px", width: "70px" }} />
              }
            />
          </ProOverlay>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <ProOverlay>
            <LinkPaperCard
              title={`Find treatmets without possible allergens`}
              body={`Choose Inactive ingredients and use Mode of action, disease, or Rx Classes to find 
              labels that do not have those inactive ingredients that a patient might be allergic too`}
              to={`${prefix}/treatmentFind`}
              buttonText="Find Treatments"
              icon={
                <DoNotDisturbOutlined sx={{ height: "70px", width: "70px" }} />
              }
            />
          </ProOverlay>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <ProOverlay>
            <LinkPaperCard
              title={`Get Patient Report (Coming Soon)`}
              body={`Add prescribed medications in the order that they were prescribed
          to get a detailed report for the patient`}
              buttonText="Patient Report"
              icon={<AccessTime sx={{ height: "70px", width: "70px" }} />}
            />
          </ProOverlay>
        </Grid>
      </Grid>
    </Container>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}
