import { ViewModel } from "helpers/types";
import { getModelByLabel } from "models";
import useApiHook from "./useApiHook";

export default function useModelDetailsHook(
  modelElement: ViewModel,
): [
  { data: any; isLoading: boolean; success: boolean; error: any },
  () => void,
] {
  const model = getModelByLabel(modelElement.label);
  const {
    api: { getOne },
  } = model;
  const [modelDetails, callApi] = useApiHook({}, getOne);
  const getDetails = () => {
    callApi(modelElement.id, { noRelations: true });
  };
  return [modelDetails, getDetails];
}
