import { deleteReq, getReq, postReq, putReq } from "./instance";

export const getLegend = async (legendId: string, params = {}) => {
  return getReq(`/legends/${legendId}`, params);
};

export const getAllLegends = async (params = {}) => {
  return getReq("/legends", params);
};

export const editLegend = async (
  legendId: string,
  properties: {},
  params = {},
) => {
  return putReq(`/legends/${legendId}`, properties, params);
};

export const createLegend = async (properties: {}, params = {}) => {
  return postReq("/legends", properties, params);
};

export const deleteLegend = async (legendId: string) => {
  return deleteReq(`/legends/${legendId}`);
};

export const editLegendRelationshipProperties = async (
  relationId: string,
  properties: {},
  params = {},
) => {
  return putReq(`/legends/relation/${relationId}`, properties, params);
};
