import _ from "lodash";
import { Data } from "./types";

export const mapDataAsCamelCase = (data: Data, values?: Object) => {
  if (!data) return {};
  const mappedData: Data = {};
  if (!values) {
    Object.keys(data).forEach((val) => {
      mappedData[_.camelCase(val)] = data[val];
    });
  } else {
    Object.keys(values).forEach((val) => {
      mappedData[val] = data[_.snakeCase(val)];
    });
  }
  return { ...mappedData, ...data };
};

export const mapDataAsPascalCase = (data: Data, values: Object) => {
  if (!data) return {};
  const mappedData: Data = {};
  Object.keys(values).forEach((val) => {
    mappedData[val] = data[_.camelCase(val)];
  });
  return { ...mappedData };
};

export const mapDataToSchema = (data: Data, values: Object) => {
  if (!data) return {};
  const mappedData: Data = {};
  Object.keys(values).forEach((val) => {
    mappedData[val] = data[val];
  });
  return mappedData;
};
// filters array of objects from and array of objects
export const filterAOBFromAOB = (
  array: any[],
  filterArray: string[],
  key: number,
) => {
  const connected = _.keyBy(filterArray, (o) => o[key]);
  return array.filter((arr) => !connected[arr[key]]);
};
// Renames object keys based on a function changing the key
export const renameObjectKeys = (
  object: Data,
  keyChange: (key: string) => string,
) => {
  if (!object) return {};
  const returnObject: Data = {};
  Object.keys(object).forEach((key) => {
    returnObject[keyChange(key)] = object[key];
  });
  return returnObject;
};
