import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import ModelCard from "components/ModelCard";
import ModelSimple from "components/ModelSimple";
import { ListKeys } from "helpers/types";
import useModelDetailsHook from "hooks/useModelDetailsHook";
import _, { capitalize } from "lodash";
import {
  ReactElement,
  JSXElementConstructor,
  ReactNode,
  ReactPortal,
} from "react";

export default function MobileRelationsView({
  result,
  listKeys,
}: {
  result: any;
  listKeys: ListKeys;
}) {
  const { relations } = listKeys;
  const ele = relations
    .map((listKey) => {
      if (!result[listKey.name]) return null;
      return {
        id: listKey.name,
        name: listKey.displayName,
        relationships: result[listKey.name],
      };
    })
    .flatMap((fl) => (fl === null ? [] : fl));
  return (
    <Box>
      <Typography
        textAlign={"center"}
        variant="h3"
        component={"h1"}
        sx={{ m: 1 }}
      >
        {capitalize(result.name)}
      </Typography>
      {ele.map((relation, index) => {
        return (
          <Box key={index}>
            <Typography color={"primary"} sx={{ ml: 2 }}>
              {relation.name}
            </Typography>
            <List>
              {relation.relationships.map(
                (
                  rela: {
                    name:
                      | string
                      | number
                      | boolean
                      | ReactElement<any, string | JSXElementConstructor<any>>
                      | Iterable<ReactNode>
                      | ReactPortal
                      | null
                      | undefined;
                    set_id: any;
                    label: any;
                  },
                  index2: number,
                ) => {
                  return (
                    <ListItem key={index + index2}>
                      <ListItemText
                        primaryTypographyProps={{
                          sx: {
                            fontSize: "4vmin",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          },
                        }}
                        secondaryTypographyProps={{
                          sx: {
                            fontSize: "3vmin",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          },
                        }}
                        primary={rela.name}
                        secondary={rela.set_id || rela.label}
                      />
                    </ListItem>
                  );
                },
              )}
            </List>
          </Box>
        );
      })}
    </Box>
  );
}
