import * as yup from "yup";
import { isEmpty } from "../helpers/sessionStorage";
import {
  Data,
  RelationProperties,
  Relations,
  SchemaArray,
  SearchInput,
} from "../helpers/types";
import _ from "lodash";

export const getValidationFromSchema = (schema: SchemaArray) => {
  const object: Data = {};
  schema.forEach((sch) => {
    const { key, validation } = sch;
    object[key] = validation;
  });
  return yup.object(object);
};
export const getInitialFromSchema = (schema: SchemaArray) => {
  const object: Data = {};
  schema.forEach((sch) => {
    const { key, default: defaultValue } = sch;
    object[key] = defaultValue;
  });
  return object;
};

export const getInitialRelationFromSchema = (
  relationSchema: RelationProperties[],
) => {
  const object: Data = {};
  relationSchema.forEach((sch) => {
    const { property, default: defaultValue } = sch;
    object[property] = defaultValue;
  });
  return object;
};
export const getRelationPropValidationFromSchema = (
  schema: RelationProperties[],
) => {
  const object: Data = {};
  schema.forEach((sch) => {
    const { property, validation } = sch;
    object[property] = validation;
  });
  return yup.object(object);
};
export const getEmptyArrayRelationship = (relations?: Relations[]) => {
  if (!relations) return {};
  const object: Data = {};
  relations.forEach((rel) => {
    object[rel.name] = [];
  });
  return object;
};

export const idMapValidation = (properties = undefined) => {
  return yup.lazy((value) => {
    if (!isEmpty(value)) {
      const validationObject = {
        name: yup.string(),
        properties: yup.object(),
      };
      const newEntries = Object.keys(value).reduce(
        (acc, val) => ({
          ...acc,
          [val]: yup.object(validationObject),
        }),
        {},
      );
      return yup.object().shape(newEntries);
    }
    return yup.mixed().notRequired();
  });
};

export const idMapPropValidation = (properties: any) => {
  return yup.lazy((value) => {
    if (!isEmpty(value)) {
      const validationObject = {
        id: yup.string().required(),
        properties: yup.object(properties).required(),
      };
      const newEntries = Object.keys(value).reduce(
        (acc, val) => ({
          ...acc,
          [val]: yup.object(validationObject).required(),
        }),
        {},
      );
      return yup.object().shape(newEntries).required();
    }
    return yup.object({ id: yup.string().required("Nothing to add") });
  });
};

export const searchSchema = yup.object();

export const searchSchemaValidation = (
  searchInputs: (SearchInput | SearchInput[])[] | undefined,
) => {
  const flatSearch = _.flatten(searchInputs);
  const keys = flatSearch.map((si) => si.name || si.model.label);
  const validationObject: any = {};
  keys.forEach((key: string, index: number) => {
    if (flatSearch[index].min === 0) return;
    validationObject[key] = yup
      .array()
      .min(flatSearch[index].min || 1)
      .required(`Select atleast ${flatSearch[index].min || 1} ${key}`);
  });
  return yup.object(validationObject);
};
