import { Box, Paper } from "@mui/material";
import Title from "components/Title";
import _ from "lodash";
import PathView from "./PathView";
import { ViewModel, ViewRelationship } from "helpers/types";
import ModelSimple from "components/ModelSimple";
import Table from "components/Table";
import ModalSimple from "components/ModalSimple";
import DynamicDisplay from "components/DynamicDisplay";
export default function IncompatibilityView({
  results,
  title,
}: {
  results: {
    startNode: ViewModel;
    endNode: ViewModel;
    paths: [ViewModel | ViewRelationship][];
  }[];
  title: string;
}) {
  if (results.length === 0) return <></>;
  const availableMap: { [key: string]: string } = {};
  // TODO fix any in table data array
  const tableData: any[] = results
    .map((res) => {
      const { startNode, endNode, paths } = res;
      if (availableMap[`${endNode.name}-${startNode.name}`]) {
        return null;
      }
      if (!availableMap[`${startNode.name}-${endNode.name}`]) {
        availableMap[`${startNode.name}-${endNode.name}`] = "done";
      }
      const descriptions = _.flatten(
        paths.map((path) => {
          return path
            .map((element) => {
              if (element.alias === "CompatibilityWithIngredient")
                return element.description;
              return false;
            })
            .filter((fl) => !!fl);
        }),
      );
      return {
        startModel: {
          value: <ModelSimple modelElement={startNode} />,
          searchString: startNode.name,
        },
        endModel: {
          value: <ModelSimple modelElement={endNode} />,
          searchString: endNode.name,
        },

        descriptions: {
          value: (
            <ModalSimple
              primary={`${paths.length} Reason${paths.length !== 1 ? "s" : ""}`}
              secondary="Click to view"
              sx={{
                position: "absolute" as "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
              }}
            >
              <Box>
                <Title>Descriptions</Title>
                <DynamicDisplay dataKey="descriptions" value={descriptions} />
                <ModalSimple
                  primary={`${paths.length} Path${
                    paths.length !== 1 ? "s" : ""
                  }`}
                  secondary="Click to view"
                >
                  <PathView paths={paths} />
                </ModalSimple>
              </Box>
            </ModalSimple>
          ),
          searchString: descriptions.join(" "),
        },
      };
    })
    .filter((val) => !!val);
  return (
    <Paper elevation={1} sx={{ overflow: "auto", p: 1, m: 1 }}>
      <Box sx={{ m: 3 }}>
        <Title>{title}</Title>
      </Box>
      <Table
        tableName={"Compatibility"}
        tableKeys={[
          { key: "startModel", display: "Medication" },
          { key: "descriptions", display: "Interactions" },
          { key: "endModel", display: "Medication" },
        ]}
        data={tableData}
      />
    </Paper>
  );
}
