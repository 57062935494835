import * as React from "react";
import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { myUserDetails } from "../api";
import { Link } from "react-router-dom";
import { PrefixContext } from "context";
import useApiHook from "hooks/useApiHook";
import ApiStatus from "components/ApiStatus";

function ProOptionsPage() {
  const { prefix } = React.useContext(PrefixContext);
  const [userDetails, getMyDetails] = useApiHook<{
    email: string;
    name: string;
  }>({}, myUserDetails);
  React.useEffect(() => {
    getMyDetails({ billingPortal: true });
  }, []);
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <ApiStatus error={userDetails.error} isLoading={userDetails.isLoading} />
      <Paper sx={{ p: 2 }}>
        <Container
          disableGutters
          maxWidth="sm"
          component="main"
          sx={{ pt: 8, pb: 6 }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item xs={12} md={12} lg={12} sx={{ mb: 3 }}>
              <Typography component="h1" variant="h2" color="primary">
                {"Upgrade to Pro"}
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} lg={6} sx={{ mb: 3 }}>
              <Button
                component={Link}
                to={`${prefix}/user/payment`}
                variant="contained"
              >
                Purchase Subscription
              </Button>
            </Grid>
            <Grid item xs={12} md={6} lg={6} sx={{ mb: 3 }}>
              <Button
                component={Link}
                to={`${prefix}/testerrequest`}
                variant="contained"
              >
                Get your free trial
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </Container>
  );
}

export default ProOptionsPage;
