import { idMapValidation } from "../schema/commonSchema";
import { Ingredient, IngredientGroup } from "./basicModels";

IngredientGroup.relations = [
  /* -------------------------------------------------------------------------- */
  /*                             Outgoing Relations                             */
  /* -------------------------------------------------------------------------- */
  {
    relatedModel: Ingredient,
    name: "IncludesIngredient",
    displayName: "Includes",
    properties: [],
    schema: idMapValidation,
  },
  /* -------------------------------------------------------------------------- */
  /*                             Incoming Relations                             */
  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                               Self Relations                               */
  /* -------------------------------------------------------------------------- */
];
export const ingredientGroupModel = IngredientGroup;
