import { discoverModel, findModel, trgSeModel } from "../models";
import ErrorPage from "../pages/ErrorPage";
import viewRoutes from "./ViewRoutes";
import searchRoutes from "./SearchRoutes";
import searchByRoutes from "./SearchByRoutes";
import { Props } from "helpers/types";
import FindByPage from "pages/FindByPage";
import AdminUserSettingsPage from "pages/AdminUserSettingsPage";
import CreatePatientPage from "pages/CreatePatientPage";
import Dashboard from "pages/StandardDashboardPage";
import Responsive from "components/Responsive";
import QuickSearchPage from "pages/QuickSearchPage";

function mockFunction(props: Props) {
  return <></>;
}
const AdminDashboardPage = mockFunction;
const DiscoverPage = mockFunction;
const TargetJoinSePage = mockFunction;
export const adminRoutes = [
  {
    path: "",
    element: (
      <>
        <Responsive displayIn={["Mobile"]}>
          <QuickSearchPage />
        </Responsive>
        <Responsive displayIn={["Laptop"]}>
          <Dashboard />
        </Responsive>
      </>
    ),
    // element: <FDALabelFilePage />
  },
  {
    path: "notfound",
    element: <ErrorPage error={"Not Found"} />,
  },
  {
    path: "dashboard",
    element: <Dashboard />,
  },
  {
    path: "quicksearch",
    element: <QuickSearchPage />,
  },
  {
    path: "discover",
    element: <DiscoverPage model={discoverModel} />,
  },
  {
    path: "users",
    element: <AdminUserSettingsPage />,
  },
  {
    path: "matchsetrg",
    element: <TargetJoinSePage model={trgSeModel} key={"matchsetrg"} />,
  },
  {
    path: "findby",
    element: <FindByPage model={findModel} key={"findby"} />,
  },
  {
    path: "add",
    children: [
      {
        path: "patient",
        children: [
          { path: "", element: <CreatePatientPage /> },
          { path: ":id", element: <CreatePatientPage /> },
        ],
      },
    ],
  },
  ...searchRoutes,
  searchByRoutes,
  viewRoutes,
];
