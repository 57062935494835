import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { LineType, RelationType } from "react-archer/lib/types";

export const rootStyle = {
  display: "flex",
  justifyContent: "center",
};
export const rowStyle = {
  margin: "100px 0",
  display: "flex",
  justifyContent: "space-between",
};
export const middleBottomRowStyle = {
  margin: "200px 0px",
  marginBottom: "400px",
  display: "flex",
  justifyContent: "space-between",
};
export const middleTopRowStyle = {
  margin: "200px 0px",
  marginTop: "400px",
  display: "flex",
  justifyContent: "space-between",
};
type Position = "top" | "top2" | "bottom2" | "bottom";
export const positionMap: Position[] = ["bottom", "bottom2", "top", "top2"];
export function getRootRelation(
  targetId: string | number,
  position: Position,
  label?: ReactJSXElement | string,
  style?: LineType,
): RelationType {
  switch (position) {
    case "top":
      return {
        targetId: targetId.toString(),
        targetAnchor: "bottom",
        sourceAnchor: "top",
        style: style || {
          strokeWidth: 1,
          strokeDasharray: "10",
        },
        label,
      };
    case "top2":
      return {
        targetId: targetId.toString(),
        targetAnchor: "bottom",
        sourceAnchor: "top",
        style: style || {
          strokeWidth: 1,
          strokeDasharray: "10",
        },
        label,
      };
    case "bottom2":
      return {
        targetId: targetId.toString(),
        targetAnchor: "top",
        sourceAnchor: "bottom",
        style: style || {
          strokeWidth: 1,
          strokeDasharray: "10",
        },
        label,
      };
    case "bottom":
      return {
        targetId: targetId.toString(),
        targetAnchor: "top",
        sourceAnchor: "bottom",
        style: style || {
          strokeWidth: 1,
          strokeDasharray: "10",
        },
        label,
      };
  }
}
