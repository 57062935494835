import React from "react";
import { Box, Typography } from "@mui/material";
import ListOfLinks from "../components/ListOfLinks";
import ViewWithRelations from "./ViewWithRelationsList";
import IncompatibilityView from "./IncompatibilityView";
import BeersCriteriaView from "./BeersCriteriaView";
// import TableList from "./TableList";
// import GraphList from "./GraphList";

export default function SearchResult({
  results,
  model,
  onClick,
}: {
  results: any[] | any;
  model: any;
  onClick?: Function;
}) {
  const {
    display,
    dataMapping,
    resultString,
    listKeys,
    comingSoon,
    secondary,
  } = model;
  if (comingSoon)
    return <Typography>{`Coming soon: ${comingSoon}`}</Typography>;
  let component = <Typography>{`No ${resultString}`}</Typography>;
  if (!results) return <></>;
  if (results.length === 0) return component;
  switch (display) {
    case "modelview":
      component = (
        <ViewWithRelations
          title={resultString}
          results={results}
          listKeys={listKeys}
          secondary={secondary}
        />
      );
      break;
    case "compatibilitylist":
      component = (
        <IncompatibilityView results={results} title={resultString} />
      );
      break;
    case "beerslist":
      component = <BeersCriteriaView results={results} title={resultString} />;
      break;
    case "list":
      component = (
        <ListOfLinks
          resultString={resultString}
          results={dataMapping(results)}
        />
      );
      break;
    // case "table":
    //   component = (
    //     <Table
    //       data={dataMapping(results)}
    //       tableKeys={listKeys}
    //       searchOn={false}
    //     />
    //   );
    //   break;
    // case "graph":
    //   component = <GraphList results={dataMapping(results[key])} />;
  }

  return <Box>{results && component}</Box>;
}
