import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { sendEmailValidationSchema } from "../schema/signupSchema";
import ApiStatus from "../components/ApiStatus";
import useApiHook from "../hooks/useApiHook";
import { Paper, Typography } from "@mui/material";
type Inputs = { email: string; emailBody: string };
export default function EmailForm({
  email,
  emailApi,
  title,
  bodyLabel,
  bodyPlaceholder,
  onSuccess,
}: {
  email?: string;
  title: string;
  emailApi: any;
  bodyLabel?: string;
  bodyPlaceholder?: string;
  onSuccess?: () => void;
}) {
  const [response, sendEmail] = useApiHook(null, emailApi);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, touchedFields },
  } = useForm<Inputs>({
    resolver: yupResolver(sendEmailValidationSchema),
    defaultValues: { email: email ? email : "" },
  });
  const onSubmit: SubmitHandler<Inputs> = async (values: Inputs) => {
    const { email, emailBody } = values;
    await sendEmail({ email, emailBody });
    if (onSuccess) onSuccess();
    reset();
  };

  return (
    <Grid container>
      <ApiStatus
        error={response.error}
        isLoading={response.isLoading}
        success={response.success}
      />
      <Grid
        container
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          component={Paper}
          elevation={4}
          square
          sx={{
            // marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: "500px",
            pl: { xs: 1, sm: 1, md: 10 },
            pr: { xs: 1, sm: 1, md: 10 },
            pt: { xs: 1, sm: 1, md: 4 },
            pb: { xs: 1, sm: 1, md: 7 },
          }}
        >
          <Typography component="h1" variant="h5" textAlign={"center"}>
            {title}
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              autoComplete="email"
              autoFocus
              disabled={!!email}
              {...register("email")}
              error={touchedFields.email && Boolean(errors.email)}
              helperText={touchedFields.email && errors.email?.message}
            />
            <TextField
              margin="normal"
              multiline
              rows={5}
              required
              fullWidth
              label={bodyLabel ? bodyLabel : "Inquiry"}
              placeholder={bodyPlaceholder ? bodyPlaceholder : ""}
              id="emailBody"
              type={"text"}
              {...register("emailBody")}
              error={touchedFields.emailBody && Boolean(errors.emailBody)}
              helperText={touchedFields.emailBody && errors.emailBody?.message}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Send
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
