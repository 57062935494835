import { Box, Button, LinearProgress, Typography } from "@mui/material";
import { Data } from "helpers/types";
import SourceList from "./SourceList";
import { useContext } from "react";
import { PrefixContext } from "context";
import { useNavigate } from "react-router-dom";
import DynamicDisplay from "./DynamicDisplay";
import { modelByLabelMap } from "models/basicModels";
import ApiStatus from "./ApiStatus";

export default function ModelDetails({
  data,
  isLoading,
  viewButton = true,
}: {
  data: Data;
  isLoading?: boolean;
  viewButton?: boolean;
}) {
  const {
    label,
    id,
    identity,
    source,
    name,
    start,
    end,
    alias,
    isRel,
    type,
    ...rest
  } = data;
  const { prefix } = useContext(PrefixContext);
  const navigate = useNavigate();
  return (
    <Box sx={{ p: 1 }}>
      {isLoading ? (
        <Box
          sx={{
            minWidth: "250px",
          }}
        >
          <LinearProgress />
        </Box>
      ) : (
        <Box>
          {alias && (
            <Box sx={{ display: "flex" }}>
              <Typography
                component="h1"
                variant="h5"
                sx={{
                  whiteSpace: "nowrap",
                }}
              >
                {alias}
              </Typography>
            </Box>
          )}
          {isRel && (
            <Box sx={{ display: "flex" }}>
              <Typography component="h1" variant="subtitle2">
                Relationship
              </Typography>
            </Box>
          )}
          {name && (
            <Box sx={{ display: "flex" }}>
              <Typography
                component="h1"
                variant="h5"
                sx={{
                  whiteSpace: "nowrap",
                }}
              >
                {name}
              </Typography>
            </Box>
          )}
          {label && (
            <Typography component="h1" variant="subtitle1">
              {modelByLabelMap[label].displayName}
            </Typography>
          )}
          {source && <SourceList source={source} />}
          {Object.keys(rest).map((key) => {
            return (
              <DynamicDisplay
                key={key}
                dataKey={key}
                value={rest[key]}
                filterList={["id", "identity", "label", "relation"]}
              />
            );
          })}
          {id && label && viewButton && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <Button
                onClick={() => {
                  navigate(`${prefix}/view/${label}/${id}`);
                }}
              >
                View
              </Button>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}
