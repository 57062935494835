import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import ApiStatus from "components/ApiStatus";
import ModelDetails from "components/ModelDetails";
import Title from "components/Title";
import { mapDataAsCamelCase } from "helpers/dataMapping";
import { Data, Model } from "helpers/types";
import useApiHook from "hooks/useApiHook";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getEmptyArrayRelationship,
  getInitialFromSchema,
} from "schema/commonSchema";
import { patientModel, patientPagesModel } from "models";
import Table from "components/Table";
import { Delete, Edit, Stop } from "@mui/icons-material";
import dayjs from "dayjs";
import CreateEditPrescription from "./CreateEditPrescription";
import PrescriptionAnalysis from "./PrescriptionAnalysisPage";
import CreateRelation from "forms/CreateRelation";
import useConfirm from "hooks/useConfirm";
import ConfirmMessage from "components/ConfirmMessage";
import { PrefixContext } from "context";
import DateStopped from "components/DateStopped";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function PatientViewPage({ model }: { model: Model }) {
  const navigate = useNavigate();
  const { prefix } = useContext(PrefixContext);
  const [tab, setSelectedTab] = useState(0);
  const { api, schema, relations, label } = model;
  const { prescriptions: prescriptionsModel } = patientPagesModel;
  const { id: selectedId } = useParams();
  const emptyValues = getInitialFromSchema(schema);
  const emptyRelations = getEmptyArrayRelationship(relations);
  const [recievedNode, getOne] = useApiHook<Data>(
    {
      id: "",
      ...emptyValues,
      ...emptyRelations,
      label,
    },
    api.getOne,
  );
  const [deletedPatient, deletePatient] = useApiHook(null, api.deleteOne);
  const [getDeleteConfirmation, DeleteConfirmation] = useConfirm((onChange) => (
    <ConfirmMessage
      message={
        "Are you sure you want to permanently delete this patient's information? This action cannot be undone and all associated data will be lost. Please confirm your decision."
      }
      buttonText="Delete"
      onConfirm={onChange}
    />
  ));
  useEffect(() => {
    if (selectedId) getOne(selectedId);
  }, [model, selectedId]);
  const { data: recievedDataDirty } = recievedNode;
  const recievedData = mapDataAsCamelCase(recievedDataDirty, emptyValues);
  const { Prescribed, HasDoctor, KnownAllergies, HasConditions, ...rest } =
    recievedData;
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <ApiStatus
        error={recievedNode.error}
        isLoading={recievedNode.isLoading}
      />
      <ApiStatus
        error={deletedPatient.error}
        isLoading={deletedPatient.isLoading}
      />
      <Paper sx={{ p: 3 }}>
        <Grid container>
          <Grid item xs={12} md={12} lg={12}>
            <ModelDetails
              data={
                patientModel && patientModel.mutate && patientModel.mutate(rest)
              }
              viewButton={false}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Button
              variant="contained"
              onClick={async () => {
                const confirmation =
                  await getDeleteConfirmation("Deleting Patient");
                if (confirmation) {
                  const res = await deletePatient(selectedId);
                  if (res) navigate(`${prefix}/view/patient`);
                }
              }}
            >
              Delete patient
            </Button>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Title>Prescriptions</Title>
            <Table
              showTitle={false}
              defaultSortKey="datePrescribed"
              data={prescriptionsModel
                .dataMapping(Prescribed)
                .map((res: any, index: number) => {
                  const { dateStopped } = res;
                  return {
                    ...res,
                    delete: {
                      value: (
                        <IconButton
                          onClick={async () => {
                            await api?.custom?.deletePrescription({
                              id: res.id,
                              patientId: selectedId,
                            });
                            await getOne(selectedId);
                          }}
                        >
                          <Delete />
                        </IconButton>
                      ),
                    },
                    stopPrescription: {
                      value: (
                        <DateStopped
                          key={index}
                          dateStopped={dateStopped}
                          onClick={async (date) => {
                            await api?.custom?.stopPrescription({
                              id: res.id,
                              patientId: selectedId,
                              dateStopped: date,
                            });
                            await getOne(selectedId);
                          }}
                        />
                      ),
                    },
                  };
                })}
              tableKeys={
                prescriptionsModel.listKeys as {
                  key: string;
                  display: string;
                  sortable: boolean;
                }[]
              }
              searchOn={false}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Title>Allergies</Title>
            <ModelDetails data={{ KnownAllergies }} />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Title>Conditions</Title>
            <ModelDetails data={{ HasConditions }} />
          </Grid>
          <Tabs
            value={tab}
            onChange={(e, newValue) => {
              setSelectedTab(newValue);
            }}
          >
            <Tab label="Add Prescription" {...a11yProps(0)} />
            <Tab label="Add Allergy" {...a11yProps(1)} />
            <Tab label="Add Conditions" {...a11yProps(2)} />
            <Tab label="Analysis" {...a11yProps(3)} />
          </Tabs>
          {tab === 0 && selectedId && (
            <CreateEditPrescription
              model={model}
              patientId={selectedId}
              getPatient={() => {
                getOne(selectedId);
                setSelectedTab(tab + 1);
              }}
            />
          )}

          {tab === 1 &&
            selectedId &&
            patientModel &&
            patientModel.relationsMap && (
              <CreateRelation
                id={selectedId}
                model={patientModel}
                schema={patientModel.relationsMap["KnownAllergies"]}
                onSuccess={() => {
                  getOne(selectedId);
                  setSelectedTab(tab + 1);
                }}
              />
            )}
          {tab === 2 &&
            selectedId &&
            patientModel &&
            patientModel.relationsMap && (
              <CreateRelation
                id={selectedId}
                model={patientModel}
                schema={patientModel.relationsMap["HasConditions"]}
                onSuccess={() => {
                  getOne(selectedId);
                  setSelectedTab(tab + 1);
                }}
              />
            )}
          {tab === 3 && selectedId && (
            <PrescriptionAnalysis patientId={selectedId} />
          )}
        </Grid>
      </Paper>
      <DeleteConfirmation />
    </Container>
  );
}
