import { Box } from "@mui/material";
import { Relations, RelationProperties, ViewRelationship } from "helpers/types";
import Table from "./Table";
import ModelSimple from "./ModelSimple";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import SourceList from "./SourceList";

export default function RelationshipList({
  relationships,
  listKey,
  tableElements,
}: {
  relationships: ViewRelationship[];
  listKey: Relations;
  tableElements?: (row: ViewRelationship) => ReactJSXElement;
}) {
  const tableData = relationships.map((rela) => {
    const { relation, ...rest } = rela;
    return {
      model: {
        value: <ModelSimple modelElement={rest} />,
        searchString: rest.name,
      },
      ...relation,
      relationSource: { value: <SourceList source={relation?.source || []} /> },
      onClick: tableElements
        ? {
            value: tableElements(rela),
          }
        : undefined,
    };
  });
  return (
    <Box sx={{ overflow: "auto", height: "45vw", minWidth: "40vw" }}>
      <Table
        tableName={listKey.displayName}
        showTitle
        tableKeys={[
          { key: "model", display: listKey.relatedModel.displayName },
          ...listKey.properties.map((prop: RelationProperties) => ({
            key: prop.property,
            display: prop.key,
          })),
          { key: "relationSource", display: "Relation Source" },
        ]}
        data={tableData}
      />
    </Box>
  );
}
