import * as yup from "yup";
import { idMapValidation } from "../schema/commonSchema";
import {
  Ingredient,
  Manufacturer,
  ClinicalDrug,
  DoseForm,
  SideEffect,
  NDC,
  Label,
} from "./basicModels";

Label.relations = [
  /* -------------------------------------------------------------------------- */
  /*                             Outgoing Relations                             */
  /* -------------------------------------------------------------------------- */
  {
    relatedModel: Ingredient,
    name: "IncludesIngredient",
    displayName: "Includes",
    properties: [
      {
        key: "Active",
        property: "active",
        validation: yup.string(),
        type: "string",
        default: "",
      },
    ],
    schema: idMapValidation,
  },
  {
    relatedModel: Manufacturer,
    name: "ManufacturedBy",
    displayName: "Manufactured By",
    properties: [],
    schema: idMapValidation,
  },
  {
    relatedModel: ClinicalDrug,
    name: "IncludesClinicalDrug",
    displayName: "Includes",
    properties: [],
    schema: idMapValidation,
  },
  {
    relatedModel: DoseForm,
    name: "IncludesDoseForm",
    displayName: "Includes",
    properties: [],
    schema: idMapValidation,
  },
  {
    relatedModel: Manufacturer,
    name: "ManufacturedFor",
    displayName: "ManufacturedFor",
    properties: [],
    schema: idMapValidation,
  },
  {
    relatedModel: SideEffect,
    name: "HasSideEffect",
    displayName: "Causes",
    properties: [],
    schema: idMapValidation,
  },
  /* -------------------------------------------------------------------------- */
  /*                             Incoming Relations                             */
  /* -------------------------------------------------------------------------- */
  {
    relatedModel: NDC,
    name: "PartOfLabel",
    displayName: "Part Of",
    properties: [],
    schema: idMapValidation,
  },
  /* -------------------------------------------------------------------------- */
  /*                               Self Relations                               */
  /* -------------------------------------------------------------------------- */
];
export const labelModel = Label;
