import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Dialog,
  Backdrop,
  CircularProgress,
  AlertColor,
} from "@mui/material";
import { Children } from "helpers/types";

type DialogProperties = {
  severity: AlertColor;
  message?: string;
  status?: any;
};
type Props = {
  success?: boolean;
  isLoading?: boolean;
  successMessage?: string;
  error?: {
    status?: string;
    message?: string;
  };
  customLoader?: Children;
};
function ApiStatus({
  success,
  isLoading,
  error,
  successMessage = "",
  customLoader,
}: Props) {
  const dialogInitial: DialogProperties = {
    severity: "success",
    message: "Success",
  };
  const [open, setOpen] = useState(false);
  const [dialog, setDialog] = useState(dialogInitial);
  const handleOpen = () => setOpen(true);
  const handleClick = () => {
    setOpen(!open);
  };
  useEffect(() => {
    if (success)
      setDialog({
        severity: "success",
        status: "Success",
        message: successMessage,
      });
    if (error && error.status)
      setDialog({
        severity: "error",
        status: `Error Status: ${error.status}`,
        message: error.message,
      });
    if (success || error?.status) handleOpen();
  }, [success, error?.status]);
  return (
    <Box>
      <Dialog open={open} onClose={handleClick}>
        <Alert severity={dialog.severity}>
          <AlertTitle>{dialog.status}</AlertTitle>
          {dialog?.message}
        </Alert>
      </Dialog>
      {isLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1000 }}
          open={isLoading}
        >
          {(typeof customLoader === "function"
            ? customLoader()
            : customLoader) || <CircularProgress color="inherit" />}
        </Backdrop>
      )}
    </Box>
  );
}

export default ApiStatus;
