import { Box, Typography } from "@mui/material";
export function QueryDisclaimer() {
  return (
    <Box>
      <Typography variant="h5" component="h2" gutterBottom>
        Search Disclaimer
      </Typography>
      <Typography variant="body1">
        The medical information provided in response to your query is intended
        for general informational purposes only. The details shared are based on
        the knowledge available up to the present date and may not encompass the
        entirety of the information contained in the prescription leaflet.
        Leaflit Care Inc strives to ensure the accuracy and completeness of the
        medical information provided, but it is crucial to acknowledge that
        medical knowledge is continually evolving. New research findings,
        updates, or changes in medical guidelines may not be immediately
        reflected in the information provided. Therefore, we strongly recommend
        that you independently review and cross-reference the information
        provided with the prescription leaflet accompanying the medication in
        question. The prescription leaflet is the primary source of accurate and
        up-to-date information regarding the medication, including indications,
        contraindications, side effects, and dosage instructions. Leaflit Care
        Inc disclaims any liability or responsibility for the accuracy,
        completeness, or relevance of the information provided in response to
        your query. It is advisable to consult with a qualified healthcare
        professional or refer to the most recent version of the prescription
        leaflet for the most accurate and current information related to your
        specific medical situation. By using the information provided, you
        acknowledge and agree to the limitations of the data presented and
        understand the importance of verifying details through official and
        updated sources, particularly the prescription leaflet provided with the
        medication. If you have specific concerns refer to the leaflet.
      </Typography>
    </Box>
  );
}
export default function Disclaimer() {
  return (
    <Box mt={3}>
      <Typography variant="h5" component="h2" gutterBottom>
        Disclaimer
      </Typography>
      <ol>
        <li>
          <Typography variant="body1">
            This website provides healthcare-related information sourced from
            external providers and is intended solely for healthcare
            professionals. The content on this website is not intended for, and
            should not be used by, the general public. It is designed for
            informational purposes related to medical practice, healthcare
            research, and education. It is your responsibility to conduct your
            own research and read the leaflets provided by the manufacturer
            before making any decision
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Accuracy and Reliability: While we endeavor to ensure the accuracy
            and reliability of the information provided, we do not guarantee the
            completeness, accuracy, or timeliness of the content. Information on
            this website may change or become outdated, and we are not
            responsible for any errors, omissions, or discrepancies in the
            information presented.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Healthcare Professional's Role: The decision regarding medical
            diagnosis, treatment, and patient care should always be made in
            consultation with qualified healthcare professionals. This website
            does not provide medical advice or treatment recommendations, and it
            should not be used as a substitute for seeking professional medical
            guidance.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Liability: We shall not be held liable for any reliance placed on
            the information provided on this website by healthcare
            professionals, nor for any consequences that may arise from such
            reliance. We disclaim any responsibility for any direct or indirect
            loss or damage resulting from the use of this website by healthcare
            professionals.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            External Links: This website may contain links to external websites
            and resources. We are not responsible for the content, policies, or
            practices of these external websites, and their inclusion here does
            not imply endorsement or affiliation.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Information Changes: The information on this website may be updated
            or modified without notice. We reserve the right to make changes to
            the content and disclaimers without prior notification.
          </Typography>
        </li>
      </ol>
      <Typography variant="body1">
        By using this website, you confirm that you are a healthcare
        professional and agree to the terms of this disclaimer. If you do not
        meet this qualification, please refrain from using this website. If you
        have specific healthcare concerns or questions, we encourage you to seek
        guidance from a qualified healthcare professional.
      </Typography>
      <QueryDisclaimer />
    </Box>
  );
}
