import { Alert, Box, Button, Grid } from "@mui/material";
import ApiStatus from "components/ApiStatus";
import { Data, Model, Relations } from "helpers/types";
import useApiHook from "hooks/useApiHook";
import ModelSelectInput from "inputs/ModelSelectInput";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

export default function CreateRelation({
  model,
  schema,
  id,
  onSuccess,
}: {
  model: Model;
  schema: Relations;
  id: string;
  onSuccess?: () => void;
}) {
  const api = model.api.custom?.createRelation;
  if (!api) throw "Model does not have create relation";
  const { relatedModel } = schema;
  const [relationCreated, createRelation] = useApiHook(null, api);
  const {
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting, errors, isSubmitted },
    setError,
  } = useForm<Data>({
    defaultValues: {},
  });
  const onSubmit: SubmitHandler<Data> = async (values: Data, e) => {
    if (e) e.preventDefault();
    const { [relatedModel.label]: targets } = values;
    if (!targets || targets.length === 0) {
      setError(relatedModel.label, {
        type: "manual",
        message: `Atleast one ${relatedModel.label} is required`,
      });
      return;
    }
    await createRelation(schema.name, {
      sourceId: id,
      targets: targets.map((id: string) => ({
        id,
      })),
    });
    if (onSuccess) onSuccess();
    reset();
  };
  return (
    <Grid container>
      <ApiStatus
        error={relationCreated.error}
        isLoading={relationCreated.isLoading}
      />
      <Grid
        item
        xs={6}
        sm={6}
        md={6}
        lg={6}
        xl={6}
        alignItems="center"
        justifyContent="center"
      >
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 1 }}
        >
          <Controller
            control={control}
            name={relatedModel.label}
            render={({ field }) => {
              return (
                <ModelSelectInput
                  model={relatedModel}
                  multiple={true}
                  field={field}
                />
              );
            }}
          />
          {isSubmitted && Boolean(errors[relatedModel.label]) && (
            <Box sx={{ width: "100%", mt: 1 }}>
              <Alert severity="error" icon={false}>
                {errors[relatedModel.label]?.message?.toString()}
              </Alert>
            </Box>
          )}
          <Button variant="contained" type="submit" sx={{ m: 2 }}>
            Add {schema.displayName}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}
