import {
  init,
  loaderRunner,
  redirectToUserPrefix,
  termsOfService,
  userChecks,
} from "./helpers";
export const standardLoader = async () => {
  return loaderRunner([
    await init(),
    await userChecks(),
    termsOfService(),
    await redirectToUserPrefix("/standard"),
  ]);
};
