import {
  BrandName,
  ClinicalDrug,
  DoseForm,
  DrugPack,
  Ingredient,
  Label,
  Patient,
  SideEffect,
  Target,
  User,
} from "./basicModels";
import * as yup from "yup";
import { idMapValidation } from "../schema/commonSchema";
import { Link, ResultModel, SearchInput, Node } from "helpers/types";
import dayjs from "dayjs";
Patient.relations = [
  {
    relatedModel: User,
    name: "HasDoctor",
    displayName: "Doctors",
    properties: [],
    schema: idMapValidation,
  },
  {
    relatedModel: Ingredient,
    name: "Prescribed",
    displayName: "Prescriptions",
    properties: [
      {
        key: "Targets",
        property: "targets",
        validation: yup.string(),
        type: "string",
        default: "",
      },
    ],
    schema: idMapValidation,
  },
  {
    relatedModel: Ingredient,
    name: "KnownAllergies",
    displayName: "Allergies",
    properties: [],
    schema: idMapValidation,
  },
  {
    relatedModel: Target,
    name: "HasConditions",
    displayName: "Conditions",
    properties: [],
    schema: idMapValidation,
  },
];
Patient.relationsMap = {};
Patient.relations.map((rel) => {
  if (Patient.relationsMap) Patient.relationsMap[rel.name] = rel;
});
const PatientPagesModel: {
  searchInputs: { [key: string]: SearchInput };
  targetInput: SearchInput;
  prescriptions: ResultModel;
  dateSchema: any;
  analysisResultModel: ResultModel[];
} = {
  searchInputs: {
    activeIng: {
      model: Ingredient,
      multiple: false,
      searchBy: "Active Ingredients / Generic Name",
      min: 1,
      where: "active",
      secondarySelect: { model: DoseForm, relation: "HasDoseForm" },
    },
    label: {
      model: Label,
      multiple: false,
      searchBy: "Label",
      findByString: "set_id",
      min: 1,
      secondarySelect: { model: DoseForm, relation: "IncludesDoseForm" },
    },
    brandName: {
      model: BrandName,
      multiple: false,
      min: 1,
      secondarySelect: { model: DoseForm, relation: "HasDoseForm" },
    },
  },
  targetInput: {
    model: Target,
    multiple: true,
    searchBy: "Prescribed For",
    type: [
      { typeName: "prescribedfor", typeDisplay: "Specific" },
      { typeName: "prescribedforgeneral", typeDisplay: "General" },
    ],
    min: 1,
  },
  dateSchema: {
    key: "DatePrescribed",
    property: "datePrescribed",
    validation: yup
      .number()
      .typeError("Must add date prescribed")
      .test(
        "Is positive?",
        "Date Prescribed must be greater than 0!",
        (value: number | undefined) => !!value && value > -2179872001000,
      ),
    type: "datepicker",
    default: Date.now,
  },
  prescriptions: {
    key: "Prescribed",
    resultString: "Prescriptions",
    display: "table",
    listKeys: [
      { key: "name", display: "Name", numeric: false, sortable: true },
      { key: "label", display: "Type", numeric: false, sortable: true },
      { key: "targets", display: "Targets", numeric: false, sortable: true },
      {
        key: "datePrescribed",
        display: "Date Prescribed",
        numeric: false,
        sortable: true,
      },
      {
        key: "dateStopped",
        display: "Date Stopped",
        numeric: false,
        sortable: true,
        color: "error",
      },
      { key: "stopPrescription", display: "Stop" },
      { key: "doseForm", display: "Dose Form", numeric: false, sortable: true },
      {
        key: "genericName",
        display: "Generic name",
        numeric: false,
        sortable: true,
      },
      { key: "delete", display: "Delete" },
    ],
    dataMapping: (data: any) =>
      data.map((dt: any) => ({
        ...dt,
        datePrescribed: dayjs(dt.datePrescribed).format("L"),
        dateStopped: dt.dateStopped ? dayjs(dt.dateStopped).format("L") : "",
      })),
  },
  analysisResultModel: [
    {
      key: "contraIndications",
      resultString: "Contra Indications to patient conditions",
      display: "modelview",
      listKeys: {
        properties: [
          {
            key: "name",
            display: "Name",
          },
        ],
        relations: [
          {
            name: "BrandName",
            relatedModel: BrandName,
            displayName: "Found In",
            properties: [],
          },
          {
            name: "ClinicalDrug",
            relatedModel: ClinicalDrug,
            displayName: "Found In",
            properties: [],
          },
          {
            name: "DoseForm",
            relatedModel: DoseForm,
            displayName: "Found In",
            properties: [],
          },
          {
            name: "DrugPack",
            relatedModel: DrugPack,
            displayName: "Found In",
            properties: [],
          },
          {
            name: "Label",
            relatedModel: Label,
            displayName: "Found In",
            properties: [],
          },
          {
            name: "Target",
            relatedModel: Target,
            displayName: "Conditions",
            properties: [],
          },
        ],
      },
      dataMapping: (results: any) => {
        return results;
      },
    },
    {
      key: "allergies",
      resultString: "Prescriptions with ingredient that patient is allergic to",
      display: "modelview",
      listKeys: {
        properties: [
          {
            key: "name",
            display: "Name",
          },
        ],
        relations: [
          {
            name: "BrandName",
            relatedModel: BrandName,
            displayName: "Found In",
            properties: [],
          },
          {
            name: "ClinicalDrug",
            relatedModel: ClinicalDrug,
            displayName: "Found In",
            properties: [],
          },
          {
            name: "DoseForm",
            relatedModel: DoseForm,
            displayName: "Found In",
            properties: [],
          },
          {
            name: "DrugPack",
            relatedModel: DrugPack,
            displayName: "Found In",
            properties: [],
          },
          {
            name: "Label",
            relatedModel: Label,
            displayName: "Found In",
            properties: [],
          },
        ],
      },
      dataMapping: (results: any) => {
        return results;
      },
    },
    {
      key: "ingProperties",
      resultString:
        "Possible Allergens Found For Active Ingredients Through Labels",
      display: "modelview",
      listKeys: {
        properties: [
          {
            key: "name",
            display: "Name",
          },
        ],
        relations: [
          {
            name: "SideEffect",
            relatedModel: SideEffect,
            displayName: "Causes",
            properties: [],
          },
          {
            name: "BrandName",
            relatedModel: BrandName,
            displayName: "Found In",
            properties: [],
          },
          {
            name: "ClinicalDrug",
            relatedModel: ClinicalDrug,
            displayName: "Found In",
            properties: [],
          },
        ],
      },
      dataMapping: (results: any) => {
        return results;
      },
    },
    {
      key: "directAllIng",
      resultString:
        "Possible Allergens Found for all Ingredients Through other studies",
      display: "modelview",
      listKeys: {
        properties: [
          {
            key: "name",
            display: "Name",
          },
        ],
        relations: [
          {
            name: "SideEffect",
            relatedModel: SideEffect,
            displayName: "Causes",
            properties: [],
          },
          {
            name: "BrandName",
            relatedModel: BrandName,
            displayName: "Found In",
            properties: [],
          },
          {
            name: "ClinicalDrug",
            relatedModel: ClinicalDrug,
            displayName: "Found In",
            properties: [],
          },
        ],
      },
      dataMapping: (results: any) => {
        return results;
      },
    },
    {
      key: "incompatibleIngredients",
      resultString: "Interactions",
      display: "compatibilitylist",
      listKeys: ["startNode", "relationship", "endNode"],
      listKeyReturns: {
        startNode: (node: Node) => node.name,
        endNode: (node: Node) => node.name,
        relationship: (relation: Link[]) =>
          relation.map((rel) => {
            const { compatibilityType, description } = rel;
            return {
              compatibilityType,
              description,
            };
          }),
      },
      dataMapping: (results: any) => results,
    },
    {
      key: "beersCriteria",
      resultString: "Beers Criteria",
      display: "beerslist",
      dataMapping: (results: any) => results,
    },
  ],
};
export const patientModel = Patient;
export const patientPagesModel = PatientPagesModel;
