import * as yup from "yup";
import { idMapValidation } from "../schema/commonSchema";
import { Ingredient, DrugPack, ClinicalDrug, BrandName } from "./basicModels";

BrandName.relations = [
  /* -------------------------------------------------------------------------- */
  /*                             Outgoing Relations                             */
  /* -------------------------------------------------------------------------- */
  {
    relatedModel: Ingredient,
    name: "HasIngredient",
    displayName: "Has",
    properties: [
      {
        key: "Active",
        property: "active",
        validation: yup.string(),
        type: "string",
        default: "",
      },
      {
        key: "Amount",
        property: "amount",
        validation: yup.string(),
        type: "string",
        default: "",
      },
    ],
    schema: idMapValidation,
  },
  {
    relatedModel: DrugPack,
    name: "HasDrugPack",
    displayName: "Has",
    properties: [],
    schema: idMapValidation,
  },
  /* -------------------------------------------------------------------------- */
  /*                             Incoming Relations                             */
  /* -------------------------------------------------------------------------- */
  {
    relatedModel: Ingredient,
    name: "CompatibilityWithIngredient",
    displayName: "Compatibility",
    properties: [],
    schema: idMapValidation,
  },
  {
    relatedModel: ClinicalDrug,
    name: "HasClinicalDrug",
    displayName: "Has",
    properties: [],
    schema: idMapValidation,
  },
  /* -------------------------------------------------------------------------- */
  /*                               Self Relations                               */
  /* -------------------------------------------------------------------------- */
];
export const brandNameModel = BrandName;
