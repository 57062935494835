import { getGraphDataFromPath } from "../helpers/graphDisplay";
import * as yup from "yup";
import {
  findIncompatMedFromList,
  findIngFromMedSE,
  perscriptionSequence,
  findBy,
  findByWithFilter,
  findIncompatIngFromList,
  findFdaLabelWithInactive,
  findTreatment,
  findBeersCriteriasForList,
  sideEffectFind,
  findAlternateTreatement,
  allSideEffects,
} from "../api";
import {
  ClinicalDrug,
  Label,
  Group,
  Ingredient,
  BrandName,
  MoA,
  SideEffect,
  Target,
} from "./basicModels";
import { idMapValidation } from "../schema/commonSchema";
import {
  Data,
  Link,
  Node,
  SearchModel,
  Path,
  FindModel,
  ListKeys,
} from "../helpers/types";

const Search: SearchModel = {
  label: "Search",
  schema: {
    prescriptionSequence: {
      key: "prescriptionSequence",
      name: "Find Prescription Sequence",
      searchInputs: [{ model: BrandName, multiple: true }],
      resultModels: [
        {
          key: "sequences",
          display: "graph",
          dataMapping: (results: Path[]) => {
            return getGraphDataFromPath(results);
          },
        },
      ],
      api: perscriptionSequence,
    },
    ingFromMedSE: {
      key: "ingFromMedSE",
      name: "Find possible allergens from Prescriptions and Side Effect",
      hasBeers: true,
      searchInputs: [
        [
          { model: BrandName, multiple: true, min: 0 },
          {
            model: Ingredient,
            multiple: true,
            searchBy: "Active Ingredients / Generic Name",
            min: 0,
            where: "active",
          },
          { model: ClinicalDrug, multiple: true, min: 0 },
        ],
        { model: SideEffect, multiple: true },
      ],
      resultModels: [
        {
          key: "ingProperties",
          resultString:
            "Possible Allergens Found For Active Ingredients Through Labels",
          display: "modelview",
          listKeys: {
            properties: [
              {
                key: "name",
                display: "Name",
              },
            ],
            relations: [
              {
                name: "SideEffect",
                relatedModel: SideEffect,
                displayName: "Causes",
                properties: [],
              },
              {
                name: "BrandName",
                relatedModel: BrandName,
                displayName: "Found In",
                properties: [],
              },
              {
                name: "ClinicalDrug",
                relatedModel: ClinicalDrug,
                displayName: "Found In",
                properties: [],
              },
            ],
          },
          dataMapping: (results: any) => {
            return results;
          },
        },
        {
          key: "directAllIng",
          resultString:
            "Possible Allergens Found for all Ingredients Through other studies",
          display: "modelview",
          listKeys: {
            properties: [
              {
                key: "name",
                display: "Name",
              },
            ],
            relations: [
              {
                name: "SideEffect",
                relatedModel: SideEffect,
                displayName: "Causes",
                properties: [],
              },
              {
                name: "BrandName",
                relatedModel: BrandName,
                displayName: "Found In",
                properties: [],
              },
              {
                name: "ClinicalDrug",
                relatedModel: ClinicalDrug,
                displayName: "Found In",
                properties: [],
              },
            ],
          },
          dataMapping: (results: any) => {
            return results;
          },
        },
        {
          key: "incompatibleIngredients",
          resultString: "Interactions",
          display: "compatibilitylist",
          listKeys: ["startNode", "relationship", "endNode"],
          listKeyReturns: {
            startNode: (node: Node) => node.name,
            endNode: (node: Node) => node.name,
            relationship: (relation: Link[]) =>
              relation.map((rel) => {
                const { compatibilityType, description } = rel;
                return {
                  compatibilityType,
                  description,
                };
              }),
          },
          dataMapping: (results: any) => results,
        },
        {
          key: "beersCriteria",
          resultString: "Beers Criteria",
          display: "beerslist",
          dataMapping: (results: any) => results,
        },
      ],
      api: findIngFromMedSE,
    },
    incompatMedFromList: {
      key: "incompatMedFromList",
      name: "Find interactions between brand name prescriptions",
      searchInputs: [{ model: BrandName, multiple: true, min: 2 }],
      resultModels: [
        {
          key: "incompatibleBrandNames",
          resultString: "Interactions",
          display: "compatibilitylist",
          listKeys: ["startNode", "relationship", "endNode"],
          listKeyReturns: {
            startNode: (node: Node) => node.name,
            endNode: (node: Node) => node.name,
            relationship: (relation: Link[]) =>
              relation.map((rel) => {
                const { compatibilityType, description } = rel;
                return {
                  compatibilityType,
                  description,
                };
              }),
          },
          dataMapping: (results: any) => results,
        },
      ],
      api: findIncompatMedFromList,
    },
    incompatIngFromList: {
      key: "incompatIngFromList",
      name: "Find interactions between active ingredients",
      searchInputs: [
        {
          model: { ...BrandName, color: "#6100DF" },
          multiple: true,
          min: 2,
          type: "activebn",
          name: "Ingredient",
          searchBy: "Ingredients",
        },
      ],
      resultModels: [
        {
          key: "incompatibleIngredients",
          resultString: "Interactions",
          display: "compatibilitylist",
          listKeys: ["startNode", "relationship", "endNode"],
          listKeyReturns: {
            startNode: (node: Node) => node.name,
            endNode: (node: Node) => node.name,
            relationship: (relation: Link[]) =>
              relation.map((rel) => {
                const { compatibilityType, description } = rel;
                return {
                  compatibilityType,
                  description,
                };
              }),
          },
          dataMapping: (results: any) => results,
        },
      ],
      api: findIncompatIngFromList,
    },
    labelWithInactive: {
      key: "labelWithInactive",
      name: "Find labels that contain inactive ingredients",
      searchInputs: [
        [
          {
            model: BrandName,
            multiple: true,
            min: 0,
          },
          {
            model: { ...Ingredient, color: "#6100DF" },
            multiple: true,
            name: "ActiveIngredients",
            searchBy: " Active Ingredients",
            min: 0,
            where: "active",
          },
        ],
        {
          model: { ...Ingredient, color: "#DF6100" },
          multiple: true,
          name: "InactiveIngredients",
          searchBy: "Inactive Ingredients",
          where: "inactive",
        },
      ],
      resultModels: [
        {
          key: "fdaResults",
          resultString: "Labels found with inactive ingredient present",
          display: "modelview",
          secondary: (res: { ing: { name: string }[] }) => ({
            primary: res.ing.map((ingre) => ingre.name).join("/"),
            secondary: "Active Ingredient",
          }),
          listKeys: {
            properties: [
              {
                key: "name",
                display: "Name",
              },
            ],
            relations: [
              {
                name: "inIng",
                relatedModel: Ingredient,
                displayName: "Inactive",
                properties: [],
              },
              // {
              //   name: "med",
              //   relatedModel: BrandName,
              //   displayName: "Brand Name",
              //   properties: [],
              // },
              {
                name: "ing",
                relatedModel: Ingredient,
                displayName: "Active",
                properties: [],
              },
            ],
          },
          dataMapping: (results: any) => {
            return results;
          },
        },
      ],
      api: findFdaLabelWithInactive,
    },
    treatmentFind: {
      key: "treatmentFind",
      name: "Find treatment without allergen (Inactive Ingredient)",
      searchInputs: [
        [
          {
            model: MoA,
            multiple: true,
            min: 0,
          },
          {
            model: Target,
            multiple: true,
            min: 0,
          },
          {
            model: Group,
            multiple: true,
            min: 0,
          },
        ],
        {
          model: Ingredient,
          multiple: true,
          name: "InactiveIngredients",
          searchBy: "Inactive Ingredients",
          where: "inactive",
        },
      ],
      resultModels: [
        {
          key: "directMoA",
          resultString:
            "Direct MOA treatment found without inactive ingredient",
          display: "modelview",
          listKeys: {
            properties: [
              {
                key: "name",
                display: "Name",
              },
            ],
            relations: [
              {
                name: "fda",
                relatedModel: Label,
                displayName: "Label",
                properties: [],
              },
              {
                name: "n",
                relatedModel: MoA,
                displayName: "MOA",
                properties: [],
              },
            ],
          },
          dataMapping: (results: any) => {
            return results;
          },
        },
        {
          key: "inDirectMoA",
          resultString:
            "Indirect MOA treatment found without inactive ingredient",
          display: "modelview",
          listKeys: {
            properties: [
              {
                key: "name",
                display: "Name",
              },
            ],
            relations: [
              {
                name: "fda",
                relatedModel: Label,
                displayName: "Label",
                properties: [],
              },
              {
                name: "n",
                relatedModel: MoA,
                displayName: "MOA",
                properties: [],
              },
            ],
          },
          dataMapping: (results: any) => {
            return results;
          },
        },
        {
          key: "directTarget",
          resultString:
            "Direct Target treatment found without inactive ingredient",
          display: "modelview",
          listKeys: {
            properties: [
              {
                key: "name",
                display: "Name",
              },
            ],
            relations: [
              {
                name: "fda",
                relatedModel: Label,
                displayName: "Label",
                properties: [],
              },
              {
                name: "n",
                relatedModel: Target,
                displayName: "Target",
                properties: [],
              },
            ],
          },
          dataMapping: (results: any) => {
            return results;
          },
        },
        {
          key: "inDirectTarget",
          resultString:
            "Indirect Target treatment found without inactive ingredient",
          display: "modelview",
          listKeys: {
            properties: [
              {
                key: "name",
                display: "Name",
              },
            ],
            relations: [
              {
                name: "fda",
                relatedModel: Label,
                displayName: "Label",
                properties: [],
              },
              {
                name: "n",
                relatedModel: Target,
                displayName: "Traget",
                properties: [],
              },
            ],
          },
          dataMapping: (results: any) => {
            return results;
          },
        },
        {
          key: "directGroup",
          resultString:
            "Direct Group treatment found without inactive ingredient",
          display: "modelview",
          listKeys: {
            properties: [
              {
                key: "name",
                display: "Name",
              },
            ],
            relations: [
              {
                name: "fda",
                relatedModel: Label,
                displayName: "Label",
                properties: [],
              },
              {
                name: "n",
                relatedModel: Group,
                displayName: "Group",
                properties: [],
              },
            ],
          },
          dataMapping: (results: any) => {
            return results;
          },
        },
        {
          key: "inDirectGroup",
          resultString:
            "Indirect Group treatment found without inactive ingredient",
          display: "modelview",
          listKeys: {
            properties: [
              {
                key: "name",
                display: "Name",
              },
            ],
            relations: [
              {
                name: "fda",
                relatedModel: Label,
                displayName: "Label",
                properties: [],
              },
              {
                name: "n",
                relatedModel: Group,
                displayName: "Group",
                properties: [],
              },
            ],
          },
          dataMapping: (results: any) => {
            return results;
          },
        },
      ],
      api: findTreatment,
    },
    beersCriteria: {
      key: "beersCriteria",
      name: "Find Beers Criteria for list of prescriptions",
      searchInputs: [
        [
          { model: BrandName, multiple: true, min: 0 },
          {
            model: Ingredient,
            multiple: true,
            searchBy: "Active Ingredients / Generic Name",
            min: 0,
            where: "active",
          },
          { model: ClinicalDrug, multiple: true, min: 0 },
        ],
      ],
      resultModels: [
        {
          key: "beersCriteria",
          resultString: "Beers Criteria",
          display: "beerslist",
          dataMapping: (results: any) => results,
        },
      ],
      api: findBeersCriteriasForList,
    },
    sideEffectFind: {
      key: "sideEffectFind",
      name: "Find if side effect is from list of prescriptions",
      searchInputs: [
        [
          {
            model: BrandName,
            multiple: true,
            min: 0,
          },
          {
            model: { ...Ingredient, color: "#6100DF" },
            multiple: true,
            name: "ActiveIngredients",
            searchBy: " Active Ingredients",
            min: 0,
            where: "active",
          },
        ],
        {
          model: SideEffect,
          multiple: true,
          min: 1,
        },
      ],
      resultModels: [
        {
          key: "bnProperties",
          resultString: "Brand names found with chosen side effects",
          display: "modelview",
          listKeys: {
            properties: [
              {
                key: "name",
                display: "Name",
              },
            ],
            relations: [
              {
                name: "SideEffect",
                relatedModel: SideEffect,
                displayName: "Causes",
                properties: [],
              },
            ],
          },
          dataMapping: (results: any) => {
            return results;
          },
        },
        {
          key: "ingProperties",
          resultString: "Active ingredients found with chosen side effects",
          display: "modelview",
          listKeys: {
            properties: [
              {
                key: "name",
                display: "Name",
              },
            ],
            relations: [
              {
                name: "SideEffect",
                relatedModel: SideEffect,
                displayName: "Causes",
                properties: [],
              },
            ],
          },
          dataMapping: (results: any) => {
            return results;
          },
        },
        {
          key: "directAllIng",
          resultString: "Other possible ingredients causing side effects",
          display: "modelview",
          listKeys: {
            properties: [
              {
                key: "name",
                display: "Name",
              },
            ],
            relations: [
              {
                name: "SideEffect",
                relatedModel: SideEffect,
                displayName: "Causes",
                properties: [],
              },
            ],
          },
          dataMapping: (results: any) => {
            return results;
          },
        },
      ],
      api: sideEffectFind,
    },
    alternateTreatment: {
      key: "alternateTreatment",
      name: "Find alternate treatmet by MoA or Group",
      searchInputs: [
        [
          { model: BrandName, multiple: false, min: 0 },
          {
            model: { ...Ingredient, color: "#6100DF" },
            multiple: true,
            name: "ActiveIngredients",
            searchBy: " Active Ingredients",
            min: 0,
            where: "active",
          },
          { model: ClinicalDrug, multiple: false, min: 0 },
        ],
        [
          { model: MoA, multiple: false },
          { model: Group, multiple: false },
        ],
      ],
      resultModels: [
        {
          key: "alternateTreatmentMoA",
          resultString: "Other ingredients treating MoA",
          display: "modelview",
          listKeys: {
            properties: [
              {
                key: "name",
                display: "Name",
              },
            ],
            relations: [
              {
                name: "MoA",
                relatedModel: MoA,
                displayName: "Has MoA",
                properties: [],
              },
            ],
          },
          dataMapping: (results: any) => {
            return results;
          },
        },
        {
          key: "alternateTreatmentVA",
          resultString: "Other ingredients treating Group VA",
          display: "modelview",
          listKeys: {
            properties: [
              {
                key: "name",
                display: "Name",
              },
            ],
            relations: [
              {
                name: "Group",
                relatedModel: Group,
                displayName: "Has VA",
                properties: [],
              },
            ],
          },
          dataMapping: (results: any) => {
            return results;
          },
        },
        {
          key: "alternateTreatmentATC",
          resultString: "Other ingredients treating Group ATC",
          display: "modelview",
          listKeys: {
            properties: [
              {
                key: "name",
                display: "Name",
              },
            ],
            relations: [
              {
                name: "Group",
                relatedModel: Group,
                displayName: "Has ATC",
                properties: [],
              },
            ],
          },
          dataMapping: (results: any) => {
            return results;
          },
        },
      ],
      api: findAlternateTreatement,
    },
    allSideEffects: {
      key: "allSideEffects",
      name: "Find all side effect for active ingredient",
      searchInputs: [
        {
          model: { ...Ingredient, color: "#6100DF" },
          multiple: true,
          name: "ActiveIngredients",
          searchBy: "Active Ingredients",
          min: 0,
          where: "active",
        },
      ],
      resultModels: [
        {
          key: "sideEffects",
          resultString: "Side Effects",
          display: "modelview",
          listKeys: {
            properties: [
              {
                key: "name",
                display: "Name",
              },
            ],
            relations: [
              {
                name: "Label",
                relatedModel: Label,
                displayName: "Listed In",
                properties: [],
              },
            ],
          },
          dataMapping: (results: any) => {
            return results;
          },
        }, 
      ],
      api: allSideEffects,
    },
  },
};

const Find: FindModel = {
  label: "Search",
  searchInputs: [
    {
      key: "findBy",
      validation: yup.string(),
      type: "select",
      valueOptions: ["name", "rxcui", "set_id", "meddra_id"],
      default: "name",
    },
    {
      key: "filter",
      validation: yup.string(),
      required: false,
      type: "select",
      valueOptions: [
        { display: "All", value: "All" },
        { display: "RX Classes", value: "Group" },
        { display: "Ingredient Group", value: "IngredientGroup" },
        { display: "Ingredient", value: "Ingredient" },
        { display: "Manufacturer", value: "Manufacturer" },
        { display: "Brand Name", value: "BrandName" },
        { display: "Dose Form", value: "DoseForm" },
        { display: "Side Effect Group", value: "SideEffectGroup" },
        { display: "Side Effect", value: "SideEffect" },
        { display: "Disease/Target", value: "Target" },
        { display: "Clinical Drug or Pack", value: "ClinicalDrug" },
        { display: "Label", value: "Label" },
        { display: "Mechanism Of Action", value: "MoA" },
        { display: "Schedule", value: "Schedule" },
        { display: "Drug Pack", value: "DrugPack" },
        { display: "Beers Criteria", value: "BeersCriteria" },
      ],
      default: "",
      mapped: true,
    },
    {
      key: "findString",
      validation: yup.string().trim().required("Field is required"),
      required: true,
      type: "string",
      default: "",
    },
  ],
  schema: [
    {
      key: "results",
      name: "Found",
      resultModels: [
        {
          key: "result",
          display: "list",
          resultString: "Results",
          listKeys: ["link"],
          dataMapping: (results: Data) => {
            return results.map((res: { id: string; label: string }) => {
              const { id, label } = res;
              return {
                ...res,
                link: `/view/${label}/${id}`,
              };
            });
          },
        },
      ],
      api: findBy,
    },
  ],
};
const TrgSeModel = {
  label: "Search",
  relations: [
    {
      relatedModel: Target,
      name: "IsTarget",
      properties: [],
      schema: idMapValidation,
    },
  ],
  searchInputs: [
    {
      model: SideEffect,
      multiple: false,
      valueSetter: (
        value: { name: string },
        setterFunction: (key: string, value: string) => void
      ) => {
        setterFunction("findBy", "name");
        setterFunction("findString", value.name);
      },
    },
  ],
  schema: [
    {
      key: "targets",
      name: "Find Similar names",
      resultModels: [
        {
          key: "result",
          resultString: "Results",
          display: "list",
          listKeys: ["icon", "name"],
          dataMapping: (results: Data) => {
            return results.map((res: Data) => {
              return {
                ...res,
                icon: ["copy", "name"],
              };
            });
          },
        },
      ],
      api: {
        findBy: findByWithFilter(["Target"]),
        getOne: SideEffect.api.getOne,
      },
    },
  ],
};

const SideEffectListKeys: ListKeys = {
  properties: [
    {
      key: "name",
      display: "Name",
    },
  ],
  relations: [
    {
      name: "SideEffects",
      relatedModel: SideEffect,
      displayName: "Causes",
      properties: [
        {
          key: "Find Paths",
          property: "onClick",
          type: "function",
        },
      ],
    },
  ],
};
export const findModel = Find;
export const searchModel = Search;
export const trgSeModel = TrgSeModel;
export const sideEffectListKeys = SideEffectListKeys;
