import { Box, Card, CardActionArea, Typography } from "@mui/material";
import { OnClickFunction, ViewModel } from "helpers/types";
import BasicPopover from "./Popover";
import ModelDetails from "./ModelDetails";
import useModelDetailsHook from "hooks/useModelDetailsHook";
import { modelByLabelMap } from "models/basicModels";

export default function ModelCard({
  modelElement,
}: {
  modelElement: ViewModel;
}) {
  const [modelDetails, getDetails] = useModelDetailsHook(modelElement);
  const model = modelByLabelMap[modelElement.label];
  return (
    <Box>
      <Card
        sx={{
          ":hover": {
            boxShadow: 20,
            cursor: "pointer",
          },
        }}
      >
        <BasicPopover
          onClick={getDetails}
          button={(onClick: OnClickFunction) => {
            return (
              <CardActionArea
                onClick={(event) => {
                  event.preventDefault();
                  onClick(event);
                }}
                sx={{ p: 2, border: `solid ${model.color} 2px` }}
              >
                <Typography
                  component="h1"
                  variant="body1"
                  sx={{
                    width: "75px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {`${modelElement.name}`}
                </Typography>
                <Typography
                  component="h1"
                  variant="subtitle2"
                  sx={{
                    width: "75px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {modelByLabelMap[modelElement.label].displayName}
                </Typography>
              </CardActionArea>
            );
          }}
        >
          <ModelDetails
            data={typeof modelDetails === "object" ? modelDetails.data : {}}
            isLoading={modelDetails.isLoading}
          />
        </BasicPopover>
      </Card>
    </Box>
  );
}
