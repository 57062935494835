import { typeCheck as importedTypeCheck } from "type-check";
const options = {
  customTypes: {
    ObjectKeyString: {
      typeOf: "Object",
      validate: function (obj: any) {
        return Object.keys(obj).map(
          (key) => typeof key === "string" && typeof obj[key] === "string",
        );
      },
    },
  },
};

export const typeCheck = (type: string, value: any) =>
  importedTypeCheck(type, value, options);
