import { idMapValidation } from "../schema/commonSchema";
import { SideEffect, SideEffectGroup } from "./basicModels";

SideEffectGroup.relations = [
  /* -------------------------------------------------------------------------- */
  /*                             Outgoing Relations                             */
  /* -------------------------------------------------------------------------- */
  {
    relatedModel: SideEffect,
    name: "HasPT",
    displayName: "Has PT",
    properties: [],
    schema: idMapValidation,
  },
  /* -------------------------------------------------------------------------- */
  /*                             Incoming Relations                             */
  /* -------------------------------------------------------------------------- */
  {
    relatedModel: SideEffect,
    name: "IsPrimarySOCOf",
    displayName: "Primary SOC of",
    properties: [],
    schema: idMapValidation,
  },
  /* -------------------------------------------------------------------------- */
  /*                               Self Relations                               */
  /* -------------------------------------------------------------------------- */
  {
    relatedModel: SideEffectGroup,
    name: "HasHLT",
    displayName: "Has HLT",
    properties: [],
    schema: idMapValidation,
  },
  {
    relatedModel: SideEffectGroup,
    name: "IsHLT",
    displayName: "Is HLT",
    properties: [],
    schema: idMapValidation,
  },
  {
    relatedModel: SideEffectGroup,
    name: "HasHLGT",
    displayName: "Has HLGT",
    properties: [],
    schema: idMapValidation,
  },
  {
    relatedModel: SideEffectGroup,
    name: "IsHLGT",
    displayName: "Is HLGT",
    properties: [],
    schema: idMapValidation,
  },
];
export const sideEffectGroupModel = SideEffectGroup;
