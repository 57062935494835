import { findModel } from "../models";
import searchRoutes from "./SearchRoutes";
import viewRoutes from "./ViewRoutes";
import searchByRoutes from "./SearchByRoutes";
import { Props } from "helpers/types";
import { userRoutes } from "./UserRoutes";
import StandardDashboardPage from "pages/StandardDashboardPage";
import FindByPage from "pages/FindByPage";
import TesterRequestPage from "pages/TesterRequestPage";
import CreatePatientPage from "pages/CreatePatientPage";
import Responsive from "components/Responsive";
import QuickSearchPage from "pages/QuickSearchPage";

function mockFunction(props: Props) {
  return <></>;
}
const UserPage = mockFunction;
export const standardRoutes = [
  {
    path: "",
    element: (
      <>
        <Responsive displayIn={["Mobile"]}>
          <QuickSearchPage />
        </Responsive>
        <Responsive displayIn={["Laptop"]}>
          <StandardDashboardPage />
        </Responsive>
      </>
    ),
  },
  {
    path: "dashboard",
    element: <StandardDashboardPage />,
  },
  {
    path: "quicksearch",
    element: <QuickSearchPage />,
  },
  {
    path: "user",
    element: <UserPage />,
  },
  {
    path: "findby",
    element: <FindByPage model={findModel} key={"findby"} />,
  },
  {
    path: "user",
    children: userRoutes,
  },
  { path: "testerrequest", element: <TesterRequestPage /> },
  {
    path: "add",
    children: [
      {
        path: "patient",
        children: [
          { path: "", element: <CreatePatientPage /> },
          { path: ":id", element: <CreatePatientPage /> },
        ],
      },
    ],
  },
  ...searchRoutes,
  searchByRoutes,
  viewRoutes,
];
