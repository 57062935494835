import Title from "components/Title";
import { Container, Grid } from "@mui/material";
import { Data } from "helpers/types";
import useApiHook from "hooks/useApiHook";
import { getPatientAnalysis } from "api";
import { useEffect } from "react";
import SearchResult from "views/SearchResult";
import PatientAnalysis from "components/PatientAnalysis";
import ApiStatus from "components/ApiStatus";
import CustomLoader from "components/CustomeLoader";

export default function PrescriptionAnalysis({
  patientId,
}: {
  patientId: string;
}) {
  const [currentAnalysis, getCurrentAnalysis] = useApiHook<Data>(
    {},
    getPatientAnalysis,
  );
  useEffect(() => {
    getCurrentAnalysis(patientId);
  }, [patientId]);
  return (
    <Container>
      <ApiStatus
        error={currentAnalysis.error}
        isLoading={currentAnalysis.isLoading}
        customLoader={
          <CustomLoader
            loaderText={
              "Performing analysis on all prescriptions this might take some time"
            }
          ></CustomLoader>
        }
      />
      <Grid container>
        <Title>Patient Analysis</Title>
        <PatientAnalysis data={currentAnalysis.data} />
      </Grid>
    </Container>
  );
}
