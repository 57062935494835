import * as React from "react";
import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { myUserDetails } from "../api";
import { Link } from "react-router-dom";
import { PrefixContext } from "context";
import useApiHook from "hooks/useApiHook";
import ApiStatus from "components/ApiStatus";
import DynamicDisplay from "components/DynamicDisplay";

function UserProfilePage() {
  const { prefix } = React.useContext(PrefixContext);
  const [userDetails, getMyDetails] = useApiHook<{
    email: string;
    name: string;
    billing_session: any;
  }>({}, myUserDetails);
  React.useEffect(() => {
    getMyDetails({ billingPortal: true });
  }, []);
  const { name, email } = userDetails.data;
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <ApiStatus error={userDetails.error} isLoading={userDetails.isLoading} />
      <Paper sx={{ p: 2 }}>
        <Container
          disableGutters
          maxWidth="sm"
          component="main"
          sx={{ pt: 8, pb: 6 }}
        >
          <Typography
            component="h1"
            variant="h2"
            align="left"
            color="primary"
            gutterBottom
          >
            {"Profile"}
          </Typography>
          <Grid container>
            <Grid item xs={12} md={12} lg={12} sx={{ mb: 3 }}>
              <Avatar
                variant="square"
                sx={{ width: 100, height: 100, mb: 3 }}
              />
              <DynamicDisplay
                dataKey="user"
                value={{ user: { name, email } }}
              />
            </Grid>

            {prefix === "/guest" && !userDetails.data?.billing_session?.url && (
              <Box>
                <Grid item xs={12} md={12} lg={12} sx={{ mb: 3 }}>
                  <Button
                    component={Link}
                    to={`${prefix}/user/payment`}
                    variant="contained"
                  >
                    Upgrade to pro
                  </Button>
                </Grid>
                <Grid item xs={12} md={12} lg={12} sx={{ mb: 3 }}>
                  <Button
                    component={Link}
                    to={`${prefix}/testerrequest`}
                    variant="contained"
                  >
                    Get your free trial
                  </Button>
                </Grid>
              </Box>
            )}
            {userDetails.data?.billing_session?.url && (
              <Button
                variant="contained"
                onClick={() => {
                  window.open(userDetails.data.billing_session?.url, "_blank");
                }}
              >
                Open Billing Portal
              </Button>
            )}
          </Grid>
        </Container>
      </Paper>
    </Container>
  );
}

export default UserProfilePage;
