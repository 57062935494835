import { Data } from "../helpers/types";
import { getReq, postReq } from "./instance";

export const getIngredient = async (ingredientId: string, params = {}) => {
  return getReq(`/Ingredient/${ingredientId}`, params);
};

export const getIngredientRelations = async (
  ingredientId: string,
  params = {},
) => {
  return postReq(`/Ingredient/${ingredientId}`, params);
};

export const getIngredientSideEffects = async (properties: {}, params = {}) => {
  return postReq(`Ingredient/sideeffects`, properties, params);
};

export const getIngredientSideEffectPaths = async (
  sideEffectId: string,
  properties: Data,
  params = {},
) => {
  return postReq(`Ingredient/sideeffects/${sideEffectId}`, properties, params);
};
