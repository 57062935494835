import { AxiosResponse } from "axios";
import { useState } from "react";
import { ErrorResponse } from "../api/instance";
import { useNavigate } from "react-router-dom";
import { setGlobal } from "helpers/globals";
import { myUserDetails } from "api";

export default function useApiHook<T>(
  initialData: any,
  apiFunction?: Function,
  dataMapping?: Function,
): [
  { data: T; isLoading: boolean; success: boolean; error: any },
  Function,
  Function,
] {
  type ErrorState = {
    status: number | null;
    message: string[] | string | null;
  };
  const noErrorState: ErrorState = {
    status: null,
    message: null,
  };
  const [data, setData] = useState<T>(initialData);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(noErrorState);
  const navigate = useNavigate();
  function callApi(...args: any[]) {
    if (!apiFunction) return;
    setError(noErrorState);
    setSuccess(false);
    setIsLoading(true);
    return apiFunction(...args).then((res: AxiosResponse | ErrorResponse) => {
      setIsLoading(false);
      if (res && "error" in res) {
        const { status, message } = res.error;
        if (status === 402) {
          myUserDetails().then((res: any) => {
            const user = res.data;
            setGlobal("user", JSON.stringify(user));
            navigate("/app");
          });
        }
        if (status === 401 && message === "No user") {
          setGlobal("accessToken", "");
          navigate("/signup");
        }
        setError(res?.error);
        return res?.error;
      } else {
        if (dataMapping) {
          setData(dataMapping(res?.data));
        } else {
          setData(res?.data);
        }
        setSuccess(true);
        return res?.data;
      }
    });
  }
  return [{ data, isLoading, success, error }, callApi, setData];
}
