import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import {
  appLoader,
  rootLoader,
  adminLoader,
  standardLoader,
  guestLoader,
} from "./loaders";

import { adminRoutes, rootRoutes, standardRoutes } from "./routes";

const router = createBrowserRouter([
  {
    path: "/app",
    element: <App prefix="/app" />,
    loader: appLoader,
  },
  {
    path: "/admin",
    element: <App withLayout prefix="/admin" limited={false} />,
    loader: adminLoader,
    children: adminRoutes,
  },
  {
    path: "/standard",
    element: <App withLayout prefix="/standard" limited={false} />,
    loader: standardLoader,
    children: standardRoutes,
  },
  {
    path: "/guest",
    element: <App withLayout prefix="/guest" limited={true} />,
    loader: guestLoader,
    children: standardRoutes,
  },
  {
    path: "/",
    element: <App prefix="/" limited={true} />,
    loader: rootLoader,
    children: rootRoutes,
  },
]);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
