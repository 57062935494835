import axios, { AxiosError, isAxiosError } from "axios";
import { sessionStorageGet } from "../helpers/sessionStorage";
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: { Authorization: `Bearer ${sessionStorageGet("accessToken")}` },
});

interface ResponseError {
  message: string[];
  data: any;
  status: number;
}

export interface ErrorResponse {
  error: {
    status: number;
    message: string[] | string;
  };
}
const handleError = (e: unknown): ErrorResponse | undefined => {
  if (isAxiosError(e)) {
    const error = e as AxiosError<ResponseError>;
    if (error.response?.data) {
      const {
        status,
        data: { message },
      } = error.response;
      return {
        error: {
          status,
          message,
        },
      };
    }
  } else {
    throw e;
  }
};
export const getReq = async (url: string, params = {}) => {
  try {
    const res = await instance.get(url, {
      headers: { Authorization: `Bearer ${sessionStorageGet("accessToken")}` },
      params,
    });
    return res?.data;
  } catch (e) {
    return handleError(e);
  }
};

export const postReq = async (url: string, options: {}, params = {}) => {
  try {
    const res = await instance.post(url, options, {
      headers: { Authorization: `Bearer ${sessionStorageGet("accessToken")}` },
      params,
    });
    return res?.data;
  } catch (e) {
    return handleError(e);
  }
};

export const putReq = async (url: string, options: {}, params = {}) => {
  try {
    const res = await instance.put(url, options, {
      headers: { Authorization: `Bearer ${sessionStorageGet("accessToken")}` },
      params,
    });
    return res?.data;
  } catch (e) {
    return handleError(e);
  }
};

export const deleteReq = async (url: string) => {
  try {
    const res = await instance.delete(url, {
      headers: { Authorization: `Bearer ${sessionStorageGet("accessToken")}` },
    });
    return res?.data;
  } catch (e) {
    return handleError(e);
  }
};

export default instance;
