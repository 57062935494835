import { idMapValidation } from "../schema/commonSchema";
import { Ingredient, Schedule } from "./basicModels";

Schedule.relations = [
  /* -------------------------------------------------------------------------- */
  /*                             Outgoing Relations                             */
  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                             Incoming Relations                             */
  /* -------------------------------------------------------------------------- */
  {
    relatedModel: Ingredient,
    name: "IsScheduleOf",
    displayName: "Is schedule of",
    properties: [],
    schema: idMapValidation,
  },
  /* -------------------------------------------------------------------------- */
  /*                               Self Relations                               */
  /* -------------------------------------------------------------------------- */
  {
    relatedModel: Schedule,
    name: "HasChild",
    displayName: "Children",
    properties: [],
    schema: idMapValidation,
  },
  {
    relatedModel: Schedule,
    name: "HasParent",
    displayName: "Parents",
    properties: [],
    schema: idMapValidation,
  },
];
export const scheduleModel = Schedule;
