import { idMapValidation } from "../schema/commonSchema";
import { SideEffect, Ingredient, Target } from "./basicModels";

Target.relations = [
  /* -------------------------------------------------------------------------- */
  /*                             Outgoing Relations                             */
  /* -------------------------------------------------------------------------- */
  {
    relatedModel: SideEffect,
    name: "IsSideEffect",
    displayName: "Is",
    properties: [],
    schema: idMapValidation,
  },
  /* -------------------------------------------------------------------------- */
  /*                             Incoming Relations                             */
  /* -------------------------------------------------------------------------- */
  {
    relatedModel: Ingredient,
    name: "MayBeTreatedBy",
    displayName: "May be treated by",
    properties: [],
    schema: idMapValidation,
  },
  {
    relatedModel: Ingredient,
    name: "MayBeDiagnosedBy",
    displayName: "May be diagnosed by",
    properties: [],
    schema: idMapValidation,
  },
  {
    relatedModel: Ingredient,
    name: "MayBePreventedBy",
    displayName: "May be prevented by",
    properties: [],
    schema: idMapValidation,
  },
  {
    relatedModel: Ingredient,
    name: "InducedBy",
    displayName: "Induced by",
    properties: [],
    schema: idMapValidation,
  },
  {
    relatedModel: Ingredient,
    name: "ContraindicatesIngredient",
    displayName: "Contraindicates",
    properties: [],
    schema: idMapValidation,
  },
  /* -------------------------------------------------------------------------- */
  /*                               Self Relations                               */
  /* -------------------------------------------------------------------------- */
  {
    relatedModel: Target,
    name: "HasChild",
    displayName: "Children",
    properties: [],
    schema: idMapValidation,
  },
  {
    relatedModel: Target,
    name: "HasParent",
    displayName: "Parents",
    properties: [],
    schema: idMapValidation,
  },
];
export const targetModel = Target;
