import { Box, ListItemButton, ListItemText } from "@mui/material";
import { OnClickFunction, ViewModel } from "helpers/types";
import BasicPopover from "./Popover";
import ModelDetails from "./ModelDetails";
import useModelDetailsHook from "hooks/useModelDetailsHook";
import { modelByLabelMap } from "models/basicModels";

export default function ModelSimple({
  modelElement,
}: {
  modelElement: ViewModel;
}) {
  const [modelDetails, getDetails] = useModelDetailsHook(modelElement);
  const includesId = !!modelDetails.data.id;
  return (
    <Box
      sx={
        includesId
          ? {
              ":hover": {
                cursor: "pointer",
              },
            }
          : {}
      }
    >
      <BasicPopover
        onClick={getDetails}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        button={(onClick: OnClickFunction) => {
          return includesId ? (
            <ListItemButton onClick={onClick} disabled={!modelDetails.data.id}>
              <ListItemText
                sx={{ mr: 1, ml: 1, width: "7vw" }}
                primaryTypographyProps={{
                  sx: {
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  },
                }}
                primary={`${modelElement.name}`}
                secondary={modelByLabelMap[modelElement.label].displayName}
              />
            </ListItemButton>
          ) : (
            <ListItemText
              sx={{ mr: 1, ml: 1, width: "20vw" }}
              primaryTypographyProps={{
                sx: {
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                },
              }}
              primary={`${modelElement.name}`}
              secondary={modelByLabelMap[modelElement.label].displayName}
            />
          );
        }}
      >
        <ModelDetails
          data={typeof modelDetails === "object" ? modelDetails.data : {}}
          isLoading={modelDetails.isLoading}
        />
      </BasicPopover>
    </Box>
  );
}
