import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Box, Checkbox } from "@mui/material";
import { Children } from "helpers/types";
import { ControllerFieldState, ControllerRenderProps } from "react-hook-form";

const styles = {
  error: {
    color: "red",
    fontSize: "small",
  },
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export default function CheckBox({
  field,
  fieldState,
  children,
}: {
  field: ControllerRenderProps<any, any>;
  fieldState: ControllerFieldState;
  children: Children;
}) {
  return (
    <Box>
      <Checkbox
        icon={icon}
        id={field.name}
        checkedIcon={checkedIcon}
        checked={!!field.value}
        style={{ marginRight: 8 }}
        onChange={(e, value) => {
          field.onChange(value);
        }}
      />
      {typeof children === "function" ? children() : children}
      <br />
      {fieldState.error && (
        <span style={styles.error}>{fieldState.error.message}</span>
      )}
    </Box>
  );
}
