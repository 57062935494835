import { Box, Button, Container, Grid } from "@mui/material";
import { useState } from "react";
import StepMapRenderer from "./StepMapRenderer";
import { quickSearchModel } from "models/quickSearch";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Data } from "helpers/types";
import InputByType from "inputs/InputByType";
import { searchModel } from "models";
import useApiHook from "hooks/useApiHook";
import SearchResult from "views/SearchResult";
import ProOverlay from "./ProOverlay";
import useConfirm from "hooks/useConfirm";
import ConfirmMessage from "./ConfirmMessage";

// Find treatment without allergen
// list prescriptions find if side effect is from them
// list prescriptions find if allergens exists
// quick find if medication has allergen

function QuickSearch() {
  const [getConfirmation, Confirmation] = useConfirm<Promise<number>>(
    (onChange) => (
      <ConfirmMessage
        message={"Are you sure you want to restart the search?"}
        buttonText="Restart"
        onConfirm={onChange}
      />
    ),
  );
  const confirmRestart = async (): Promise<number> => {
    return await getConfirmation(``);
  };
  const {
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting, errors, isSubmitted },
    watch,
    getValues,
    setValue,
    setError,
  } = useForm<Data>({
    defaultValues: [{ searchType: "" }],
  });
  const [searchType, setSearchType] = useState(null);
  const { name, searchInputs, hasBeers, resultModels, api } =
    searchModel.schema[searchType || ""] || {};
  const [searchResults, performSearch, setSearchResults] = useApiHook<{
    [key: string]: any;
  }>({}, api);
  const [showResults, setShowResults] = useState(false);

  const onSubmit: SubmitHandler<Data> = async (values: Data, e) => {
    if (e) e.preventDefault();
    const { searchtype, ...rest } = values;
    await performSearch(rest);
    setShowResults(true);
  };
  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      {searchType === null ? (
        <Controller
          control={control}
          name={quickSearchModel.key}
          render={({ field, fieldState }) => (
            <InputByType
              schema={quickSearchModel as any}
              field={field}
              fieldState={fieldState}
              autoFocus={false}
              customOnChange={(value) => setSearchType(value)}
            />
          )}
        />
      ) : (
        <Grid container justifyContent={"flex-end"}>
          <Grid item xs={3}>
            {" "}
            <Button
              onClick={async () => {
                const confirmation = await confirmRestart();
                if (confirmation) {
                  reset();
                  setSearchResults({});
                  setSearchType(null);
                  setShowResults(false);
                }
              }}
            >
              Restart
            </Button>
          </Grid>
        </Grid>
      )}
      {searchType !== null && !showResults && (
        <StepMapRenderer
          schema={(quickSearchModel as any).nextSteps[searchType]}
          control={control}
          namePrefix={""}
          getValues={getValues}
          setValue={setValue}
        />
      )}
      {/* {showResults && ( */}

      {/* )} */}
      {showResults &&
        resultModels &&
        resultModels.map((model, index) => (
          <Grid key={index} item xs={12} md={12} lg={12}>
            <SearchResult
              model={model}
              results={searchResults.data[model.key]}
            />
          </Grid>
        ))}
      <Confirmation />
    </Box>
  );
}
export default QuickSearch;
