import { getReq, postReq } from "./instance";

export const getBeersCriteria = async (
  beersCriteriaId: string,
  params = {},
) => {
  return getReq(`/BeersCriteria/${beersCriteriaId}`, params);
};

export const findBeersCriteriasForList = async (properties: {}) => {
  return postReq("/searches/beerscriteriaslist", properties);
};
