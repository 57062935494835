import {
  Group,
  IngredientGroup,
  Ingredient,
  Manufacturer,
  SideEffectGroup,
  SideEffect,
  Target,
} from "./basicModels";

const Discover = {
  label: "Discover",
  inputModels: [
    Group,
    IngredientGroup,
    Ingredient,
    Manufacturer,
    SideEffectGroup,
    SideEffect,
    Target,
  ],
};

export const discoverModel = Discover;
