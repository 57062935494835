import { Grid } from "@mui/material";
import { patientPagesModel } from "models";
import SearchResult from "views/SearchResult";

export default function PatientAnalysis({ data }: { data: any }) {
  const resultModels = patientPagesModel.analysisResultModel;
  return (
    <Grid container>
      {resultModels &&
        resultModels.map((model, index) => (
          <Grid key={index} item xs={12} md={12} lg={12}>
            <SearchResult model={model} results={data[model.key]} />
          </Grid>
        ))}
    </Grid>
  );
}
