import * as yup from "yup";
import { idMapValidation } from "../schema/commonSchema";
import { BeersCriteria, Ingredient, Group } from "./basicModels";

Group.relations = [
  /* -------------------------------------------------------------------------- */
  /*                             Outgoing Relations                             */
  /* -------------------------------------------------------------------------- */
  {
    relatedModel: BeersCriteria,
    name: "GroupInteractionOne",
    displayName: "In Group One",
    properties: [
      {
        key: "Notes",
        property: "notes",
        validation: yup.string(),
        type: "string",
        default: "",
      },
    ],
    schema: idMapValidation,
  },
  /* -------------------------------------------------------------------------- */
  /*                             Incoming Relations                             */
  /* -------------------------------------------------------------------------- */
  {
    relatedModel: Ingredient,
    name: "IsVAOf",
    displayName: "Is VA Of",
    properties: [],
    schema: idMapValidation,
  },
  {
    relatedModel: Ingredient,
    name: "IsAtcOf",
    displayName: "Is Atc Of",
    properties: [],
    schema: idMapValidation,
  },
  {
    relatedModel: BeersCriteria,
    name: "AffectsBeers",
    displayName: "Affects",
    properties: [],
    schema: idMapValidation,
  },
  {
    relatedModel: BeersCriteria,
    name: "GroupInteractionTwoIn",
    displayName: "Group Two",
    properties: [],
    schema: idMapValidation,
  },
  /* -------------------------------------------------------------------------- */
  /*                               Self Relations                               */
  /* -------------------------------------------------------------------------- */
  {
    relatedModel: Group,
    name: "HasChild",
    displayName: "Children",
    properties: [],
    schema: idMapValidation,
  },
  {
    relatedModel: Group,
    name: "HasParent",
    displayName: "Parents",
    properties: [],
    schema: idMapValidation,
  },
];
export const groupModel = Group;
