import React from "react";
import { Box, Grid, Link } from "@mui/material";
import { LinkedIn, Email } from "@mui/icons-material";
import logosmall from "./../images/Asset5@4x.png";

export default function LandingFooter() {
  return (
    <Grid container item xs={12} md={12} lg={12}>
      <Grid
        item
        sx={{ display: "flex", justifyContent: "center" }}
        xs={12}
        md={12}
        lg={12}
      >
        <Box
          component={"img"}
          src={logosmall}
          alt="Leaflit"
          sx={{
            mt: { xs: "10px", md: "50px" },
            mb: { xs: "5px", md: "20px" },
            width: { xs: "40%", md: "20%" },
            maxHeight: { xs: "inherit", md: "inherit" },
            pr: { pr: 15 },
          }}
        />
        {/* <img
          src={logosmall}
          alt="Leaflit"
          style={{
            marginTop: "50px",
            marginBottom: "20px",
            width: "20%",
            maxHeight: "inherit",
            paddingRight: 15,
          }}
        /> */}
      </Grid>
      <Grid item container xs={12} md={12} lg={12}>
        <Grid item xs={3} md={3} lg={3}>
          {" "}
        </Grid>
        <Grid
          item
          sx={{ display: "flex", justifyContent: "center" }}
          xs={6}
          md={6}
          lg={6}
        >
          <Link
            href="https://www.linkedin.com/company/leaflit-care-inc/"
            target="_blank"
          >
            <LinkedIn sx={{ m: 1 }} />
          </Link>
          <Link href="mailto:leaflitcare@outlook.com">
            <Email sx={{ m: 1 }} />
          </Link>
          {/* <Instagram sx={{ m: 1, opacity: 0.6 }} />
          <Facebook sx={{ m: 1, opacity: 0.6 }} /> */}
        </Grid>
        <Grid item xs={3} md={3} lg={3}>
          {" "}
        </Grid>
      </Grid>
    </Grid>
  );
}
