import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { loginValidationSchema } from "../schema/signupSchema";
import { logIn } from "../api";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import ApiStatus from "../components/ApiStatus";
import useApiHook from "../hooks/useApiHook";
import { Paper } from "@mui/material";
import Copyright from "components/Copyright";
import { setGlobal } from "../helpers/globals";
import LandingLayout from "components/LandingLayout";

type Inputs = { email: string; password: string };
export default function Login() {
  const [user, logInUser] = useApiHook(null, logIn);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
  } = useForm<Inputs>({ resolver: yupResolver(loginValidationSchema) });
  const onSubmit: SubmitHandler<Inputs> = async (values: Inputs) => {
    const { email, password } = values;
    const userLogin = await logInUser(email, password);
    const accessToken = userLogin?.tokenData?.token;
    const userData = userLogin?.findUser;
    if (accessToken) {
      setGlobal("accessToken", accessToken);
      setGlobal("user", JSON.stringify(userData));
      navigate("/app");
    }
  };
  return (
    <LandingLayout>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <ApiStatus error={user.error} />
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            component={Paper}
            elevation={4}
            square
            sx={{
              mt: { xs: 9, sm: 0 },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              maxWidth: "500px",
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 4,
              paddingBottom: 7,
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                autoComplete="email"
                autoFocus
                {...register("email")}
                error={touchedFields.email && Boolean(errors.email)}
                helperText={touchedFields.email && errors.email?.message}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                {...register("password")}
                error={touchedFields.password && Boolean(errors.password)}
                helperText={touchedFields.password && errors.password?.message}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                id="login-button"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                  <Link
                    variant="body2"
                    component={RouterLink}
                    to="/resetpassword"
                  >
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Link variant="body2" component={RouterLink} to="/signup">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Grid item>
            <Copyright sx={{ mt: 8, mb: 4 }} />
          </Grid>
        </Grid>
      </Grid>
    </LandingLayout>
  );
}
