import { Box, List, ListItem, ListItemText, Paper } from "@mui/material";
import RelationsView from "./RelationsView";
import Title from "components/Title";
import ModalSimple from "components/ModalSimple";
import Responsive from "components/Responsive";
import MobileRelationsView from "./MobileRelationsView";

export default function ViewWithRelationsList({
  results,
  listKeys,
  title,
  secondary,
}: {
  results: any[];
  listKeys: any;
  title: string;
  secondary?: (res: any) => { primary: string; secondary: string };
}) {
  return (
    <Paper
      elevation={1}
      sx={{
        height: { xs: undefined, sm: undefined, md: "500px" },
        overflow: "auto",
        p: 1,
        m: 1,
      }}
    >
      <Box sx={{ m: 3 }}>
        <Title>
          {title} ({results.length} Results)
        </Title>
      </Box>
      <List>
        {results.map((res, index) => (
          <ListItem divider key={`${res.name}-${res.label}-${index}`}>
            <ModalSimple
              primary={res.name}
              secondary={res.label}
              sx={{
                position: "absolute" as "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: { xs: "95vw", sm: "95vw", md: "70vw" },
                height: { xs: "80vh", sm: "80vh", md: undefined },
                bgcolor: "background.paper",
                boxShadow: 24,
                overflow: "auto",
                p: 4,
              }}
              textSx={{ mr: 1, ml: 1, width: "20vw" }}
              row={
                secondary && (
                  <ListItemText
                    sx={{ mr: 1, ml: 1, width: "20vw" }}
                    primaryTypographyProps={{
                      sx: {
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      },
                    }}
                    primary={secondary(res).primary}
                    secondary={secondary(res).secondary}
                  />
                )
              }
            >
              <Responsive displayIn={["Laptop"]}>
                <RelationsView key={index} result={res} listKeys={listKeys} />
              </Responsive>
              <Responsive displayIn={["Mobile"]}>
                <MobileRelationsView
                  key={index}
                  result={res}
                  listKeys={listKeys}
                />
              </Responsive>
            </ModalSimple>
          </ListItem>
        ))}
      </List>
    </Paper>
  );
}
