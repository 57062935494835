import { Container } from "@mui/material";
import ProOverlay from "components/ProOverlay";
import QuickSearch from "components/QuickSearch";

function QuickSearchContent() {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <ProOverlay sx={{ bgcolor: "rgba(0, 0, 0, 0)" }}>
        <QuickSearch />
      </ProOverlay>
    </Container>
  );
}

export default function QuickSearchPage() {
  return <QuickSearchContent />;
}
