import { useContext, useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useNavigate } from "react-router-dom";
import { Alert, Box, Button, Container, Grid, Paper } from "@mui/material";
import { mapDataAsCamelCase } from "helpers/dataMapping";
import useApiHook from "hooks/useApiHook";
import { patientModel } from "models";
import {
  getInitialFromSchema,
  getValidationFromSchema,
} from "schema/commonSchema";
import ApiStatus from "components/ApiStatus";
import Title from "components/Title";

import InputByType from "inputs/InputByType";
import { Data, ModelSchema } from "helpers/types";
import { PrefixContext } from "context";
type Patient = {
  name: string;
  age: number;
  sex: "male" | "female" | "decline";
  ethnicity: "black" | "white" | "asian" | "latino" | "southasian";
  weight: "skinny" | "healthy" | "obese";
};
export default function CreatePatientPage() {
  const { id: selectedId } = useParams();
  const navigate = useNavigate();
  const {
    api: { getOne, createOne, editOne },
    schema,
    relations,
  } = patientModel;
  const emptyValues = getInitialFromSchema(schema);
  const [patient, getPatient] = useApiHook<Patient>(
    {
      ...emptyValues,
    },
    getOne,
  );
  const [newPatient, createPatient] = useApiHook<Patient>({}, createOne);
  const [editedPatient, editPatient] = useApiHook<Patient>({}, editOne);
  const { prefix } = useContext(PrefixContext);
  const { data: recievedDataDirty } = patient;
  const patientDetails = mapDataAsCamelCase(recievedDataDirty, emptyValues);
  const onSubmit: SubmitHandler<Data> = async (values: Data) => {
    if (selectedId) {
      await editPatient(selectedId, values);
      navigate(selectedId);
    } else {
      const newPatient = await createPatient(values);
      if (!newPatient.id) return;
      navigate(`${prefix}/view/patient/${newPatient.id}`);
    }
  };
  const {
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting, errors, isSubmitted },
  } = useForm<any>({
    resolver: yupResolver(getValidationFromSchema(schema)),
    defaultValues: patientDetails,
  });
  useEffect(() => {
    if (selectedId) {
      getPatient(selectedId).then((res: Patient) => {
        const { name, ethnicity, weight, sex, age } = res;
        reset({ name, ethnicity, weight, sex, age });
      });
    }
  }, [selectedId]);
  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 4, mb: 4 }}
      component={"form"}
      onSubmit={handleSubmit(onSubmit)}
    >
      <ApiStatus error={patient.error} isLoading={patient.isLoading} />
      <ApiStatus error={newPatient.error} isLoading={newPatient.isLoading} />
      <ApiStatus isLoading={isSubmitting} />
      <Paper elevation={1}>
        <Grid
          container
          sx={{
            p: 1,
            m: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            sx={{
              p: 1,
              m: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Title sx={{ mt: 1 }}>
              {selectedId ? `Edit ${patient.data.name}` : "Add new patient"}
            </Title>
            {selectedId && (
              <Button
                variant="contained"
                sx={{ ml: 2 }}
                onClick={() => navigate(`${prefix}/view/patient/${selectedId}`)}
              >
                View
              </Button>
            )}
          </Grid>
          {schema.map((sch: ModelSchema, index: number) => {
            const { key, type } = sch;
            return (
              <Grid
                key={index}
                item
                xs={12}
                md={type === "select" ? 3 : 6}
                lg={type === "select" ? 3 : 6}
                sx={{ p: 1 }}
              >
                <Controller
                  key={`${key}-${index}`}
                  control={control}
                  name={key}
                  render={({ field, fieldState }) => {
                    return (
                      <Box>
                        <InputByType
                          key={sch.key}
                          schema={sch}
                          field={field}
                          fieldState={fieldState}
                          autoFocus={index === 0}
                        />
                        {isSubmitted && Boolean(errors[field.name]) && (
                          <Box sx={{ width: "100%", mt: 1 }}>
                            <Alert severity="error" icon={false}>
                              {errors[field.name]?.message?.toString()}
                            </Alert>
                          </Box>
                        )}
                      </Box>
                    );
                  }}
                />
              </Grid>
            );
          })}

          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            display={"flex"}
            alignItems="center"
            justifyContent="center"
          >
            <Button
              variant="contained"
              type="submit"
              sx={{ textAlign: "center", width: "100%" }}
            >
              {!selectedId ? "Create" : "Edit"}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
