import { Box, Button, IconButton, SxProps, Theme } from "@mui/material";
import { Children } from "helpers/types";
import { useState } from "react";
import { ArrowForwardIos } from "@mui/icons-material";
export default function FloatingDrawer({
  buttonText,
  children,
  sx,
}: {
  buttonText: string;
  children: Children;
  sx?: SxProps<Theme>;
}) {
  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  return (
    <Box>
      <Box
        sx={{
          position: "absolute",
          right: 20,
          top: 100,
          zIndex: 1000,
          ...sx,
        }}
      >
        <Button
          onClick={toggleDrawer}
          variant="contained"
          size="small"
          sx={{
            m: 1,
            ...(open && { display: "none" }),
          }}
        >
          {buttonText}
        </Button>
      </Box>
      {open && (
        <Box
          sx={{
            position: "absolute",
            right: 20,
            top: 100,
            zIndex: 1000,
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              position: "absolute",
              right: -20,
              top: 15,
              display: open ? undefined : "none",
            }}
          >
            <ArrowForwardIos />
          </IconButton>
          {typeof children === "function" ? children() : children}
        </Box>
      )}
    </Box>
  );
}
