import { Box, Grid, Alert, Button, Typography } from "@mui/material";
import InputByType from "inputs/InputByType";
import ModelSelectInput from "inputs/ModelSelectInput";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import QuickModelSelect from "./QuickModelSelect";
import QuickInputSwitch from "./QuickInputSwitch";
import { searchSchemaValidation } from "schema/commonSchema";
import _ from "lodash";
import { validateInputs } from "helpers/quickViewValidations";

export default function StepMapRenderer({
  schema,
  control,
  namePrefix,
  getValues,
  setValue,
}: {
  schema: any;
  getValues: any;
  setValue: any;
  control: any;
  namePrefix: string;
}) {
  const [step, setStep] = useState(0);
  const [error, setError] = useState<null | string>(null);
  return (
    <>
      {schema.map((sch: any, index: any) => {
        const { type, onNext } = sch;
        return (
          <Box
            key={`${sch.key}-${index}`}
            sx={{ display: step === index ? "block" : "none" }}
          >
            <Typography component="h1" variant="body1" textAlign={"center"}>
              {sch.title || ""}
            </Typography>
            <QuickInputSwitch
              schema={sch}
              control={control}
              namePrefix={namePrefix}
              getValues={getValues}
              onChange={(val: any) => {
                setStep(step + 1);
              }}
            />
            {Boolean(error) && (
              <Box sx={{ width: "100%", mt: 1 }}>
                <Alert severity="error" icon={false}>
                  {error}
                </Alert>
              </Box>
            )}
            <Grid
              container
              sx={{
                m: 2,
              }}
              justifyContent="flex-end"
            >
              {sch.skippable && (
                <Grid item xs={3} sm={3} justifySelf={"flex-start"}>
                  <Button onClick={() => setStep(step + 1)}>Skip</Button>
                </Grid>
              )}
              {step !== 0 && (
                <Grid item xs={3} sm={3}>
                  <Button
                    onClick={() => {
                      setError(null);
                      setStep(step - 1);
                    }}
                  >
                    Back
                  </Button>
                </Grid>
              )}

              {sch.lastStep ? (
                <Grid
                  item
                  xs={3}
                  sm={3}
                  justifySelf={"flex-end"}
                  sx={{ mr: 2 }}
                >
                  <Button
                    key={`${sch.key}-${index}`}
                    variant="contained"
                    type="submit"
                    // disabled={}
                    onClick={(e) => {
                      if (
                        !validateInputs(
                          sch.inputs,
                          getValues,
                          sch.validationType,
                          sch.errorMessage,
                          setError,
                          () => {}
                        )
                      )
                        e.preventDefault();
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              ) : (
                <Grid
                  item
                  xs={3}
                  sm={3}
                  justifySelf={"flex-end"}
                  sx={{ mr: 0 }}
                >
                  <Button
                    sx={{
                      display: type === "multiplechoice" ? "none" : "block",
                    }}
                    onClick={async () => {
                      validateInputs(
                        sch.filter
                          ? sch.inputs.filter((input: any) =>
                              sch.filter(input, getValues)
                            )
                          : sch.inputs,
                        getValues,
                        sch.validationType,
                        sch.errorMessage,
                        setError,
                        () => {
                          if (onNext) onNext(getValues, setValue);
                          setStep(step + 1);
                        }
                      );
                    }}
                    variant="contained"
                  >
                    Next
                  </Button>
                </Grid>
              )}
            </Grid>
          </Box>
        );
      })}
    </>
  );
}
