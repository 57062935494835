import { Box, useTheme } from "@mui/material";
import Container, { DefaultDraw } from "components/Archer";
import ModelCard from "components/ModelCard";
import RelationshipList from "components/RelationshipList";
import RelationshipBox from "components/RelationshipsBox";
import { ListKeys } from "helpers/types";
import _ from "lodash";

export default function RelationsView({
  result,
  listKeys,
}: {
  result: any;
  listKeys: ListKeys;
}) {
  const { relations } = listKeys;
  const theme = useTheme();
  const elements = relations
    .map((listKey) => {
      if (!result[listKey.name]) return null;
      return {
        id: listKey.name,
        arrow: (
          <Box sx={{ mt: "-20px", textAlign: "center" }}>
            {listKey.displayName}
          </Box>
        ),
        children: (
          <RelationshipBox listKey={listKey}>
            <RelationshipList
              relationships={result[listKey.name]}
              listKey={listKey}
            />
          </RelationshipBox>
        ),
        relation: result.id,
      };
    })
    .flatMap((fl) => (fl === null ? [] : fl));
  return (
    <Box style={{ margin: "50px" }}>
      <Container strokeColor={theme.palette.primary.main}>
        <DefaultDraw
          root={[
            {
              id: result.id,
              children: <ModelCard modelElement={result} />,
            },
          ]}
          elements={elements}
        />
      </Container>
    </Box>
  );
}
