import { idMapValidation } from "../schema/commonSchema";
import { NDC, Label, DrugPack, Ingredient, DoseForm } from "./basicModels";

DoseForm.relations = [
  /* -------------------------------------------------------------------------- */
  /*                             Outgoing Relations                             */
  /* -------------------------------------------------------------------------- */
  {
    relatedModel: NDC,
    name: "HasNDC",
    displayName: "Has",
    properties: [],
    schema: idMapValidation,
  },
  /* -------------------------------------------------------------------------- */
  /*                             Incoming Relations                             */
  /* -------------------------------------------------------------------------- */
  {
    relatedModel: Label,
    name: "PartOfLabel",
    displayName: "Part Of",
    properties: [],
    schema: idMapValidation,
  },
  {
    relatedModel: DrugPack,
    name: "HasDrugPack",
    displayName: "Has",
    properties: [],
    schema: idMapValidation,
  },
  {
    relatedModel: Ingredient,
    name: "IncludesIngredient",
    displayName: "Includes",
    properties: [],
    schema: idMapValidation,
  },
  /* -------------------------------------------------------------------------- */
  /*                               Self Relations                               */
  /* -------------------------------------------------------------------------- */
];
export const doseFormModel = DoseForm;
