import { Box, Typography, Grid, Button } from "@mui/material";
import ModelSelectInput from "inputs/ModelSelectInput";

export default function QuickModelSelect({
  schema,
  field,
  fieldState,
}: {
  schema: any;
  field: any;
  fieldState: any;
}) {
  return (
    <Box>
      <ModelSelectInput
        model={schema.model}
        multiple={schema.multiple}
        field={field}
        filter={schema.filter}
        searchBy={schema.searchBy}
        where={schema.where}
        type={schema.type}
      />
    </Box>
  );
}
