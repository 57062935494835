import { useContext } from "react";
import { Link } from "react-router-dom";
import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { PrefixContext } from "context";
import { modelByLabelMap } from "models/basicModels";

export default function ListOfLinks({
  results,
  resultString,
}: {
  results: { link: string; name: string; label: string }[];
  resultString: string;
}) {
  const { prefix } = useContext(PrefixContext);
  return (
    <List
      dense
      disablePadding
      subheader={`${results.length} ${resultString}`}
      sx={{ overflowY: "auto", maxHeight: "40vw" }}
    >
      {results &&
        results.map((result, index) => {
          return (
            <ListItem
              key={index}
              alignItems="flex-start"
              divider
              dense
              disablePadding
              disableGutters
            >
              <ListItemButton
                component={Link}
                to={`${prefix}${result["link"]}`}
              >
                <ListItemText
                  primary={`${result["name"]} `}
                  secondary={`${modelByLabelMap[result["label"]].displayName}`}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
    </List>
  );
}
