import { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Card,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import { searchSchemaValidation } from "../schema/commonSchema";
import ApiStatus from "../components/ApiStatus";
import useApiHook from "../hooks/useApiHook";
import ModelSelectInput from "inputs/ModelSelectInput";
import SearchResult from "views/SearchResult";
import { Data, SearchSchema } from "helpers/types";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Title from "components/Title";
import MyModal from "components/Modal";
import { QueryDisclaimer } from "components/Disclaimer";
import FloatingDrawer from "components/FloatingDrawer";
import { modelByLabelMap } from "models/basicModels";
import CheckBox from "components/CheckBox";

type Inputs = Data;

export default function SearchPage({
  schema,
  schemas,
}: {
  schema: SearchSchema;
  schemas?: SearchSchema[];
}) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const usedSchema = (schemas && schemas[selectedIndex]) || schema;
  const { name, searchInputs, hasBeers, resultModels, api } = usedSchema;
  const [searchResults, performSearch, setSearchResults] = useApiHook<{
    [key: string]: any;
  }>({}, api);
  const [modalOpen, setOpenModal] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const {
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting, errors, isSubmitted },
  } = useForm<Inputs>({
    resolver: yupResolver(searchSchemaValidation(searchInputs)),
    defaultValues: {},
  });
  const onSubmit: SubmitHandler<Inputs> = async (values: Inputs, e) => {
    if (e) e.preventDefault();
    await performSearch(values);
    setShowResults(true);
  };
  useEffect(() => {
    reset();
    setSearchResults({});
  }, [schema]);
  return (
    <Container
      maxWidth="lg"
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{ mt: 4, mb: 4, minWidth: "250px", width: "70vw" }}
    >
      <ApiStatus error={searchResults.error} isLoading={isSubmitting} />
      <ApiStatus isLoading={searchResults.isLoading} />
      <FloatingDrawer buttonText="Color Guide">
        <Card sx={{ p: 2 }}>
          {Object.keys(modelByLabelMap).map((key, index) => (
            <Box
              key={`${key}-${index}`}
              sx={{ color: modelByLabelMap[key].color }}
            >
              {modelByLabelMap[key].displayName}
            </Box>
          ))}
        </Card>
      </FloatingDrawer>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12} width={"100%"}>
          <Paper
            elevation={0}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {schemas && schemas.length > 1 && (
              <FormControl>
                <InputLabel>Change search</InputLabel>
                <Select
                  value={selectedIndex}
                  label="Change search"
                  placeholder="Change search"
                  onChange={(e, value) =>
                    setSelectedIndex(e.target.value as number)
                  }
                >
                  {schemas.map((schema, index) => (
                    <MenuItem key={`${schema.name}-${index}`} value={index}>
                      {/* {" "} */}
                      {schema.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <Title>
              {name}
              <Button
                color="warning"
                variant="text"
                onClick={() => setOpenModal(true)}
              >
                Disclaimer
              </Button>
            </Title>
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {searchInputs.map((input, index) => {
                if (Array.isArray(input)) {
                  return (
                    <Grid key={index} item xs={12} md={6} lg={6} sx={{ p: 1 }}>
                      {input.map((inp, index2) => {
                        const {
                          model,
                          multiple,
                          name,
                          filter,
                          searchBy,
                          where,
                          type,
                        } = inp;
                        const { label: modelLabel } = model;
                        const label = name || modelLabel;
                        return (
                          <Box key={index + index2}>
                            <Controller
                              control={control}
                              name={label}
                              render={({ field, fieldState }) => (
                                <ModelSelectInput
                                  key={`${index}-search-model`}
                                  model={model}
                                  multiple={multiple}
                                  field={field}
                                  filter={filter}
                                  searchBy={searchBy}
                                  where={where}
                                  type={type}
                                />
                              )}
                            />
                            {isSubmitted && Boolean(errors[label]) && (
                              <Box sx={{ width: "100%", mt: 1 }}>
                                <Alert severity="error" icon={false}>
                                  {errors[label]?.message?.toString()}
                                </Alert>
                              </Box>
                            )}
                          </Box>
                        );
                      })}
                    </Grid>
                  );
                } else {
                  const {
                    model,
                    multiple,
                    name,
                    filter,
                    searchBy,
                    where,
                    type,
                  } = input;
                  const { label: modelLabel } = model;
                  const label = name || modelLabel;
                  return (
                    <Grid key={index} item xs={12} md={6} lg={6} sx={{ p: 1 }}>
                      <Controller
                        control={control}
                        name={label}
                        render={({ field, fieldState }) => (
                          <ModelSelectInput
                            key={`${index}-search-model`}
                            model={model}
                            multiple={multiple}
                            field={field}
                            filter={filter}
                            searchBy={searchBy}
                            where={where}
                            type={type}
                          />
                        )}
                      />
                      {isSubmitted && Boolean(errors[label]) && (
                        <Box sx={{ width: "100%", mt: 1 }}>
                          <Alert severity="error" icon={false}>
                            {errors[label]?.message?.toString()}
                          </Alert>
                        </Box>
                      )}
                    </Grid>
                  );
                }
              })}
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button type="submit" variant="contained" sx={{ width: "50%" }}>
                  Search
                </Button>
              </Grid>

              {hasBeers && (
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Controller
                    control={control}
                    name="hasBeers"
                    render={({ field, fieldState }) => (
                      <CheckBox field={field} fieldState={fieldState}>
                        <Typography
                          component={"span"}
                          sx={{ margin: "auto 0" }}
                          fontSize={"small"}
                        >
                          {`Patient is above 65 years of age`}{" "}
                        </Typography>
                      </CheckBox>
                    )}
                  />
                </Grid>
              )}
              {showResults &&
                resultModels &&
                resultModels.map((model, index) => (
                  <Grid key={index} item xs={12} md={12} lg={12}>
                    <SearchResult
                      model={model}
                      results={searchResults.data[model.key]}
                    />
                  </Grid>
                ))}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <MyModal
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 800,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          overflow: "auto",
        }}
        open={modalOpen}
      >
        <QueryDisclaimer />
        <Button
          variant="contained"
          fullWidth
          onClick={() => setOpenModal(false)}
        >
          Ok
        </Button>
      </MyModal>
    </Container>
  );
}
