import React, { useEffect, useState } from "react";
import { visuallyHidden } from "@mui/utils";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "./Title";
import {
  Box,
  Grid,
  TableFooter,
  TablePagination,
  TableSortLabel,
  Toolbar,
  Typography,
} from "@mui/material";
import { TextField } from "@mui/material";
import _, { capitalize } from "lodash";
import {
  EnhancedTableProps,
  Order,
  getComparator,
  stableSort,
} from "helpers/tableHelpers";

// TODO not convinced by this
type TableDataRow = {
  [key: string]: any;
};
function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    order,
    orderBy,
    onRequestSort,
    tableKeys,
    isSearchable,
    searchOn,
    requestSearch,
  } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  return (
    <TableHead>
      <TableRow>
        {tableKeys.map((headCell, index) => (
          <TableCell
            key={`head-cell-${headCell.id}-${index}`}
            align={headCell.numeric ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id && headCell.sortable}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              disabled={!headCell.sortable}
            >
              <Grid container>
                <Grid item xs={12} md={12} lg={12}>
                  {headCell.label}
                </Grid>
              </Grid>
            </TableSortLabel>
            {searchOn && (
              <Grid item xs={12} md={12} lg={12}>
                {isSearchable(headCell.id) ? (
                  <TextField
                    id={`table-title-${headCell.id}`}
                    size="small"
                    label={`Search ${headCell.label}`}
                    variant="standard"
                    onChange={(e) => {
                      e.preventDefault();
                      requestSearch(e.target.value, headCell.id);
                    }}
                  />
                ) : (
                  <Box sx={{ height: "1.4375em", mt: "16px" }}></Box>
                )}
              </Grid>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function TableComponent({
  tableName,
  showTitle,
  data,
  tableKeys,
  searchOn = true,
  fillEmpty = true,
  defaultRowsPerPage,
  defaultSortKey,
}: {
  tableName?: string;
  showTitle?: boolean;
  data: TableDataRow[];
  tableKeys: {
    key: string;
    display: string;
    sortable?: boolean;
    color?: string;
    numeric?: boolean;
  }[];
  searchOn?: boolean;
  fillEmpty?: boolean;
  defaultRowsPerPage?: number;
  defaultSortKey?: string;
}) {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState(
    defaultSortKey || tableKeys[0].key
  );
  const [rows, setRows] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage || 5);
  const [emptyRows, setEmptyRows] = useState(0);
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangePage = (
    event: any,
    newPage: React.SetStateAction<number>
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: { target: { value: string } }) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    setRows(data);
    setEmptyRows(Math.max(0, (1 + page) * rowsPerPage - data.length));
  }, [data, page, rowsPerPage]);

  const requestSearch = (searchedVal: string, key: string) => {
    const filteredRows = data.filter((row: TableDataRow) => {
      const searchString =
        typeof row[key] === "object" ? row[key].searchString : row[key];
      return (
        String(searchString) &&
        String(searchString).toLowerCase().includes(searchedVal.toLowerCase())
      );
    });
    setRows(filteredRows);
    setPage(0);
    setEmptyRows(Math.max(0, (1 + page) * rowsPerPage - filteredRows.length));
  };

  const checkSearchable = (key: string) => {
    if (!data[0]) return false;
    if (typeof data[0][key] === "string") return true;
    if (typeof data[0][key] === "number") return true;
    if (typeof data[0][key] === "boolean") return true;
    if (typeof data[0][key] === "object" && "searchString" in data[0][key])
      return true;
    return false;
  };
  return (
    <React.Fragment>
      {showTitle && <Title>{tableName}</Title>}
      <Box>
        <Box sx={{ width: "100%", display: "table" }}>
          <Table size="small" sx={{ width: "100%" }}>
            <EnhancedTableHead
              tableKeys={tableKeys.map((tk, index) => ({
                id: tk.key,
                numeric: !!tk.numeric,
                disablePadding: true,
                label: tk.display,
                ...tk,
              }))}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              isSearchable={checkSearchable}
              searchOn={searchOn}
              requestSearch={requestSearch}
            />
            <TableBody sx={{ overflow: "auto" }}>
              {}
              {(rowsPerPage > 0
                ? stableSort<any>(rows, getComparator(order, orderBy)).slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : rows
              ).map((row, index) => {
                return (
                  <TableRow key={`table-main-${row.id}-${index}`}>
                    {tableKeys.map(({ key, display, color }, index: number) => {
                      if (row[key] === undefined)
                        console.warn(row, key, index, "is undefined");
                      return (
                        <TableCell key={`table-row-${row.id}-${index}`}>
                          {row[key] !== undefined ? (
                            typeof row[key] === "object" ? (
                              row[key].value
                            ) : (
                              <Typography variant="body2" color={color}>
                                {capitalize(row[key].toString())}
                              </Typography>
                            )
                          ) : (
                            ""
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
              {emptyRows > 0 && fillEmpty && (
                <TableRow
                  style={{
                    height: 33 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {(data.length > rowsPerPage || fillEmpty) && (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[5, 10, 25]}
                    labelRowsPerPage={<span>Rows:</span>}
                    backIconButtonProps={{
                      color: "secondary",
                    }}
                    nextIconButtonProps={{ color: "secondary" }}
                    showFirstButton={true}
                    showLastButton={true}
                  />
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </Box>
      </Box>
    </React.Fragment>
  );
}
