import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { signupValidationSchema } from "../schema/signupSchema";
import { logIn, signUp } from "../api";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import ApiStatus from "../components/ApiStatus";
import useApiHook from "../hooks/useApiHook";
import { Paper } from "@mui/material";
import Copyright from "components/Copyright";
import { setGlobal } from "../helpers/globals";
import LandingLayout from "components/LandingLayout";
import useModalHook from "hooks/useModalHook";
import MyModal from "components/Modal";
import Disclaimer from "components/Disclaimer";
import Title from "components/Title";
import TermsAndConditions from "components/TermsAndConditions";
import CheckBox from "components/CheckBox";

const modalSx = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // height: { xs: "110vw", sm: "50vw", md: "30vw" },
  width: { xs: "95vw", sm: "95vw", md: 600 },
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

type Inputs = {
  email: string;
  password: string;
  confirmPassword: string;
  name: string;
  termsOfService: boolean;
};
export default function SignUp() {
  const [user, setUser] = useApiHook(null, signUp);
  const [userLogin, logInUser] = useApiHook(null, logIn);
  const [openTerms, handleOpenTerms, closeTerms] = useModalHook();
  const [openDisclaimer, handleOpenDisclaimer, closeDisclaimer] =
    useModalHook();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, touchedFields },
  } = useForm<Inputs>({ resolver: yupResolver(signupValidationSchema) });
  const onSubmit: SubmitHandler<Inputs> = async (values: Inputs) => {
    const newUser = await setUser({ ...values, confirmPassword: undefined });
    if (!newUser.error) {
      const { email, password } = values;
      const userLogin = await logInUser(email, password);
      const accessToken = userLogin?.tokenData?.token;
      const userData = userLogin?.findUser;
      if (accessToken) {
        setGlobal("accessToken", accessToken);
        setGlobal("user", JSON.stringify(userData));
        navigate("/app");
      }
    }
  };
  return (
    <LandingLayout>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <ApiStatus
          error={user.error}
          success={user.success}
          successMessage={"New User Created"}
        />
        <ApiStatus error={userLogin.error} />
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            component={Paper}
            elevation={4}
            square
            sx={{
              mt: { xs: 9, sm: 0 },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              maxWidth: "500px",
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 4,
              paddingBottom: 7,
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign up
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
              sx={{ mt: 3 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    autoComplete="given-name"
                    required
                    fullWidth
                    id="name"
                    label="Name"
                    autoFocus
                    {...register("name")}
                    error={touchedFields.name && Boolean(errors.name)}
                    helperText={touchedFields.name && errors.name?.message}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    autoComplete="email"
                    {...register("email")}
                    error={touchedFields.email && Boolean(errors.email)}
                    helperText={touchedFields.email && errors.email?.message}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                    {...register("password")}
                    error={touchedFields.password && Boolean(errors.password)}
                    helperText={
                      touchedFields.password && errors.password?.message
                    }
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Confirm Password"
                    type="password"
                    id="confirmPassword"
                    autoComplete="current-password"
                    {...register("confirmPassword")}
                    error={
                      touchedFields.confirmPassword &&
                      Boolean(errors.confirmPassword)
                    }
                    helperText={
                      touchedFields.confirmPassword &&
                      errors.confirmPassword?.message
                    }
                  />
                  <Box
                    sx={{
                      display: "flex",
                      alignContent: "flex-start",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Controller
                      control={control}
                      name="termsOfService"
                      render={({ field, fieldState }) => (
                        <CheckBox field={field} fieldState={fieldState}>
                          <Typography
                            component={"span"}
                            sx={{ margin: "auto 0" }}
                            fontSize={"small"}
                          >
                            {`I am a healthcare professional and agree to the terms of`}{" "}
                            <Link
                              onClick={() => {
                                handleOpenTerms();
                              }}
                            >
                              LeaflitCare Agreement
                            </Link>{" "}
                            {`and the`}{" "}
                            <Link
                              onClick={() => {
                                handleOpenDisclaimer();
                              }}
                            >
                              Disclaimer
                            </Link>
                          </Typography>
                        </CheckBox>
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign Up
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link variant="body2" component={RouterLink} to="/login">
                    Already have an account? Sign in
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Grid item>
            <Copyright sx={{ mt: 8, mb: 4 }} />
          </Grid>
        </Grid>
      </Grid>
      <MyModal sx={modalSx} open={openTerms} onClose={closeTerms}>
        <Box>
          <Box
            sx={{
              overflow: "auto",
              height: { xs: "80vw", sm: "80vw", md: "30vw" },
            }}
          >
            <TermsAndConditions />
          </Box>
        </Box>
      </MyModal>
      <MyModal sx={modalSx} open={openDisclaimer} onClose={closeDisclaimer}>
        <Box>
          <Title>Disclaimer: Information for Healthcare Professionals</Title>
          <Box
            sx={{
              overflow: "auto",
              height: { xs: "80vw", sm: "80vw", md: "30vw" },
            }}
          >
            <Disclaimer />
          </Box>
        </Box>
      </MyModal>
    </LandingLayout>
  );
}
