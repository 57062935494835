import { Box, Button } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useState } from "react";

export default function GetDate({
  onConfirm,
}: {
  onConfirm: (val: number | boolean) => void;
}) {
  const [date, onChange] = useState(dayjs());
  return (
    <Box sx={{ m: 2, p: 2 }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          sx={{ width: "100%", mt: 3 }}
          value={date}
          onChange={(value: any) => {
            onChange(value);
          }}
          label={"Symptoms start date (MM/DD/YYYY)"}
          maxDate={dayjs()}
          defaultValue={dayjs()}
        />
        <Button onClick={() => onConfirm(dayjs(date?.format()).valueOf())}>
          OK
        </Button>
        <Button onClick={() => onConfirm(false)}>Cancel</Button>
      </LocalizationProvider>
    </Box>
  );
}
