import Popover from "@mui/material/Popover";
import { forwardRef } from "react";
import { Props } from "helpers/types";
import React from "react";
import { Box, LinearProgress } from "@mui/material";

function ControlledPopover(
  {
    children,
    button,
    onClick,
    anchorOrigin,
    transformOrigin,
    open,
    handleClose,
    isLoading,
  }: Props,
  ref: any,
) {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (onClick) onClick();
  };
  const id = open ? "simple-popover" : undefined;
  return (
    <div>
      {button(handleClick)}
      <Popover
        id={id}
        sx={{ zIndex: 100000 }}
        disableAutoFocus
        disableEnforceFocus
        open={open}
        anchorEl={ref?.current}
        onClose={handleClose}
        anchorOrigin={
          anchorOrigin
            ? anchorOrigin
            : {
                vertical: "bottom",
                horizontal: "right",
              }
        }
        transformOrigin={
          transformOrigin
            ? transformOrigin
            : {
                vertical: "top",
                horizontal: "right",
              }
        }
      >
        {isLoading ? (
          <Box
            sx={{
              minWidth: "250px",
            }}
          >
            <LinearProgress />
          </Box>
        ) : typeof children === "function" ? (
          children()
        ) : (
          children
        )}
      </Popover>
    </div>
  );
}

export default forwardRef(ControlledPopover);
