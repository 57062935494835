import * as yup from "yup";
import _ from "lodash";
import FindByPage from "./FindByPage";
import { findByPatients } from "../api";
import { Model, ViewModel } from "helpers/types";

export default function PatientFindPage({ model }: { model: Model }) {
  const { label, findBy: findByKeys } = model;
  const newModel = {
    label,
    searchInputs: [
      {
        key: "findBy",
        validation: yup.string(),
        type: "select",
        valueOptions: findByKeys,
        default: findByKeys[0],
      },
      {
        key: "findString",
        validation: yup.string().required("Field is required"),
        required: true,
        type: "string",
        default: "",
      },
    ],
    schema: [
      {
        key: "results",
        name: "Found",
        resultModels: [
          {
            key: "result",
            display: "list",
            resultString: "Results",
            listKeys: ["link"],
            dataMapping: (results: ViewModel[]) => {
              return results.map((res) => {
                const { id, label } = res;
                return {
                  ...res,
                  link: `/view/${label}/${id}`,
                };
              });
            },
          },
        ],
        api: (values: {
          findString: string;
          findBy: string;
          filter: string[];
        }) => {
          return findByPatients({ ...values, filter: [label] });
        },
      },
    ],
  };
  return <FindByPage model={newModel} />;
}
