import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
const paper = {
  minHeight: { md: "250px", lg: "250px" },
  // width: '100px',
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  verticalAlign: "middle",
};

const button = {
  color: "primary",
  display: "block",
  width: "90%",
};
export function LinkPaperCard({
  title,
  body,
  buttonText,
  to = "",
  icon,
}: {
  title: string;
  body: string;
  buttonText: string;
  to?: string;
  icon?: any;
}) {
  return (
    <Paper sx={paper} elevation={0}>
      <Tooltip title={body}>
        <IconButton
          sx={{ h: "120px", m: 1 }}
          component={RouterLink}
          to={to}
          disabled={!to}
        >
          {icon}
        </IconButton>
      </Tooltip>
      <Tooltip title={body}>
        <Button
          component={RouterLink}
          disabled={!to}
          to={to}
          variant={"contained"}
          sx={button}
        >
          {title}
        </Button>
      </Tooltip>
    </Paper>
  );
}
