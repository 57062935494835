import { sessionStorageGet, sessionStorageSet } from "./sessionStorage";
import { getAccessLevels as getAccessLevelsApi } from "../api";
type AccessLevelNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6;
type Globals = {
  [key: string]: any;
  user: {
    [key: string]: any;
    access_level: AccessLevelNumber;
  };
  token: string | null;
  accessLevels: any;
  userDetails: {
    prefix: string;
    isAdmin: boolean;
    accessLevel: AccessLevelNumber;
  };
};
enum AccessLevel {}
let globals: Globals = {
  user: {
    access_level: 6,
  },
  token: null,
  accessLevels: AccessLevel,
  userDetails: {
    prefix: "",
    isAdmin: false,
    accessLevel: 6,
  },
};

const accessLevelPrefix = {
  0: "/admin",
  1: "/admin",
  2: "/standard",
  3: "/guest",
  4: "/patient",
  5: "/dataentry",
  6: "/standard",
  7: "/standard",
  8: "/standard",
};

const getAccessLevels = async () => {
  const accessLevels = (await getAccessLevelsApi())?.data;
  return accessLevels || [];
};

export const setAccessLevels = async () => {
  setGlobal("accessLevels", (await getAccessLevels()) || []);
};

export const setGlobal = (key: string, value: string) => {
  sessionStorageSet(key, value);
  globals[key] = value;
};

export const getGlobal = (key: string) => {
  return globals[key];
};
export const userDetails = () => {
  const userAccessLevel = globals.user?.access_level;
  const { accessLevels } = globals;
  const accessMap = {
    [userAccessLevel]: accessLevels[userAccessLevel],
    prefix: accessLevelPrefix[userAccessLevel] || accessLevelPrefix[3],
    accessLevel: userAccessLevel,
    isAdmin: userAccessLevel <= accessLevels.Administrator,
  };
  return accessMap;
};

export const initGlobals = async () => {
  const token = sessionStorageGet("accessToken");
  const user = sessionStorageGet("user");
  globals.user = user ? JSON.parse(user) : {};
  globals.token = token;
  globals.accessLevels = await getAccessLevels();
  globals.userDetails = userDetails();
};

export const allGlobals = () => globals;

export const isAdmin = () => globals.userDetails.isAdmin;
