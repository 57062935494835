import { ViewModel, ViewRelationship } from "helpers/types";
import Container, { Element } from "./Archer";
import ModelCard from "./ModelCard";
import RelationCard from "./RelationCard";
import { rowStyle } from "helpers/archer";
import { Box, useTheme } from "@mui/material";
import { RelationType } from "react-archer/lib/types";
import _ from "lodash";
type Path = ViewModel[] | ViewRelationship[];
const wrapperStyle = {
  padding: "10px",
  margin: "20px",
};

export default function Path({ path }: { path: Path }) {
  const theme = useTheme();
  function makeRelations(
    start: number,
    end: number,
    lastIdentity: number,
  ): RelationType[] {
    const relations: RelationType[] = [];
    const directionOut = start === lastIdentity;
    if (start || end) {
      relations.push({
        targetId: start.toString(),
        targetAnchor: directionOut ? "right" : "left",
        sourceAnchor: directionOut ? "left" : "right",
        style: {
          strokeColor: theme.palette.secondary.main,
          strokeWidth: 1,
          strokeDasharray: "5",
          endMarker: false,
        },
      });
      relations.push({
        targetId: end.toString(),
        targetAnchor: directionOut ? "left" : "right",
        sourceAnchor: directionOut ? "right" : "left",
        style: {
          strokeColor: theme.palette.secondary.main,
          strokeWidth: 1,
          strokeDasharray: "5",
          endMarker: true,
        },
      });
    }
    return relations;
  }
  return (
    <Container strokeColor="black" style={{ width: `${path.length * 200}px` }}>
      <Box style={rowStyle}>
        {path.map((pt, index: number) => {
          const { identity: id, start, end } = pt;
          return (
            <Box sx={wrapperStyle} key={`${id}-${index}`}>
              <Element
                id={id}
                relations={makeRelations(
                  start,
                  end,
                  _.get(path, [index - 1, "identity"]),
                )}
              >
                {pt.isRel && pt.alias ? (
                  <RelationCard relationElement={pt} />
                ) : (
                  <ModelCard modelElement={pt} />
                )}
              </Element>
            </Box>
          );
        })}
      </Box>
    </Container>
  );
}
