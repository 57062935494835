import { Box, Paper } from "@mui/material";
import Title from "components/Title";
import _ from "lodash";
import { ViewModel } from "helpers/types";
import ModelSimple from "components/ModelSimple";
import Table from "components/Table";
type BeersCriteria = {
  bc: ViewModel;
  ing: ViewModel;
  ingOne: ViewModel;
  ingTwo: ViewModel;
};
export default function BeersCriteriaView({
  results,
  title,
}: {
  results: {
    single: BeersCriteria[];
    multiple: BeersCriteria[];
  };
  title: string;
}) {
  if (results.single.length === 0 && results.multiple.length === 0)
    return <></>;
  const availableMap: { [key: string]: string } = {};
  // TODO fix any in table data array
  const singleTable: any[] = results.single.map((res) => {
    const { bc, ing } = res;
    return {
      interactingDrug: {
        value: <ModelSimple modelElement={ing} />,
        searchString: ing.name,
      },
      riskRationale: bc.risk_rationale,
      recommendation: bc.recommendation,
      qualityOfEvidence: bc.quality_of_evidence,
      strengthOfRecommendation: bc.strength_of_recommendation,
    };
  });
  const multipleTable: any[] = results.multiple.map((res) => {
    const { bc, ingOne, ingTwo } = res;
    return {
      drugOne: {
        value: <ModelSimple modelElement={ingOne} />,
        searchString: ingOne.name,
      },
      drugTwo: {
        value: <ModelSimple modelElement={ingTwo} />,
        searchString: ingTwo.name,
      },
      riskRationale: bc.risk_rationale,
      recommendation: bc.recommendation,
      qualityOfEvidence: bc.quality_of_evidence,
      strengthOfRecommendation: bc.strength_of_recommendation,
    };
  });
  return (
    <Paper elevation={1} sx={{ overflow: "auto", p: 1, m: 1 }}>
      <Box sx={{ m: 3 }}>
        <Title>{"Beers Criteria"}</Title>
      </Box>
      <Table
        tableName={"Single"}
        tableKeys={[
          { key: "interactingDrug", display: "Drug" },
          { key: "riskRationale", display: "Risk Rationale" },
          { key: "recommendation", display: "Recommendation" },
          { key: "qualityOfEvidence", display: "Quality of Evidence" },
          {
            key: "strengthOfRecommendation",
            display: "Strength of Recommendation",
          },
        ]}
        searchOn={false}
        data={singleTable}
      />
      <Box sx={{ m: 3 }}>
        <Title>{"Beers Criteria Interactions"}</Title>
      </Box>
      <Table
        tableName={"Multiple"}
        tableKeys={[
          { key: "drugOne", display: "Drug" },
          { key: "drugTwo", display: "Interacting Drug" },
          { key: "riskRationale", display: "Risk Rationale" },
          { key: "recommendation", display: "Recommendation" },
          { key: "qualityOfEvidence", display: "Quality of Evidence" },
          {
            key: "strengthOfRecommendation",
            display: "Strength of Recommendation",
          },
        ]}
        searchOn={false}
        data={multipleTable}
      />
    </Paper>
  );
}
