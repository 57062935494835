import { useState } from "react";
import _ from "lodash";
import { BasicData } from "helpers/types";

type IdArray = string[];
type SetterFunction = (data: BasicData[]) => void;
type PushFunction = (data: BasicData) => void;
type RemoveFunction = (data: BasicData) => void;
type UniqueFunction = (
  unique: boolean,
  data: BasicData[],
) => [BasicData[], IdArray];
export default function useIdObjectHook(
  onChange: (idArray: IdArray) => void,
  unique: boolean,
  multiple: boolean,
  defaultValue?: BasicData[],
  onChangeData?: (objectData: BasicData[]) => void,
): [BasicData[], IdArray, PushFunction, RemoveFunction, SetterFunction] {
  const getDataByUnique: UniqueFunction = function (
    unique: boolean,
    data: BasicData[],
  ) {
    if (unique) {
      const uniqueData = _.uniqBy(data, function (e) {
        return e.id;
      });
      const uniqueIds = uniqueData.map((ud) => ud.id);
      return [uniqueData, uniqueIds];
    } else {
      return [data, data.map((dt) => dt.id)];
    }
  };
  const [dataArray, setDataArray] = useState<BasicData[]>(defaultValue || []);
  const [idArray, setIdArray] = useState<IdArray>([]);
  const setterFunction: SetterFunction = function (data) {
    const [objectData, idArray] = getDataByUnique(unique, data);
    setDataArray(objectData);
    setIdArray(idArray);
    onChange(idArray);
    if (onChangeData) onChangeData(objectData);
  };
  const pushFunction: PushFunction = function (data) {
    if (!multiple) {
      setterFunction([data]);
      return;
    }
    const newData = dataArray;
    newData.push(data);
    setterFunction(newData);
  };
  const removeFunction: RemoveFunction = function (data) {
    const newData = dataArray;
    _.remove(newData, (nd) => nd.id === data.id);
    setterFunction(newData);
  };
  return [dataArray, idArray, pushFunction, removeFunction, setterFunction];
}
