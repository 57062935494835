import { Props } from "helpers/types";
import * as React from "react";
// import { createBrowserHistory } from "history";
import { useLocation } from "react-router-dom";

// function logErrorToMyService(...args) {
//   console.log(args);
// }
// export default class ErrorBoundary extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { hasError: false };
//   }
//   static getDerivedStateFromError(error) {
//     // Update state so the next render will show the fallback UI.
//     return { hasError: true };
//   }
//   componentDidCatch(error, errorInfo) {
//     // You can also log the error to an error reporting service
//     logErrorToMyService(error, errorInfo);
//   }
//   render() {
//     if (this.state.hasError) {
//       // You can render any custom fallback UI
//       return <h1>Something went wrong.</h1>;
//     }
//     return this.props.children;
//   }
// }

// A context will be the way that we allow components lower down
// the tree to trigger the display of an error page
// const ErrorStatusContext = React.createContext();

// The top level component that will wrap our app's core features
const ErrorHandler = ({ children }: Props) => {
  let location = useLocation();
  // const [errorStatusCode, setErrorStatusCode] = React.useState();

  // Make sure to "remove" this status code whenever the user
  // navigates to a new URL. If we didn't do that, then the user
  // would be "trapped" into error pages forever
  //   React.useEffect(() => {
  //     // Listen for changes to the current location.
  //     let unlisten = history.listen(({ action, location }) => {
  //         console.log(action, location )
  //         // The current location changed.
  //       });
  //     // cleanup the listener on unmount
  //     return unlisten;
  //   }, []);
  React.useEffect(() => {
    // Google Analytics
    // console.log(location);
    // ga('send', 'pageview');
  }, [location]);
  // This is what the component will render. If it has an
  // errorStatusCode that matches an API error, it will only render
  // an error page. If there is no error status, then it will render
  // the children as normal
  const renderContent = () => {
    // console.log(errorStatusCode, "------");
    // if (errorStatusCode === 404) {
    //   return <div>hello nice guy</div>;
    // }

    // ... more HTTP codes handled here

    return typeof children === "function" ? children() : children;
  };

  // We wrap it in a useMemo for performance reasons. More here:
  // https://kentcdodds.com/blog/how-to-optimize-your-context-value/
  // const contextPayload = React.useMemo(
  //   () => ({ setErrorStatusCode }),
  //   [setErrorStatusCode]
  // );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return (
    // <ErrorStatusContext.Provider value={contextPayload}>
    <div>{renderContent()}</div>

    // </ErrorStatusContext.Provider>
  );
};
export default ErrorHandler;
// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
// const useErrorStatus = () => React.useContext(ErrorStatusContext);
