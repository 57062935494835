import { Data } from "../helpers/types";
import { getReq, postReq } from "./instance";

export const getDoseForm = async (doseFormId: string, params = {}) => {
  return getReq(`/DoseForm/${doseFormId}`, params);
};
export const getDoseFormSideEffects = async (properties: {}, params = {}) => {
  return postReq(`DoseForm/sideeffects`, properties, params);
};

export const getDoseFormSideEffectPaths = async (
  sideEffectId: string,
  properties: Data,
  params = {},
) => {
  return postReq(`DoseForm/sideeffects/${sideEffectId}`, properties, params);
};
