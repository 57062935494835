import dayjs from "dayjs";
import { TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { camelCaseToString } from "../helpers/common";
import _, { isObject } from "lodash";
import { ModelSchema } from "helpers/types";
import { ControllerFieldState, ControllerRenderProps } from "react-hook-form";
import Select from "./Select";
import MultipleChoiceInput from "./MultipleChoiceInput";

export default function InputByType({
  schema,
  field,
  fieldState,
  autoFocus,
  customOnChange,
}: {
  schema: ModelSchema;
  field: ControllerRenderProps;
  fieldState: ControllerFieldState;
  autoFocus: boolean;
  customOnChange?: (e: any) => void;
}) {
  const { name, onChange } = field;
  const { isTouched, error } = fieldState;
  const {
    type,
    valueOptions,
    required = false,
    multiple = false,
    mapped,
    title,
  } = schema;
  let inputField;
  switch (type) {
    case "string":
      inputField = (
        <TextField
          margin="normal"
          required={!!required}
          fullWidth
          autoFocus={autoFocus}
          id={name}
          label={camelCaseToString(name)}
          autoComplete={name}
          {...field}
          onChange={(e) => {
            onChange(e);
            if (customOnChange) customOnChange(e);
          }}
          error={isTouched && Boolean(error)}
          helperText={isTouched && error?.message}
        />
      );
      break;
    case "select":
      if (valueOptions && valueOptions.length === 0) {
        inputField = <></>;
        break;
      } else {
        inputField = (
          <Select
            field={field}
            title={title}
            multiple={multiple}
            autoFocus={autoFocus}
            valueOptions={valueOptions}
            mapped={mapped}
          />
        );
      }

      break;
    case "number":
      inputField = (
        <TextField
          fullWidth
          type="number"
          margin="normal"
          required={!!required}
          autoFocus={autoFocus}
          id={name}
          label={camelCaseToString(name)}
          autoComplete={name}
          {...field}
          onChange={(e) => {
            onChange(e);
            if (customOnChange) customOnChange(e);
          }}
          error={isTouched && Boolean(error)}
          helperText={isTouched && error?.message}
        />
      );
      break;
    case "datepicker":
      inputField = (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            sx={{ width: "100%", mt: 1 }}
            value={dayjs(field.value)}
            onChange={(value: any) => {
              onChange(dayjs(value?.format()).valueOf());
              if (customOnChange)
                customOnChange(dayjs(value?.format()).valueOf());
            }}
            label={`${title || camelCaseToString(name)}  (MM/DD/YYYY)`}
            maxDate={dayjs()}
            defaultValue={dayjs()}
          />
        </LocalizationProvider>
      );
      break;
    case "multiplechoice":
      if (valueOptions && valueOptions.length === 0) {
        inputField = <></>;
        break;
      } else {
        inputField = (
          <MultipleChoiceInput
            label={title || camelCaseToString(name)}
            valueOptions={(
              valueOptions as { display: string; value: number }[]
            ).map((vo) => {
              if (isObject(vo)) return vo;
              return {
                display: vo,
                value: vo,
              };
            })}
            onClick={(value: any) => {
              onChange(value);
              if (customOnChange) customOnChange(value);
            }}
          />
        );
      }
      break;
    default:
      inputField = <></>;
      break;
  }
  return inputField;
}
