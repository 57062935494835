import { SxProps, Theme } from "@mui/material";
import Typography from "@mui/material/Typography";

interface TitleProps {
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
}

export default function Title({ children, sx }: TitleProps) {
  return (
    <Typography
      sx={sx}
      component="h2"
      variant="h6"
      color="primary"
      gutterBottom
    >
      {children}
    </Typography>
  );
}
