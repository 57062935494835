import { Paid } from "@mui/icons-material";
import { Box, Grid, IconButton, SxProps } from "@mui/material";
import { PrefixContext } from "context";
import { Children } from "helpers/types";
import { useContext } from "react";
import { Link } from "react-router-dom";

export default function ProOverlay({
  children,
  sx,
}: {
  children: Children;
  sx?: SxProps;
}) {
  const { limited } = useContext(PrefixContext);
  const child = typeof children === "function" ? children() : children;
  if (limited)
    return (
      <Grid
        sx={{
          position: "relative",
        }}
      >
        {child}
        <Box
          component={Link}
          to={"/guest/user/prooptions"}
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            height: "100%",
            bgcolor: "rgba(0, 0, 0, 0.1)",
            ...sx,
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              top: -10,
              right: 10,
              width: "5%",
              height: "5%",
            }}
          >
            <Paid fontSize="large" color="secondary" />
          </IconButton>
        </Box>{" "}
      </Grid>
    );
  return <>{child}</>;
}
