import { BasicData, Model } from "helpers/types";
import * as yup from "yup";
import { findBy as findByApi } from "api";
import { Box, IconButton, Paper, TextField } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { ControllerRenderProps, SubmitHandler, useForm } from "react-hook-form";
import { Add, Clear, Search } from "@mui/icons-material";
import useApiHook from "hooks/useApiHook";
import ApiStatus from "components/ApiStatus";
import List from "components/List";
import useIdObjectHook from "hooks/useIdObjectHook";
import { useEffect, useRef, useState } from "react";
import ControlledPopover from "components/ControlledPopover";
import { MenuItem, Select as MUISelect } from "@mui/material";

type Inputs = { findText: string };
export default function ModelSelectInput({
  model,
  multiple,
  field,
  defaultValues,
  returnIds = true,
  filter,
  searchBy,
  where,
  type,
  findByString = "name",
  addInput,
  secondaryDisplay,
  secondaryMutate,
}: {
  model: Model;
  multiple: boolean;
  field: ControllerRenderProps;
  defaultValues?: BasicData[];
  returnIds?: boolean;
  filter?: string[];
  searchBy?: string;
  where?: string;
  type?: string | { typeName: string; typeDisplay: string }[];
  findByString?: string;
  addInput?: (res: any) => any;
  secondaryDisplay?: string;
  secondaryMutate?: (arg: any) => string;
}) {
  const { label: modelLabel, displayName, color } = model;
  const label = searchBy || displayName;
  const searchFilter = filter || [modelLabel];
  const [findResults, findBy, setFindBy] = useApiHook<BasicData[]>(
    [],
    findByApi,
  );
  const [open, setOpen] = useState(false);
  const [typeSelected, setSelectedType] = useState(
    Array.isArray(type) ? type[0].typeName : type,
  );
  const textRef = useRef();
  const { onChange } = field;
  const [dataObject, idArray, push, remove, set] = useIdObjectHook(
    returnIds ? onChange : () => {},
    true,
    multiple,
    defaultValues,
    returnIds ? () => {} : onChange,
  );
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    trigger,
    reset,
    watch,
  } = useForm<Inputs>({
    resolver: yupResolver(
      yup.object({
        findText: yup.string().required("Can not search empty field"),
      }),
    ),
  });
  useEffect(() => {
    if (!field.value) {
      set([]);
    }
  }, [field.value]);

  const onSubmit: SubmitHandler<Inputs> = async (values: Inputs) => {
    if (!values.findText) return;
    setFindBy([]);
    setOpen(true);
    await findBy({
      findBy: findByString,
      findString: values.findText,
      filter: searchFilter,
      where: where,
      type: typeSelected,
    });
  };
  const handleKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      handleSubmit(onSubmit)(event);
    }
  };
  useEffect(() => {
    if (defaultValues) set(defaultValues);
  }, [defaultValues]);
  return (
    <Box>
      <ApiStatus error={findResults.error} />
      <Box sx={{ display: "flex" }}>
        <TextField
          margin="normal"
          fullWidth
          id="findText"
          placeholder={`Search ${label}`}
          {...register("findText")}
          onChange={(e) => {
            register("findText").onChange(e);
          }}
          onKeyDown={handleKeyDown}
          error={Boolean(errors.findText)}
          helperText={errors.findText?.message || " "}
          InputProps={{
            endAdornment: (
              <ControlledPopover
                onClick={handleSubmit(onSubmit)}
                open={open}
                ref={textRef}
                handleClose={() => {
                  setOpen(false);
                }}
                button={() => (
                  <Box ref={textRef}>
                    <IconButton sx={{ color }} onClick={handleSubmit(onSubmit)}>
                      <Search />
                    </IconButton>
                  </Box>
                )}
              >
                <Box
                  sx={{
                    p: 1,
                    width: { xs: "80vw", sm: "80vw", md: "25vw" },
                    height: { xs: "60vw", sm: "60vw", md: "40vw" },
                  }}
                >
                  <List
                    isLoading={isSubmitting}
                    results={findResults.data}
                    onClick={async (res) => {
                      setOpen(false);
                      setFindBy([]);
                      if (addInput) {
                        const newRes = await addInput(res);
                        push(newRes);
                      } else {
                        push(res);
                      }
                      reset();
                    }}
                    primary={"name"}
                    secondary="searchedTerm"
                    onClickIcon={<Add />}
                    resultString={`${label} Found`}
                    sx={{
                      width: { xs: "100%", sm: "100%", md: "25vw" },
                    }}
                    scrollable={true}
                  />
                </Box>
              </ControlledPopover>
            ),
          }}
        />
        {Array.isArray(type) && (
          <Box sx={{ pt: 2, pl: 1 }}>
            <MUISelect
              defaultValue={typeSelected}
              onChange={(e) => {
                setSelectedType(e.target.value);
              }}
            >
              {type.map((option: { typeName: string; typeDisplay: string }) => {
                const { typeName, typeDisplay } = option;
                return (
                  <MenuItem
                    id={`menuitem-${typeName}-${typeDisplay}`}
                    key={`menuitem-${typeName}-${typeDisplay}`}
                    value={typeName}
                  >
                    {typeDisplay}
                  </MenuItem>
                );
              })}
            </MUISelect>
          </Box>
        )}
      </Box>

      <Paper
        sx={{
          overflow: "auto",
          height: { xs: "40vw", sm: "40vw", md: "10vw" },
          p: 1,
          mt: 1,
          border: `solid ${color} 2px`,
        }}
        elevation={4}
      >
        <List
          results={dataObject}
          primary="name"
          secondary={secondaryDisplay}
          secondaryMutate={secondaryMutate}
          onClick={(res) => {
            remove(res);
          }}
          onClickIcon={<Clear />}
          resultString={`${label} Selected`}
        />
      </Paper>
    </Box>
  );
}
