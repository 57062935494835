import { Box, Button, Card, Container, Grid, Paper } from "@mui/material";
import ApiStatus from "components/ApiStatus";
import FloatingDrawer from "components/FloatingDrawer";
import Title from "components/Title";
import { PrefixContext } from "context";
import { mapDataAsCamelCase } from "helpers/dataMapping";
import { Data, Model } from "helpers/types";
import useApiHook from "hooks/useApiHook";
import { modelByLabelMap } from "models/basicModels";
import { useContext, useEffect } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import {
  getEmptyArrayRelationship,
  getInitialFromSchema,
} from "schema/commonSchema";
import RelationsView from "views/RelationsView";

export default function ViewPage({ model }: { model: Model }) {
  const { api, schema, relations, label } = model;
  const { id: selectedId } = useParams();
  const { prefix } = useContext(PrefixContext);
  const emptyValues = getInitialFromSchema(schema);
  const emptyRelations = getEmptyArrayRelationship(relations);
  const [recievedNode, getOne] = useApiHook<Data>(
    {
      id: "",
      ...emptyValues,
      ...emptyRelations,
      label,
    },
    api.getOne,
  );
  useEffect(() => {
    if (selectedId) getOne(selectedId);
  }, [model, selectedId]);
  const { data: recievedDataDirty } = recievedNode;
  const recievedData = mapDataAsCamelCase(recievedDataDirty, emptyValues);
  const listKeys = {
    properties: schema.map((sch) => ({ key: sch.key, display: sch.key })),
    relations: relations
      ? relations
          .map((rel) => {
            return {
              ...rel,
              key: rel.name,
              label: rel.name,
              relationLabel: rel.name,
            };
          })
          .filter((rels) => !!recievedData[rels.key])
      : [],
  };
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <ApiStatus
        error={recievedNode.error}
        isLoading={recievedNode.isLoading}
      />
      <FloatingDrawer buttonText="Color Guide">
        <Card sx={{ p: 2 }}>
          {Object.keys(modelByLabelMap).map((key, index) => (
            <Box
              key={`${key}-${index}`}
              sx={{ color: modelByLabelMap[key].color }}
            >
              {modelByLabelMap[key].displayName}
            </Box>
          ))}
        </Card>
      </FloatingDrawer>
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <Paper
            elevation={1}
            sx={{
              p: 1,
              m: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Title>{recievedData.name}</Title>
            {api.sideEffects ? (
              <Button
                sx={{ ml: 3 }}
                component={RouterLink}
                to={`${prefix}/view/${label.toLowerCase()}/sideeffects/${selectedId}`}
                variant={"outlined"}
                color="secondary"
              >
                View Side Effects
              </Button>
            ) : null}
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Paper
            elevation={1}
            sx={{
              p: 1,
              m: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {recievedData && recievedData.id && (
              <RelationsView result={recievedData} listKeys={listKeys} />
            )}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
