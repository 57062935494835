import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { SxProps, Theme } from "@mui/material";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
type Props = {
  open: boolean;
  onClose?: Function;
  children: any;
  sx?: SxProps<Theme> | undefined;
};
export default function MyModal({ open, onClose, children, sx }: Props) {
  return (
    <Modal
      open={open}
      onClose={() => {
        if (onClose) onClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={sx || style}>
        {/* {children} */}
        <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
          {onClose && (
            <Button
              onClick={() => {
                onClose();
              }}
            >
              Close
            </Button>
          )}
        </Box>
        {children}
      </Box>
    </Modal>
  );
}
