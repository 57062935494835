import _ from "lodash";

export function validateInputs(
  inputSchema: any,
  getValues: any,
  type: any,
  errorMessage: string,
  setError: (arg0: string | null) => void,
  callback: any
) {
  switch (type) {
    case "atleastone":
      const values = inputSchema.map(
        (input: { name: any; model: { label: any } }) => {
          return getValues(`${input.name || input.model.label}`);
        }
      );
      if (_.flatten(values).length === 0) {
        setError(errorMessage);
        return false;
      } else {
        setError(null);
        callback();
        return true;
      }
    case "onefromlist":
      const oneValue = inputSchema.map(
        (input: { keyToSend: (arg0: any) => any }) => {
          const name = input.keyToSend(getValues);
          return getValues(name);
        }
      );
      if (_.flatten(oneValue).filter(fl => !!fl).length === 0) {
        setError(errorMessage);
        return false;
      } else {
        setError(null);
        callback();
        return true;
      }
    default:
      return true;
  }
}
