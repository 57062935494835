import React from "react";
import {
  Box,
  Toolbar,
  AppBar,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Drawer,
  Divider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link as RouterLink } from "react-router-dom";
import logosvg from "images/LogoSameline.svg";
import { Props } from "helpers/types";

{
  /* <a href="https://www.freepik.com/free-vector/set-with-doctor-medical-people-working-research-online-cartoon-charactor-flat-illustration-medical-concept_13330900.htm">Image by jcomp</a> on Freepik Set with doctor and medical people working or research online in cartoon charactor, flat illustration, medical concept*/
}
{
  /* <a href="https://www.freepik.com/free-vector/medical-good-team-hospital-staff-doctors-nurse-illustration_13400062.htm">Image by macrovector</a> on Freepik Medical good team. hospital staff doctors and nurse. illustration */
}
type PageLink = {
  link: string;
  text: string;
  sx?: Object;
  variant?: "text" | "contained" | "outlined";
};
const drawerWidth = 240;
const pages: PageLink[] = [
  {
    link: "/",
    text: "Home",
  },
  // {
  //   link: "",
  //   text: "News",
  // },
  {
    link: "/funding",
    text: "Funding",
  },
  // {
  //   link: "",
  //   text: "Careers",
  // },
  {
    link: "/signup",
    text: "Sign up",
  },
  {
    link: "/login",
    text: "Log In",
    variant: "contained",
    sx: { backgroundColor: "primary", color: "white" },
  },
];
export default function LandingLayout({ children }: Props) {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <List>
        {pages.map((item) => (
          <Box key={item.text}>
            <ListItem disablePadding>
              <ListItemButton
                component={RouterLink}
                to={item.link}
                sx={{ textAlign: "center" }}
              >
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
            <Divider />
          </Box>
        ))}
      </List>
    </Box>
  );
  const container =
    window !== undefined ? () => window.document.body : undefined;
  return (
    <Box>
      <AppBar
        position="absolute"
        color="transparent"
        // sx={{ width: { xs: "90%", sm: "90%", md: '100%' } }}
      >
        <Toolbar disableGutters sx={{ backgroundColor: "white", pl: 2 }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }}>
            <RouterLink to="/">
              <img
                src={logosvg}
                alt="Leaflit"
                onClick={() => console.log("clicked")}
                style={{
                  maxHeight: "100%",
                  maxWidth: "250px",
                  // margin: "0px 20px",
                  padding: "10px",
                }}
              />
            </RouterLink>
          </Box>
          <Box sx={{ display: { xs: "none", sm: "block", md: "flex" }, mr: 2 }}>
            {pages.map((page: PageLink) => {
              const { link, text, sx: buttonSx = {}, variant = "text" } = page;
              return (
                <Button
                  key={text}
                  component={RouterLink}
                  to={link}
                  variant={variant}
                  sx={{
                    my: 2,
                    mr: 1,
                    color: "primary",
                    display: "block",
                    ...buttonSx,
                  }}
                >
                  {text}
                </Button>
              );
            })}
          </Box>
          <Box component="nav">
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: "block", sm: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
            >
              {drawer}
            </Drawer>
          </Box>
        </Toolbar>
      </AppBar>
      {typeof children === "function" ? children() : children}
    </Box>
  );
}
