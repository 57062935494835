import { idMapValidation } from "../schema/commonSchema";
import { DoseForm, BrandName, DrugPack } from "./basicModels";

DrugPack.relations = [
  /* -------------------------------------------------------------------------- */
  /*                             Outgoing Relations                             */
  /* -------------------------------------------------------------------------- */
  {
    relatedModel: DoseForm,
    name: "HasDoseForm",
    displayName: "Has",
    properties: [],
    schema: idMapValidation,
  },
  /* -------------------------------------------------------------------------- */
  /*                             Incoming Relations                             */
  /* -------------------------------------------------------------------------- */
  {
    relatedModel: BrandName,
    name: "HasBrandName",
    displayName: "Has",
    properties: [],
    schema: idMapValidation,
  },
  /* -------------------------------------------------------------------------- */
  /*                               Self Relations                               */
  /* -------------------------------------------------------------------------- */
];
export const drugPackModel = DrugPack;
