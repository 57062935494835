import { allGlobals, initGlobals } from "helpers/globals";
import { redirect } from "react-router-dom";

export async function init() {
  await initGlobals();
  return null;
}
export async function userChecks() {
  const { token, user } = allGlobals();
  if (!token || !user) {
    return redirect("/login");
  }
}
export async function userLoggedIn() {
  const { token, user } = allGlobals();
  if (!!token && !!user) {
    return redirect("/app");
  }
}
export function termsOfService() {
  const { user } = allGlobals();
  if (!user.terms_of_service) {
    return redirect("/termsofservice");
  }
}
export async function redirectToUserPrefix(prefix?: string) {
  const { userDetails } = allGlobals();
  const { prefix: userPrefix, isAdmin } = userDetails;
  if (!prefix && userPrefix) {
    return redirect(userPrefix);
  }
  if (!isAdmin && userPrefix !== prefix) {
    return redirect(userPrefix);
  }
}

export async function loaderRunner(loaderArray: any[]) {
  return loaderArray.find((arg) => !!arg) || null;
}
