import { Container } from "@mui/material";
import React from "react";
import ApiStatus from "../components/ApiStatus";
export default function ErrorPage({ error }: { error: any }) {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      Not Found
    </Container>
  );
}
