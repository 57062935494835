import { Card, LinearProgress, Typography } from "@mui/material";

export default function CustomLoader({ loaderText }: { loaderText: string }) {
  return (
    <Card sx={{ maxWidth: "35vh", p: 2 }}>
      <LinearProgress />
      <Typography align="center" sx={{ m: 2 }}>
        {loaderText}
      </Typography>
    </Card>
  );
}
