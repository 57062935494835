import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import {
  List as MuiList,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  SxProps,
  Theme,
  Box,
  CircularProgress,
} from "@mui/material";
import { BasicData } from "helpers/types";
import { createRef, useEffect, useState } from "react";

export default function List({
  results,
  onClick,
  primary,
  secondary,
  onClickIcon,
  resultString,
  sx,
  isLoading,
  listChild,
  secondaryMutate,
  scrollable,
}: {
  results: BasicData[];
  onClick?: (arg: any) => void;
  primary?: string;
  secondary?: string;
  onClickIcon?: ReactJSXElement | ((arg: any) => ReactJSXElement);
  resultString?: string;
  sx?: SxProps<Theme> | undefined;
  isLoading?: boolean;
  listChild?: (arg: any) => ReactJSXElement;
  secondaryMutate?: (arg: any) => string;
  scrollable?: boolean;
}) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const lastRef = createRef<any | undefined>();
  useEffect(() => {
    if (lastRef.current) lastRef.current.focusVisible();
  });
  if (isLoading)
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  function getResultString(res: any[], resString?: string) {
    if (!resString) return null;
    if (res.length === 0) return `No ${resultString}`;
    if (res.length === 1) return resString;
    return `${res.length} ${resString}`;
  }
  const moveUp = () => {
    if (selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1);
    }
  };

  const moveDown = () => {
    if (selectedIndex < results.length - 1) {
      setSelectedIndex(selectedIndex + 1);
    }
  };
  const onKeyPressed = (e: any) => {
    e.preventDefault();
    if (!scrollable) return;
    if (e.keyCode == "38") {
      moveUp();
    } else if (e.keyCode == "40") {
      moveDown();
    }
    if (e.keyCode === 13) {
      if (onClick) onClick(results[selectedIndex]);
    }
    e.stopPropagation();
  };
  return (
    <MuiList
      dense
      subheader={getResultString(results, resultString)}
      disablePadding
      sx={{
        overflowY: "auto",
        maxHeight: { xs: undefined, sm: undefined, md: "40vw" },
        ...sx,
      }}
      onKeyDown={(e) => e.preventDefault()}
    >
      {results &&
        results.map((result, index) => {
          return (
            <ListItem
              key={index}
              alignItems="flex-start"
              divider
              dense
              disablePadding
              disableGutters
              sx={{ maxWidth: { xs: "100%", sm: "100%", md: "30vw" } }}
              onKeyDown={onKeyPressed}
            >
              <ListItemButton
                action={
                  selectedIndex === index && scrollable ? lastRef : undefined
                }
                autoFocus={index === 0 && scrollable}
                disableTouchRipple={!onClick}
                disabled={!onClick}
                onClick={() => {
                  if (onClick) onClick(result);
                }}
                onKeyDown={(e) => e.preventDefault()}
                onKeyUp={(e) => e.preventDefault()}
              >
                {(primary || secondary) && (
                  <ListItemText
                    primaryTypographyProps={{
                      sx: {
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      },
                    }}
                    primary={primary ? `${result[primary]}` : null}
                    secondary={
                      secondary
                        ? secondaryMutate
                          ? secondaryMutate(result[secondary])
                          : `${result[secondary] || ""}`
                        : null
                    }
                  />
                )}
                {onClickIcon && (
                  <ListItemIcon>
                    {typeof onClickIcon === "function"
                      ? onClickIcon(result)
                      : onClickIcon}
                  </ListItemIcon>
                )}
              </ListItemButton>
              {listChild && listChild(result)}
            </ListItem>
          );
        })}
    </MuiList>
  );
}
