import { Box, Card, CardActionArea, Typography } from "@mui/material";
import { OnClickFunction, ViewRelationship } from "helpers/types";
import BasicPopover from "./Popover";
import ModelDetails from "./ModelDetails";

export default function RelationCard({
  relationElement,
}: {
  relationElement: ViewRelationship;
}) {
  return (
    <Box>
      <Card
        sx={{
          ":hover": {
            boxShadow: 20,
            cursor: "pointer",
          },
        }}
      >
        <BasicPopover
          button={(onClick: OnClickFunction) => {
            return (
              <CardActionArea
                onClick={(event) => {
                  event.preventDefault();
                  onClick(event);
                }}
                sx={{ p: 2 }}
              >
                <Typography
                  component="h1"
                  variant="body1"
                  sx={{
                    width: "75px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {`${relationElement.alias}`}
                </Typography>
                <Typography
                  component="h1"
                  variant="subtitle2"
                  sx={{
                    width: "75px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {`Click to expand`}
                </Typography>
              </CardActionArea>
            );
          }}
        >
          <ModelDetails data={relationElement} />
        </BasicPopover>
      </Card>
    </Box>
  );
}
