import * as React from "react";
import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { myUserDetails as myUserDetailsApi } from "../api";
import useApiHook from "hooks/useApiHook";
import ApiStatus from "components/ApiStatus";
import { setGlobal } from "helpers/globals";

export default function SubscriptionSuccess() {
  const [userDetails, myUserDetails] = useApiHook(null, myUserDetailsApi);
  React.useEffect(() => {
    myUserDetails().then((res: any) => {
      const user = res;
      setGlobal("user", JSON.stringify(user));
    });
  }, []);
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <ApiStatus error={userDetails.error} />
      <Paper sx={{ p: 2 }}>
        <Container
          disableGutters
          maxWidth="sm"
          component="main"
          sx={{ pt: 8, pb: 6 }}
        >
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="primary"
            gutterBottom
          >
            Thank you for your purchase
          </Typography>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              color="secondary"
              sx={{ color: "white" }}
              component={Link}
              to={`/app`}
              variant="contained"
            >
              Go to Dashboard
            </Button>
          </Grid>
        </Container>
      </Paper>
    </Container>
  );
}
