import { useState } from "react";

const useModalHook = (): [boolean, Function, Function] => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = (onClose: Function) => {
    setOpen(false);
    if (onClose) onClose();
  };
  return [open, handleOpen, handleClose];
};

export default useModalHook;
