import { Box, Card, CardActionArea, Typography } from "@mui/material";
import { Children, Relations } from "helpers/types";
import MyModal from "./Modal";
import useModalHook from "hooks/useModalHook";
import { modelByLabelMap } from "models/basicModels";

export default function PathBox({
  listKey,
  children,
}: {
  listKey: Relations;
  children: Children;
}) {
  const [open, handleOpenModal, handleCloseModal] = useModalHook();
  const {
    relatedModel: { label, displayName },
  } = listKey;
  return (
    <Box>
      <Card
        sx={{
          ":hover": {
            boxShadow: 20,
            cursor: "pointer",
          },
        }}
      >
        <CardActionArea
          onClick={(event) => {
            event.preventDefault();
            handleOpenModal();
          }}
          sx={{ p: 2 }}
        >
          <Typography component="h1" variant="body1">
            {displayName}
          </Typography>
          <Typography component="h1" variant="subtitle2" textAlign={"center"}>
            Click to view
          </Typography>
        </CardActionArea>
        <MyModal
          open={open}
          onClose={handleCloseModal}
          sx={{
            position: "absolute" as "absolute",
            top: "0%",
            right: "0%",
            width: "75vw",
            height: "100vw",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 1,
          }}
        >
          {typeof children === "function" ? children() : children}
        </MyModal>
      </Card>
    </Box>
  );
}
