import { searchModel } from "../models";
import SearchPage from "pages/SearchPage";

const searchRoutes = Object.keys(searchModel.schema).map((key) => ({
  path: key,
  element: <SearchPage schema={searchModel.schema[key]} key={key} />,
}));
const extraRoutes = [
  {
    path: "bothincompat",
    element: (
      <SearchPage
        schema={searchModel.schema["incompatMedFromList"]}
        schemas={[
          searchModel.schema["incompatMedFromList"],
          searchModel.schema["incompatIngFromList"],
        ]}
        key={"bothincompat"}
      />
    ),
  },
];
export default [...searchRoutes, ...extraRoutes];
