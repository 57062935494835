import * as yup from "yup";
import { idMapValidation } from "../schema/commonSchema";
import {
  BrandName,
  Target,
  Group,
  Schedule,
  MoA,
  DoseForm,
  SideEffect,
  BeersCriteria,
  Label,
  IngredientGroup,
  ClinicalDrug,
  Ingredient,
} from "./basicModels";

Ingredient.relations = [
  /* -------------------------------------------------------------------------- */
  /*                             Outgoing Relations                             */
  /* -------------------------------------------------------------------------- */
  {
    relatedModel: BrandName,
    name: "CompatibilityWithBrandName",
    displayName: "Compatibility",
    properties: [
      {
        key: "Description",
        property: "description",
        validation: yup.string(),
        type: "string",
        default: "",
      },
      {
        key: "CompatibilityType",
        property: "compatibilityType",
        validation: yup.string(),
        type: "string",
        default: "",
      },
    ],
    schema: idMapValidation,
  },
  {
    relatedModel: Target,
    name: "MayTreat",
    displayName: "May treat",
    properties: [
      {
        key: "RelationType",
        property: "relationType",
        validation: yup.string(),
        type: "string",
        default: "",
      },
    ],
    schema: idMapValidation,
  },
  {
    relatedModel: Target,
    name: "MayDiagnose",
    displayName: "May diagnose",
    properties: [
      {
        key: "RelationType",
        property: "relationType",
        validation: yup.string(),
        type: "string",
        default: "",
      },
    ],
    schema: idMapValidation,
  },
  {
    relatedModel: Target,
    name: "MayPrevent",
    displayName: "May prevent",
    properties: [
      {
        key: "RelationType",
        property: "relationType",
        validation: yup.string(),
        type: "string",
        default: "",
      },
    ],
    schema: idMapValidation,
  },
  {
    relatedModel: Target,
    name: "Induces",
    displayName: "Induces",
    properties: [
      {
        key: "RelationType",
        property: "relationType",
        validation: yup.string(),
        type: "string",
        default: "",
      },
    ],
    schema: idMapValidation,
  },
  {
    relatedModel: Target,
    name: "ContraindicatedWith",
    displayName: "Contraindicated",
    properties: [
      {
        key: "RelationType",
        property: "relationType",
        validation: yup.string(),
        type: "string",
        default: "",
      },
    ],
    schema: idMapValidation,
  },
  {
    relatedModel: Group,
    name: "HasVA",
    displayName: "Has VA",
    properties: [
      {
        key: "RelationType",
        property: "relationType",
        validation: yup.string(),
        type: "string",
        default: "",
      },
    ],
    schema: idMapValidation,
  },
  {
    relatedModel: Group,
    name: "HasAtc",
    displayName: "Has Atc",
    properties: [
      {
        key: "RelationType",
        property: "relationType",
        validation: yup.string(),
        type: "string",
        default: "",
      },
    ],
    schema: idMapValidation,
  },
  {
    relatedModel: Schedule,
    name: "HasSchedule",
    displayName: "Has",
    properties: [
      {
        key: "RelationType",
        property: "relationType",
        validation: yup.string(),
        type: "string",
        default: "",
      },
    ],
    schema: idMapValidation,
  },
  {
    relatedModel: MoA,
    name: "HasMoA",
    displayName: "Has",
    properties: [
      {
        key: "RelationType",
        property: "relationType",
        validation: yup.string(),
        type: "string",
        default: "",
      },
    ],
    schema: idMapValidation,
  },
  {
    relatedModel: DoseForm,
    name: "HasDoseForm",
    displayName: "Has",
    properties: [],
    schema: idMapValidation,
  },
  {
    relatedModel: SideEffect,
    name: "HasSideEffect",
    displayName: "Has",
    properties: [
      {
        key: "MaxAge",
        property: "maxAge",
        validation: yup.string(),
        type: "string",
        default: "",
      },
      {
        key: "MinAge",
        property: "minAge",
        validation: yup.string(),
        type: "string",
        default: "",
      },
      {
        key: "AdditionalNotes",
        property: "additionalNotes",
        validation: yup.string(),
        type: "string",
        default: "",
      },
      {
        key: "Ethnicity",
        property: "ethnicity",
        validation: yup.string(),
        type: "string",
        default: "",
      },
      {
        key: "Weight",
        property: "weight",
        validation: yup.string(),
        type: "string",
        default: "",
      },
      {
        key: "Rarity",
        property: "rarity",
        validation: yup.string(),
        type: "string",
        default: "",
      },
      {
        key: "RaritySecondary",
        property: "raritySecondary",
        validation: yup.string(),
        type: "string",
        default: "",
      },
    ],
    schema: idMapValidation,
  },
  {
    relatedModel: BeersCriteria,
    name: "IngredientInteractionOne",
    displayName: "In group one",
    properties: [
      {
        key: "Notes",
        property: "notes",
        validation: yup.string(),
        type: "string",
        default: "",
      },
    ],
    schema: idMapValidation,
  },
  /* -------------------------------------------------------------------------- */
  /*                             Incoming Relations                             */
  /* -------------------------------------------------------------------------- */
  {
    relatedModel: BrandName,
    name: "PartOfBrandName",
    displayName: "Part Of",
    properties: [],
    schema: idMapValidation,
  },
  {
    relatedModel: Label,
    name: "PartOfLabel",
    displayName: "Part Of",
    properties: [],
    schema: idMapValidation,
  },
  {
    relatedModel: IngredientGroup,
    name: "PartOfIngredientGroup",
    displayName: "Part Of",
    properties: [],
    schema: idMapValidation,
  },
  {
    relatedModel: ClinicalDrug,
    name: "PartOfClinicalDrug",
    displayName: "Part Of",
    properties: [],
    schema: idMapValidation,
  },
  {
    relatedModel: BeersCriteria,
    name: "AffectsBeers",
    displayName: "Affects",
    properties: [],
    schema: idMapValidation,
  },
  {
    relatedModel: BeersCriteria,
    name: "ExcludedFromBeers",
    displayName: "Excludes",
    properties: [],
    schema: idMapValidation,
  },
  {
    relatedModel: BeersCriteria,
    name: "IngredientInteractionTwoIn",
    displayName: "Group Two",
    properties: [],
    schema: idMapValidation,
  },
  /* -------------------------------------------------------------------------- */
  /*                               Self Relations                               */
  /* -------------------------------------------------------------------------- */
  {
    relatedModel: Ingredient,
    name: "CompatibilityWithIngredientOut",
    displayName: "Compatibility",
    properties: [
      {
        key: "CompatibilityType",
        property: "compatibilityType",
        validation: yup.string(),
        type: "string",
        default: "",
      },
      {
        key: "Description",
        property: "description",
        validation: yup.string(),
        type: "string",
        default: "",
      },
    ],
    schema: idMapValidation,
  },
  {
    relatedModel: Ingredient,
    name: "CompatibilityWithIngredientIn",
    displayName: "Compatibility",
    properties: [
      {
        key: "CompatibilityType",
        property: "compatibilityType",
        validation: yup.string(),
        type: "string",
        default: "",
      },
      {
        key: "Description",
        property: "description",
        validation: yup.string(),
        type: "string",
        default: "",
      },
    ],
    schema: idMapValidation,
  },
  {
    relatedModel: Ingredient,
    name: "MadeOf",
    displayName: "Made of",
    properties: [],
    schema: idMapValidation,
  },
  {
    relatedModel: Ingredient,
    name: "MadeFrom",
    displayName: "Made from",
    properties: [],
    schema: idMapValidation,
  },
  {
    relatedModel: Ingredient,
    name: "HasPreciseForm",
    displayName: "Has precise form",
    properties: [],
    schema: idMapValidation,
  },
  {
    relatedModel: Ingredient,
    name: "IsPreciseForm",
    displayName: "Is precise form",
    properties: [],
    schema: idMapValidation,
  },
];
export const ingredientModel = Ingredient;
