import { Data } from "../helpers/types";
import { getReq, postReq } from "./instance";

export const signUp = async (userData: Data) => {
  return postReq("/signup", userData);
};

export const logIn = async (email: string, password: string) => {
  return postReq("/login", {
    email,
    password,
  });
};

export const logOut = async () => {
  return postReq("/logout", {});
};

export const myUserDetails = async (params = {}) => {
  return getReq("/user", params);
};

export const resetPassword = async (
  email: string,
  password: string,
  newPassword: string,
) => {
  return postReq("/resetpassword", {
    email,
    password,
    newPassword,
  });
};

export const getAccessLevels = async () => {
  return getReq("/accesslevels");
};
