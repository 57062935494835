import { deleteReq, getReq, postReq, putReq } from "./instance";

export const getPatient = async (patientId: string, params = {}) => {
  return getReq(`patients/${patientId}`, params);
};

export const getPatientAnalysis = async (patientId: string, params = {}) => {
  return getReq(`patients/analysis/${patientId}`, params);
};

export const findByPatients = async (properties: {}) => {
  return postReq("/patients/findby", properties);
};

export const getAllPatients = async (params = {}) => {
  return getReq(`/patients`, params);
};

export const createPatient = async (properties: {}, params = {}) => {
  return postReq("/patients", properties, params);
};

export const editPatient = async (
  patientId: string,
  properties: {},
  params = {},
) => {
  return putReq(`/patients/${patientId}`, properties, params);
};

export const deletePatient = async (patientId: string) => {
  return deleteReq(`/patients/${patientId}`);
};

export const editPatientRelationshipProperties = async (
  relationId: string,
  properties: {},
  params = {},
) => {
  return putReq(`/patients/relation/${relationId}`, properties, params);
};

export const patientHasDoctor = async (properties: {}, params = {}) => {
  return postReq("/patients/hasdoctor", properties, params);
};
export const patientPrescribed = async (properties: {}, params = {}) => {
  return postReq("/patients/prescribed", properties, params);
};
export const patientCheckPrescribed = async (properties: {}, params = {}) => {
  return postReq(`/patients/checkprescription`, properties, params);
};
export const patientHasIssues = async (properties: {}, params = {}) => {
  return postReq("/patients/hasissues", properties, params);
};
// export const deletePatientHasDoctor = async (id: string) => {
//   return postReq("/patients/delete/hasdoctor", { id });
// };
export const stopPrescription = async (properties: {}) => {
  return postReq("/patients/stop/prescribed", properties);
};
export const deletePatientPrescribed = async (properties: {}) => {
  return postReq("/patients/delete/prescribed", properties);
};
export const deletePatientHasIssues = async (id: string) => {
  return postReq("/patients/delete/hasissues", { id });
};
export const createPatientRelation = async (
  relationName: string,
  properties: {},
) => {
  return postReq(`/patients/${relationName}`, properties);
};
