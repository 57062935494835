import { idMapValidation } from "../schema/commonSchema";
import { Label, Manufacturer } from "./basicModels";

Manufacturer.relations = [
  /* -------------------------------------------------------------------------- */
  /*                             Outgoing Relations                             */
  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                             Incoming Relations                             */
  /* -------------------------------------------------------------------------- */
  {
    relatedModel: Label,
    name: "ManufactursBy",
    displayName: "By",
    properties: [],
    schema: idMapValidation,
  },
  {
    relatedModel: Label,
    name: "ManufactursFor",
    displayName: "For",
    properties: [],
    schema: idMapValidation,
  },
  /* -------------------------------------------------------------------------- */
  /*                               Self Relations                               */
  /* -------------------------------------------------------------------------- */
];
export const manufacturerModel = Manufacturer;
