import { idMapValidation } from "../schema/commonSchema";
import { Label, DoseForm, ClinicalDrug, NDC } from "./basicModels";

NDC.relations = [
  /* -------------------------------------------------------------------------- */
  /*                             Outgoing Relations                             */
  /* -------------------------------------------------------------------------- */
  {
    relatedModel: Label,
    name: "HasLabel",
    displayName: "Has",
    properties: [],
    schema: idMapValidation,
  },
  /* -------------------------------------------------------------------------- */
  /*                             Incoming Relations                             */
  /* -------------------------------------------------------------------------- */
  {
    relatedModel: DoseForm,
    name: "OneOfDoseForm",
    displayName: "One of",
    properties: [],
    schema: idMapValidation,
  },
  {
    relatedModel: ClinicalDrug,
    name: "OneOfClinicalDrug",
    displayName: "One of",
    properties: [],
    schema: idMapValidation,
  },
  /* -------------------------------------------------------------------------- */
  /*                               Self Relations                               */
  /* -------------------------------------------------------------------------- */
];
export const nDCModel = NDC;
