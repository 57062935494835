import { Box } from "@mui/material";
import { useContext } from "react";
import List from "./List";
import { SourceContext } from "context/Sources";
export default function SourceList({ source }: { source: string[] }) {
  const { sources } = useContext(SourceContext);
  if (!source) return <></>
  return (
    <Box sx={{ p: 1 }}>
      <List
        onClick={(row) => {
          window.open(row.link, "_blank");
        }}
        results={sources.filter((src) => source.includes(src.id))}
        primary="name"
        secondary="link"
      />
    </Box>
  );
}
