import * as React from "react";
import { Container, Grid, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  myUserDetails as myUserDetailsApi,
  sendTestingRequestEmail,
} from "../api";
import useApiHook from "hooks/useApiHook";
import ApiStatus from "components/ApiStatus";
import { setGlobal } from "helpers/globals";
import EmailForm from "forms/EmailForm";

export default function TesterRequestPage() {
  const [userDetails, myUserDetails] = useApiHook<{
    email: string;
    id: string;
  }>(null, myUserDetailsApi);
  const navigate = useNavigate();
  React.useEffect(() => {
    myUserDetails().then((res: any) => {
      const user = res;
      setGlobal("user", JSON.stringify(user));
    });
  }, []);
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <ApiStatus error={userDetails.error} />
      <Paper sx={{ p: 2 }}>
        <Container
          disableGutters
          maxWidth="sm"
          component="main"
          sx={{ pt: 8, pb: 6 }}
        >
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="primary"
            gutterBottom
          >
            Thank you for showing interest Leaflit Care
          </Typography>
          <Typography
            component="h2"
            variant="h4"
            align="center"
            color="primary"
            gutterBottom
          >
            Get your three month free trial now
          </Typography>
          <Grid
            spacing={3}
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item xs={12} md={12} lg={12}>
              {userDetails.data?.email && (
                <EmailForm
                  emailApi={sendTestingRequestEmail}
                  title="Free trial request"
                  email={userDetails.data.email}
                  bodyPlaceholder="Please send us a breif email about your position and what you will be using Leaflit for"
                  bodyLabel="Tell us about yourself"
                  onSuccess={() => {
                    navigate("/app");
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </Container>
  );
}
