import { findMoAGrpForIngredient, getIngredient } from "api";
import { searchModel } from "./search";
import { VpnLock } from "@mui/icons-material";
export const quickSearchModel = {
  key: "searchtype",
  title: "What would you like to inquire today?",
  valueOptions: [
    { display: "Find treatment without allergen", value: "treatmentFind" },
    {
      display: "Find alternate treatment by Mechanism of Action or Group",
      value: "alternateTreatment",
    },
    {
      display: "Find if side effect is linked to perscription",
      value: "sideEffectFind",
    },
    {
      display: "Find labels containing inactive ingredients",
      value: "labelWithInactive",
    },
    {
      display: "Find all side effects of active ingredient",
      value: "allSideEffects",
    },
  ],
  type: "multiplechoice",
  required: true,
  default: null,
  nextSteps: {
    treatmentFind: [
      {
        key: "selecttreatement",
        inputs: [
          {
            key: "selecttreatement",
            title:
              "How would you like to search for a medication, via the following?",
            valueOptions: [
              { display: "Mechanism Of Action", value: "MoA" },
              { display: "Target", value: "Target" },
              { display: "Group", value: "Group" },
            ],
            type: "multiplechoice",
          },
        ],
        type: "multiplechoice",
        required: true,
        default: null,
      },
      {
        key: "treatmentfor",
        title: "Select one or multiple to treat for",
        validationType: "atleastone",
        errorMessage: "Please select at least one value",
        inputs: searchModel.schema.treatmentFind.searchInputs[0],
        filter: (input: any, getValues: (arg0: string) => any) => {
          if (
            getValues("selecttreatement") === (input.name || input.model.label)
          )
            return true;
          return false;
        },
        type: "modelselectinput",
        required: true,
        default: null,
      },
      {
        key: "allergen",
        title: "Select Allergens",
        inputs: [searchModel.schema.treatmentFind.searchInputs[1]],
        validationType: "atleastone",
        errorMessage: "Please select at least one allergen",
        type: "modelselectinput",
        required: true,
        default: null,
        lastStep: true,
      },
    ],
    allSideEffects: [
      {
        key: "activeingredient",
        title: "Select Active Ingredients / Generic Name",
        validationType: "atleastone",
        errorMessage: "Please select at least one value",
        inputs: searchModel.schema.allSideEffects.searchInputs,
        type: "modelselectinput",
        required: true,
        default: null,
        lastStep: true,
      },
    ],
    sideEffectFind: [
      {
        key: "selectprescriptiontype",
        inputs: [
          {
            key: "selectprescriptiontype",
            title: "Preferred search method of the drug?",
            valueOptions: [
              { display: "Brand Names", value: "BrandName" },
              {
                display: "Active Ingredient/Generic Name",
                value: "ActiveIngredients",
              },
            ],
            type: "multiplechoice",
          },
        ],
        type: "multiplechoice",
        required: true,
        default: null,
      },
      {
        key: "prescriptions",
        title: "Select all prescriptions",
        validationType: "atleastone",
        errorMessage: "Please select at least one value",
        inputs: searchModel.schema.sideEffectFind.searchInputs[0],
        filter: (input: any, getValues: (arg0: string) => any) => {
          if (
            getValues("selectprescriptiontype") ===
            (input.name || input.model.label)
          )
            return true;
          return false;
        },
        type: "modelselectinput",
        required: true,
        default: null,
      },
      {
        key: "sideeffect",
        title: "Select Side Effects",
        inputs: [searchModel.schema.sideEffectFind.searchInputs[1]],
        validationType: "atleastone",
        errorMessage: "Please select at least one side effect",
        type: "modelselectinput",
        required: true,
        default: null,
        lastStep: true,
      },
    ],
    labelWithInactive: [
      {
        key: "selectprescriptiontype",
        inputs: [
          {
            key: "selectprescriptiontype",
            title: "Preferred search method of the drug?",
            valueOptions: [
              { display: "Brand Names", value: "BrandName" },
              {
                display: "Active Ingredient/Generic Name",
                value: "ActiveIngredients",
              },
            ],
            type: "multiplechoice",
          },
        ],
        type: "multiplechoice",
        required: true,
        default: null,
      },
      {
        key: "prescriptions",
        title: "Select all prescriptions",
        validationType: "atleastone",
        errorMessage: "Please select at least one value",
        inputs: searchModel.schema.labelWithInactive.searchInputs[0],
        filter: (input: any, getValues: (arg0: string) => any) => {
          if (
            getValues("selectprescriptiontype") ===
            (input.name || input.model.label)
          )
            return true;
          return false;
        },
        type: "modelselectinput",
        required: true,
        default: null,
      },
      {
        key: "inactiveingredients",
        title: "Select allergens",
        inputs: [searchModel.schema.labelWithInactive.searchInputs[1]],
        validationType: "atleastone",
        errorMessage: "Please select at least one inactive ingredient",
        type: "modelselectinput",
        required: true,
        default: null,
        lastStep: true,
      },
    ],
    alternateTreatment: [
      {
        key: "selectprescriptiontype",
        inputs: [
          {
            key: "selectprescriptiontype",
            title: "Preferred search method of the drug?",
            valueOptions: [
              { display: "Brand Names", value: "BrandName" },
              {
                display: "Active Ingredient/Generic Name",
                value: "ActiveIngredients",
              },
            ],
            type: "multiplechoice",
          },
        ],
        type: "multiplechoice",
        required: true,
        default: null,
      },
      {
        key: "prescriptions",
        title: "Select one prescription",
        validationType: "atleastone",
        errorMessage: "Please select at least one value",
        inputs: searchModel.schema.alternateTreatment.searchInputs[0],
        filter: (input: any, getValues: (arg0: string) => any) => {
          if (
            getValues("selectprescriptiontype") ===
            (input.name || input.model.label)
          )
            return true;
          return false;
        },
        onNext: async (getValues: () => any, setValue: any) => {
          const res = await findMoAGrpForIngredient(getValues());
          setValue("possibleClasses", res.data);
        },
        type: "modelselectinput",
        required: true,
        default: null,
      },
      {
        key: "selecttreatementtype",
        inputs: [
          {
            key: "selecttreatementtype",
            title: "Preferred treatement class?",
            valueOptions: [
              { display: "Mechanism Of Action", value: "MoA" },
              {
                display: "Group",
                value: "Group",
              },
            ],
            type: "multiplechoice",
          },
        ],
        type: "multiplechoice",
        required: true,
        default: null,
      },
      {
        key: "treamentclass",
        validationType: "onefromlist",
        errorMessage: "Please select at least one value",
        inputs: [
          {
            title: "Select one to treat",
            keyToSend: (getValues: (arg0: string) => any) =>
              getValues("selecttreatementtype"),
            valueOptions: (getValues: any) => {
              const values = getValues("possibleClasses");
              if (!values) return [];
              const selected = getValues("selecttreatementtype");
              return values
                .map((value: any) => {
                  if (value[selected]) return value[selected];
                })
                .filter((fl: any) => !!fl);
            },
            type: "selectfromlist",
            validationChecker: (getValues: any) => {},
          },
        ],
        type: "selectfromlist",
        required: true,
        default: null,
        lastStep: true,
      },
    ],
  },
};
