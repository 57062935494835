import { Children } from "helpers/types";
import {
  ArcherContainer,
  ArcherContainerRef,
  ArcherElement,
} from "react-archer";
import { LineType, RelationType } from "react-archer/lib/types";
import {
  getRootRelation,
  middleBottomRowStyle,
  middleTopRowStyle,
  positionMap,
  rootStyle,
  rowStyle,
} from "helpers/archer";
import _ from "lodash";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { Box } from "@mui/material";
import { useEffect, useRef } from "react";
type Root = {
  id: string;
  children: Children;
  rootRelations?: RelationType[];
};
type SimpleElement = {
  id: string;
  relations?: RelationType[];
  children: Children;
};
type Element = {
  id: string;
  children: Children;
  relation: string;
  arrow: ReactJSXElement | string;
};
export function Element({ id, relations, children }: SimpleElement) {
  return (
    <ArcherElement id={id} relations={relations}>
      <Box>{typeof children === "function" ? children() : children}</Box>
    </ArcherElement>
  );
}
export function Root({ id, children, rootRelations }: Root) {
  return (
    <ArcherElement id={id} relations={rootRelations}>
      <Box>{typeof children === "function" ? children() : children}</Box>
    </ArcherElement>
  );
}
export function DefaultDraw({
  root,
  elements,
  style,
}: {
  root: Root[];
  elements: Element[];
  style?: LineType;
}) {
  let chunkSize = 4;
  const chunks = _.chunk(elements, chunkSize);
  if (chunks.length > 4) return <>Cannot display in relation view</>;
  const newPositions = positionMap.filter((pm, index) => !!chunks[index]);
  const rootRelationsMap: { [key: string]: RelationType[] } = {};
  root.forEach((rt: Root) => {
    rootRelationsMap[rt.id] = [];
  });
  newPositions.map((position, index) => {
    return chunks[index].map((arr: Element) => {
      const { id, arrow, relation } = arr;
      rootRelationsMap[relation].push(
        getRootRelation(id, position, arrow, style),
      );
    });
  });
  return (
    <>
      {newPositions[2] && (
        <Box style={rowStyle}>
          {chunks[2].map((ind: Element) => (
            <Element id={ind.id} key={ind.id}>
              {ind.children}
            </Element>
          ))}
        </Box>
      )}
      {newPositions[3] && (
        <Box style={middleTopRowStyle}>
          {chunks[3].length < 4 && <Box width={"100px"}></Box>}
          {[0, 1].map((index) => {
            const ind = chunks[3][index];
            if (ind)
              return (
                <Element id={ind.id} key={ind.id}>
                  {ind.children}
                </Element>
              );
            return null;
          })}
          {[2, 3].map((index) => {
            const ind = chunks[3][index];
            if (ind)
              return (
                <Element id={ind.id} key={ind.id}>
                  {ind.children}
                </Element>
              );
            return null;
          })}
        </Box>
      )}
      <Box style={rootStyle}>
        {root &&
          root.map((rt: Root) => (
            <Box sx={{ ml: 2, mr: 2 }} key={rt.id}>
              <Root
                id={rt.id}
                rootRelations={[
                  ...rootRelationsMap[rt.id],
                  ...(rt.rootRelations ? rt.rootRelations : []),
                ]}
              >
                {rt.children}
              </Root>
            </Box>
          ))}
      </Box>
      {newPositions[1] && (
        <Box style={middleBottomRowStyle}>
          {chunks[1].length < 4 && <Box width={"100px"}></Box>}
          {[0, 1].map((index) => {
            const ind = chunks[1][index];
            if (ind)
              return (
                <Element id={ind.id} key={ind.id}>
                  {ind.children}
                </Element>
              );
            return null;
          })}
          {[2, 3].map((index) => {
            const ind = chunks[1][index];
            if (ind)
              return (
                <Element id={ind.id} key={ind.id}>
                  {ind.children}
                </Element>
              );
            return null;
          })}
        </Box>
      )}
      {newPositions[0] && (
        <Box style={rowStyle}>
          {chunks[0].map((ind: Element) => (
            <Element id={ind.id} key={ind.id}>
              {ind.children}
            </Element>
          ))}
        </Box>
      )}
    </>
  );
}
export default function Container({
  strokeColor,
  children,
  style,
}: {
  strokeColor: string;
  children?: Children;
  style?: any;
}) {
  const container = useRef<ArcherContainerRef | null>(null);
  const handleScroll = () => {
    if (container && container.current && container.current.refreshScreen) {
      container.current.refreshScreen();
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);
    return window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <ArcherContainer
      ref={container}
      strokeColor={strokeColor}
      style={style || { width: "700px" }}
    >
      {typeof children === "function" ? children() : children}
    </ArcherContainer>
  );
}
