import _ from "lodash";
import { Data, GraphData, Node, Path, Return } from "./types";
export const getNodeColor = (label: string) => {
  let color = "grey";
  switch (label) {
    case "Group":
      color = "rgb(141, 204, 147)";
      break;
    case "Ingredient":
      color = "rgb(241, 102, 103)";
      break;
    case "IngredientGroup":
      color = "rgb(255, 196, 84)";
      break;
    case "Manufacturer":
      color = "rgb(76, 142, 218)";
      break;
    case "Medication":
      color = "rgb(201, 144, 192)";
      break;
    case "SerialNumber":
      color = "rgb(247, 151, 103)";
      break;
    case "SideEffect":
      color = "rgb(217, 200, 174)";
      break;
    case "SideEffectGroup":
      color = "rgb(218, 113, 148)";
      break;
    case "Target":
      color = "rgb(87, 199, 227)";
      break;
    case "Type":
      color = "rgb(236, 181, 201)";
      break;
    case "User":
      color = "rgb(255, 224, 129)";
      break;
    default:
      color = "grey";
  }
  return color;
};

export const circleTextNodeDisplay = (
  node: Node,
  ctx: CanvasRenderingContext2D,
) => {
  ctx.beginPath();
  const circleSize = 8;
  ctx.fillStyle = getNodeColor(node.label || "");
  ctx.arc(node.x || 0, node.y || 0, circleSize, 0, Math.PI * 2, false);
  ctx.fill();
  ctx.lineWidth = 0.3;
  ctx.strokeStyle = "black";
  ctx.stroke();

  ctx.beginPath();
  const fontSize = 3;
  ctx.font = `${fontSize}px Sans-Serif`;
  // ctx.fontWeight = "bold";
  ctx.textAlign = "center";
  ctx.textBaseline = "middle";
  ctx.fillStyle = "black";
  ctx.fillText(node.name || "", node.x || 0, node.y || 0, circleSize * 2);
  ctx.fill();
};

export const getGraphDataFromPath = (data: Path[]) => {
  const graphData: GraphData = { nodes: [], links: [] };
  if (!data) return graphData;
  data.forEach((path: Path) => {
    path.forEach((dt) => {
      if (!dt) return;
      const { start, relationship, end } = dt;
      const { identity: startId } = start;
      const { identity: endId } = end;
      graphData.nodes.push({
        name: start.name,
        ...start.properties,
        ...start,
      });
      graphData.nodes.push({
        name: end.name,
        ...end.properties,
        ...end,
      });
      graphData.links.push({
        ...relationship,
        start: startId,
        end: endId,
      });
    });
  });
  return {
    nodes: _.uniqBy(graphData.nodes, "identity"),
    links: graphData.links,
  };
};

export const getGraphDataFromQuery = (data: Data, returns: Return) => {
  const graphData: GraphData = { nodes: [], links: [] };
  if (!data) return graphData;
  const [nodeKeys, linkKeys] = returns;
  data.forEach((dt: Data) => {
    if (!dt) return;
    nodeKeys.forEach((key) => {
      if (dt[key])
        graphData.nodes.push({
          ...dt[key],
          name: dt[key].name,
          properties: dt[key],
        });
    });
    linkKeys.forEach((key) => {
      if (dt[key]) graphData.links.push({ ...dt[key], properties: dt[key] });
    });
  });
  return {
    nodes: _.uniqBy(graphData.nodes, "identity"),
    links: _.uniqBy(graphData.links, "identity"),
  };
};
