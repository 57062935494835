import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MUISelect,
} from "@mui/material";
import { camelCaseToString } from "helpers/common";
import { ControllerRenderProps } from "react-hook-form";

export default function Select({
  field,
  multiple,
  autoFocus,
  valueOptions = [],
  mapped,
  title,
}: {
  field: ControllerRenderProps;
  autoFocus: boolean;
  multiple: boolean;
  valueOptions?: any[];
  mapped?: boolean;
  title?: string;
}) {
  const { name } = field;
  return (
    <FormControl fullWidth variant="standard">
      <InputLabel>{title || camelCaseToString(name)}</InputLabel>
      <MUISelect
        fullWidth
        multiple={multiple}
        id={name}
        type="number"
        autoFocus={autoFocus}
        {...field}
      >
        {valueOptions &&
          valueOptions.map((option) => {
            let itemValue;
            let itemDisplay;
            itemValue = option;
            itemDisplay = option;
            if (mapped) {
              itemValue = option.value;
              itemDisplay = option.display;
            }
            return (
              <MenuItem
                id={`menuitem-${name}-${itemValue}`}
                key={`menuitem-${name}-${itemValue}`}
                value={itemValue}
              >
                {itemDisplay}
              </MenuItem>
            );
          })}
      </MUISelect>
    </FormControl>
  );
}
