import { Data } from "../helpers/types";
import { getReq, postReq } from "./instance";

export const getBrandName = async (brandNameId: string, params = {}) => {
  return getReq(`/BrandName/${brandNameId}`, params);
};
export const getBrandNameSideEffects = async (properties: {}, params = {}) => {
  return postReq(`BrandName/sideeffects`, properties, params);
};

export const getBrandNameRelations = async (id: string, params = {}) => {
  return postReq(`/BrandName/${id}`, params);
};

export const getBrandNameSideEffectPaths = async (
  sideEffectId: string,
  properties: Data,
  params = {},
) => {
  return postReq(`BrandName/sideeffects/${sideEffectId}`, properties, params);
};
