import { Data } from "../helpers/types";
import { deleteReq, getReq, postReq, putReq } from "./instance";

export const getSource = async (sourceId: string, params = {}) => {
  return getReq(`/sources/${sourceId}`, params);
};

export const getSources = async (properties: Data, params = {}) => {
  return postReq(`/sources/getmany`, properties, params);
};

export const getAllSources = async (params = {}) => {
  return getReq("/sources", params);
};

export const editSource = async (
  sourceId: string,
  properties: {},
  params = {},
) => {
  return putReq(`/sources/${sourceId}`, properties, params);
};

export const createSource = async (properties: {}, params = {}) => {
  return postReq("/sources", properties, params);
};

export const deleteSource = async (sourceId: string) => {
  return deleteReq(`/sources/${sourceId}`);
};

export const editSourceRelationshipProperties = async (
  relationId: string,
  properties: {},
  params = {},
) => {
  return putReq(`/sources/relation/${relationId}`, properties, params);
};
