import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { BasicData, Data, Model } from "helpers/types";
import InputByType from "inputs/InputByType";
import ModelSelectInput from "inputs/ModelSelectInput";
import { patientPagesModel } from "models";
import { Target } from "models/basicModels";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Title from "components/Title";
import useApiHook from "hooks/useApiHook";
import useConfirm from "hooks/useConfirm";
import dayjs from "dayjs";
import GetDate from "components/GetDate";
import { searchSchemaValidation } from "schema/commonSchema";
import MyModal from "components/Modal";
import useModalHook from "hooks/useModalHook";
import PatientAnalysis from "components/PatientAnalysis";
import ApiStatus from "components/ApiStatus";
import CustomLoader from "components/CustomeLoader";

const modalSx = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  // height: "60vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
};

export default function CreateEditPrescription({
  model,
  patientId,
  getPatient,
}: {
  model: Model;
  patientId: string;
  getPatient: Function;
}) {
  const { api } = model;
  const { searchInputs, targetInput, dateSchema } = patientPagesModel;
  const [selectedPrescriptionInput, setSelectedPrescriptionInput] = useState<
    string | null
  >(Object.keys(searchInputs)[0]);
  const [getConfirmation, Confirmation] = useConfirm((onChange) => (
    <GetDate onConfirm={onChange} />
  ));
  const [openAnalysis, handleOpenAnalysis, closeAnalysis] = useModalHook();
  const [prescription, addPrescription] = useApiHook<Data>(
    {},
    api?.custom?.addPrescription,
  );
  const [newAnalysis, checkPrescription] = useApiHook<Data>(
    {},
    api?.custom?.checkPrescription,
  );
  const {
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting, errors, isSubmitted },
    watch,
    getValues,
    setError,
  } = useForm<Data>({
    resolver: yupResolver(
      searchSchemaValidation([
        searchInputs[selectedPrescriptionInput || "activeIng"],
        // targetInput,
      ]),
    ),
    defaultValues: {},
  });
  const {
    model: inputModel,
    multiple,
    name,
    filter,
    searchBy,
    where,
    type,
    findByString,
    secondarySelect,
  } = searchInputs[selectedPrescriptionInput || "activeIng"];
  const { label: modelLabel } = inputModel;
  const inputLabel = name || modelLabel;
  const { getRelated } = inputModel.api;
  const [secondaryList, getSecondaryList] = useApiHook<BasicData>(
    [],
    getRelated,
  );
  useEffect(() => {
    const value = getValues(inputLabel);
    if (value?.length > 0) {
      getSecondaryList(value[0], { filter: [secondarySelect?.relation] });
    }
  }, [watch(inputLabel)]);
  if (!secondarySelect) return <></>;
  const onCheck: SubmitHandler<Data> = async (values: Data, e) => {
    if (e) e.preventDefault();
    const secondaryLabel = secondarySelect.model.label;
    const secondaryRelation = secondarySelect.relation;
    const {
      Target,
      datePrescribed,
      [secondaryLabel]: Secondary,
      [inputModel.label]: Primary,
    } = values;
    if (
      !Secondary &&
      secondaryList &&
      secondaryList.data &&
      Object.keys(secondaryList.data).length > 0 &&
      secondaryList.data[secondaryRelation]?.length > 0
    ) {
      setError(secondaryLabel, {
        type: "manual",
        message: `${secondaryLabel} is required`,
      });
      return;
    }
    const prescriptionId = Primary[0];
    await checkPrescription({
      prescriptionId,
      Target,
      [secondaryLabel]: Secondary,
      patientId: patientId,
      datePrescribed: datePrescribed || Date.now(),
    });
    handleOpenAnalysis();
  };
  const onSubmit: SubmitHandler<Data> = async (values: Data, e) => {
    if (e) e.preventDefault();
    const secondaryLabel = secondarySelect.model.label;
    const secondaryRelation = secondarySelect.relation;
    const {
      Target,
      datePrescribed,
      [secondaryLabel]: Secondary,
      [inputModel.label]: Primary,
    } = values;
    if (
      !Secondary &&
      secondaryList &&
      secondaryList.data &&
      Object.keys(secondaryList.data).length > 0 &&
      secondaryList.data[secondaryRelation]?.length > 0
    ) {
      setError(secondaryLabel, {
        type: "manual",
        message: `${secondaryLabel} is required`,
      });
      return;
    }
    const prescriptionId = Primary[0];
    const res = await addPrescription({
      prescriptionId,
      Target,
      [secondaryLabel]: Secondary,
      patientId: patientId,
      datePrescribed: datePrescribed || Date.now(),
    });
    if (res.patientId) {
      reset();
      getPatient();
    }
  };
  return (
    <Container component="form" onSubmit={handleSubmit(onSubmit)}>
      <ApiStatus
        error={newAnalysis.error}
        isLoading={newAnalysis.isLoading}
        customLoader={
          <CustomLoader
            loaderText={
              "Performing analysis on all previous prescriptions with the new addition, this might take some time"
            }
          ></CustomLoader>
        }
      />
      <ApiStatus
        error={prescription.error}
        isLoading={prescription.isLoading}
      />
      <Grid container>
        <Grid
          container
          item
          xs={12}
          md={12}
          lg={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {selectedPrescriptionInput && (
            <Grid container>
              <Grid item xs={12} md={12} lg={12} sx={{ p: 1 }}>
                <Title>
                  Select one {inputLabel} and one or multiple targets
                </Title>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Prescription Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedPrescriptionInput}
                    label="Prescription Type"
                    onChange={(e) =>
                      setSelectedPrescriptionInput(e.target.value)
                    }
                  >
                    {Object.keys(searchInputs).map((key, index) => {
                      const { searchBy, model } = searchInputs[key];
                      const label = searchBy || model.label;
                      return (
                        <MenuItem
                          key={`${searchBy}-${index}-${label}`}
                          value={key}
                        >
                          {label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={6} sx={{ p: 1 }}>
                <Controller
                  control={control}
                  name={inputLabel}
                  render={({ field }) => {
                    return (
                      <ModelSelectInput
                        model={inputModel}
                        multiple={multiple}
                        field={field}
                        filter={filter}
                        searchBy={searchBy}
                        where={where}
                        type={type}
                        findByString={findByString}
                      />
                    );
                  }}
                />
                {isSubmitted && Boolean(errors[inputLabel]) && (
                  <Box sx={{ width: "100%", mt: 1 }}>
                    <Alert severity="error" icon={false}>
                      {errors[inputLabel]?.message?.toString()}
                    </Alert>
                  </Box>
                )}
                {secondarySelect &&
                  secondarySelect.relation &&
                  secondaryList.data[secondarySelect.relation] && (
                    <Box sx={{ mt: 1 }}>
                      <Controller
                        control={control}
                        name={secondarySelect.model.label}
                        render={({ field }) => {
                          return (
                            <Box>
                              {secondaryList.data[secondarySelect.relation] && (
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    {secondarySelect.model.label} Selected
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    displayEmpty
                                    id="demo-simple-select"
                                    value={field.value || ""}
                                    label="Prescription Type"
                                    onChange={(e) =>
                                      field.onChange(e.target.value)
                                    }
                                  >
                                    {secondaryList.data[
                                      secondarySelect.relation
                                    ].map((rel: BasicData, index: number) => {
                                      const { id, name } = rel;
                                      return (
                                        <MenuItem
                                          key={`${searchBy}-${index}`}
                                          value={id}
                                        >
                                          {name}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              )}
                            </Box>
                          );
                        }}
                      />
                      {isSubmitted &&
                        Boolean(errors[secondarySelect.model.label]) && (
                          <Box sx={{ width: "100%", mt: 1 }}>
                            <Alert severity="error" icon={false}>
                              {errors[
                                secondarySelect.model.label
                              ]?.message?.toString()}
                            </Alert>
                          </Box>
                        )}
                    </Box>
                  )}
              </Grid>
              <Grid item xs={12} md={6} lg={6} sx={{ p: 1 }}>
                <Controller
                  control={control}
                  name={Target.label}
                  render={({ field }) => (
                    <ModelSelectInput
                      model={Target}
                      multiple={targetInput.multiple}
                      field={field}
                      searchBy={targetInput.searchBy}
                      type={targetInput.type}
                      returnIds={false}
                      addInput={async (res) => {
                        const date = await getConfirmation(
                          `Symptom "${res.name}" start date`,
                        );
                        return { ...res, dateStarted: date };
                      }}
                      secondaryDisplay="dateStarted"
                      secondaryMutate={(date) => dayjs(date).format("L")}
                    />
                  )}
                />
                {isSubmitted && Boolean(errors[Target.label]) && (
                  <Box sx={{ width: "100%", mt: 1 }}>
                    <Alert severity="error" icon={false}>
                      {errors[Target.label]?.message?.toString()}
                    </Alert>
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={6} sx={{ p: 1 }}>
                <Controller
                  control={control}
                  name={dateSchema.property}
                  render={({ field, fieldState }) => (
                    <InputByType
                      schema={dateSchema}
                      field={field}
                      fieldState={fieldState}
                      autoFocus={false}
                    />
                  )}
                />
                {isSubmitted && Boolean(errors[dateSchema.property]) && (
                  <Box sx={{ width: "100%", mt: 1 }}>
                    <Alert severity="error" icon={false}>
                      {errors[dateSchema.property]?.message?.toString()}
                    </Alert>
                  </Box>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button variant="contained" onClick={handleSubmit(onCheck)}>
            Check prescription
          </Button>
        </Grid>
      </Grid>
      <Confirmation />
      <MyModal sx={modalSx} open={openAnalysis} onClose={closeAnalysis}>
        <Box>
          <Title sx={{ ml: 3 }}>Patient Analysis with new prescriptions</Title>
          <Box sx={{ overflow: "auto", height: "40vw" }}>
            <PatientAnalysis data={newAnalysis.data} />
            <Button
              variant="contained"
              type="submit"
              onClick={handleSubmit(onSubmit)}
              sx={{ m: 1 }}
            >
              Confirm prescription
            </Button>
          </Box>
        </Box>
      </MyModal>
    </Container>
  );
}
