import { Box, Button, Grid, Typography } from "@mui/material";

export default function ConfirmMessage({
  onConfirm,
  message,
  buttonText,
}: {
  message: string;
  buttonText: string;
  onConfirm: (val: boolean) => void;
}) {
  return (
    <Box sx={{ m: 2, p: 2 }}>
      <Grid container>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{
            mb: 3,
          }}
        >
          <Typography>{message}</Typography>
        </Grid>
        <Grid
          item
          xs={6}
          md={6}
          lg={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button variant="contained" onClick={() => onConfirm(true)}>
            {buttonText}
          </Button>
        </Grid>
        <Grid
          item
          xs={6}
          md={6}
          lg={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button variant="contained" onClick={() => onConfirm(false)}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
