import React, { useEffect, useState } from "react";
import {
  getWindowDimension,
  IdDeviceBreakpointsByWidth,
  IdMobileHeight,
} from "../helpers/utilResponsive";
import { Children } from "helpers/types";
const { width, height } = getWindowDimension();
const initialState = { width, height };

const shouldRender = (
  display: string | string[],
  width: number,
  height: number,
) => {
  if (
    display.indexOf("Laptop") !== -1 &&
    width >= IdDeviceBreakpointsByWidth.laptop_min
  ) {
    return true;
  }
  if (
    display.indexOf("Tablet") !== -1 &&
    width <= IdDeviceBreakpointsByWidth.tablet_max &&
    width >= IdDeviceBreakpointsByWidth.tablet_min
  ) {
    return true;
  }
  // For mobile regardless of orientation
  if (
    display.indexOf("Mobile") !== -1 &&
    width <= IdDeviceBreakpointsByWidth.mobile_max
  ) {
    return true;
  }
  if (
    display.indexOf("MobilePortrait") !== -1 &&
    width <= IdDeviceBreakpointsByWidth.mobile_max &&
    height >= IdMobileHeight.mobileLandscape_max
  ) {
    return true;
  }
  return !!(
    display.indexOf("MobileLandScape") !== -1 &&
    width <= IdDeviceBreakpointsByWidth.mobile_max &&
    height <= IdMobileHeight.mobileLandscape_min
  );
};
function Responsive({
  children,
  displayIn,
}: {
  children: Children;
  displayIn: string[];
}) {
  const [{ width, height }, setState] = useState(initialState);
  const shouldRenderChildren = shouldRender(displayIn, width, height);
  const handleResize = () => {
    const { width, height } = getWindowDimension();
    setState({ width, height });
  };
  useEffect(() => {
    // Your code here
    window.addEventListener("resize", handleResize, false);
    return () => window.removeEventListener("resize", handleResize, false);
  }, []);

  return (
    <React.Fragment>
      {shouldRenderChildren
        ? typeof children === "function"
          ? children()
          : children
        : null}
    </React.Fragment>
  );
}
export default Responsive;
