import {
  Box,
  ListItemButton,
  ListItemText,
  SxProps,
  Theme,
} from "@mui/material";
import { Children } from "helpers/types";
import MyModal from "./Modal";
import useModalHook from "hooks/useModalHook";

export default function ModalSimple({
  children,
  primary,
  secondary,
  sx,
  textSx,
  row,
}: {
  children: Children;
  primary?: string;
  secondary?: string;
  sx?: SxProps<Theme>;
  textSx?: SxProps<Theme>;
  row?: Children;
}) {
  const [open, handleOpenModal, handleCloseModal] = useModalHook();
  return (
    <Box
      sx={{
        ":hover": {
          cursor: "pointer",
        },
        width: "inherit",
      }}
    >
      <ListItemButton
        onClick={(event) => {
          event.preventDefault();
          handleOpenModal();
        }}
      >
        <ListItemText
          sx={textSx || { mr: 1, ml: 1, width: "7vw" }}
          primaryTypographyProps={{
            sx: {
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            },
          }}
          primary={primary}
          secondary={secondary}
        />
        {typeof row === "function" ? row() : row}
      </ListItemButton>
      <MyModal
        open={open}
        onClose={handleCloseModal}
        sx={
          sx || {
            position: "absolute" as "absolute",
            top: "0%",
            right: "0%",
            width: "75vw",
            height: "100vw",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 1,
          }
        }
      >
        {typeof children === "function" ? children() : children}
      </MyModal>
    </Box>
  );
}
