import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Container, Grid, Paper, Typography } from "@mui/material";
import { acceptTermsAndConditions, myUserDetails } from "api";
import CheckBox from "components/CheckBox";
import Disclaimer from "components/Disclaimer";
import TermsAndConditions from "components/TermsAndConditions";
import { setGlobal } from "helpers/globals";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { redirect, useNavigate } from "react-router-dom";
import { termsAcceptSchema } from "schema/signupSchema";

type Inputs = {
  termsOfService: boolean;
};
export default function TermsOfServicePage() {
  const navigate = useNavigate();
  const { handleSubmit, control } = useForm<Inputs>({
    resolver: yupResolver(termsAcceptSchema),
  });
  const onSubmit: SubmitHandler<Inputs> = async (values: Inputs) => {
    if (values.termsOfService === true) {
      const res = await acceptTermsAndConditions();
      if (!res.error) {
        const { data: user } = await myUserDetails();
        setGlobal("user", JSON.stringify(user));
        navigate("/app");
      }
    }
  };
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper sx={{ p: 2 }}>
        <Container
          disableGutters
          maxWidth="sm"
          component="main"
          sx={{ pt: 8, pb: 6 }}
        >
          <Typography
            component="h1"
            variant="h4"
            align="center"
            color="primary"
            gutterBottom
          >
            Review and accept new Terms and Conditions
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              spacing={3}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid item>
                <Paper sx={{ overflow: "auto", height: "30vw" }}>
                  <TermsAndConditions />
                </Paper>
              </Grid>
              <Grid item>
                <Paper sx={{ overflow: "auto", height: "30vw" }}>
                  <Disclaimer />
                </Paper>
              </Grid>
              <Grid item>
                <Controller
                  control={control}
                  name="termsOfService"
                  render={({ field, fieldState }) => (
                    <CheckBox field={field} fieldState={fieldState}>
                      <Typography
                        component={"span"}
                        sx={{ margin: "auto 0" }}
                        fontSize={"small"}
                      >
                        {`I am a healthcare professional and agree to the terms of`}{" "}
                        LeaflitCare Agreement {`and the`} Disclaimer
                      </Typography>
                    </CheckBox>
                  )}
                />
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Paper>
    </Container>
  );
}
