import {
  Box,
  Grid,
  Button,
  List,
  ListItem,
  ListItemText,
  Radio,
  Typography,
} from "@mui/material";
import InputByType from "inputs/InputByType";
import { Controller } from "react-hook-form";
import QuickModelSelect from "./QuickModelSelect";
import {
  ReactElement,
  JSXElementConstructor,
  ReactNode,
  ReactPortal,
  Key,
} from "react";

export default function QuickInputSwitch({
  control,
  schema,
  namePrefix,
  onChange,
  getValues,
}: {
  schema: any;
  control: any;
  namePrefix: string;
  onChange: Function;
  getValues: any;
}) {
  const { type, key, inputs, filter } = schema;
  let quickInput;
  switch (type) {
    case "modelselectinput":
      quickInput = inputs.map((input: any, index: number) => {
        if (filter && !filter(input, getValues)) return;
        return (
          <Controller
            key={
              namePrefix +
              `.${key}.${index}.${input.model.name || input.model.label}`
            }
            control={control}
            name={`${input.name || input.model.label}`}
            render={({ field, fieldState }) => {
              return (
                <QuickModelSelect
                  schema={input}
                  field={field}
                  fieldState={fieldState}
                />
              );
            }}
          />
        );
      });
      break;
    case "selectfromlist":
      quickInput = inputs.map(
        (
          input: { title: string; valueOptions: any; notFound: string, keyToSend:any  },
          index: Key | null | undefined
        ) => {
          const values = input.valueOptions(getValues);
          if (!values || values.length === 0)
            return (
              <Typography
                key={
                  namePrefix +
                  `.${key}.${index}.${input.keyToSend(getValues)}`
                }
                component="h1"
                variant="h4"
                textAlign={"center"}
                sx={{ m: 2 }}
              >
                No {input.keyToSend(getValues)} found
              </Typography>
            );
          return (
            <Controller
              key={
                namePrefix +
                `${input.keyToSend(getValues)}`
              }
              control={control}
              name={`${input.keyToSend(getValues)}`}
              render={({ field, fieldState }) => {
                return (
                  <Box>
                    <Typography
                      component="h1"
                      variant="h4"
                      textAlign={"center"}
                      sx={{ m: 2 }}
                    >
                      {input.title}
                    </Typography>
                    {values.map(
                      (
                        value: {
                          name: string;
                          id: string;
                        },
                        indexValue: any
                      ) => {
                        const valueArray =
                          getValues(input.keyToSend(getValues)) || [];
                        const checked = valueArray.includes(value.id);
                        return (
                          <Box key={`${index}-${indexValue}`}>
                            <Radio
                              checked={checked}
                              onChange={(e) => {
                                field.onChange([e.target.value]);
                              }}
                              value={value.id}
                              name="radio-buttons"
                              inputProps={{ "aria-label": value.name }}
                            />{" "}
                            {value.name}
                          </Box>
                        );
                      }
                    )}
                  </Box>
                );
              }}
            />
          );
        }
      );
      break;
    default:
      quickInput = inputs.map((input: any, index: number) => {
        return (
          <Controller
            key={namePrefix + `.${key}.${index}.${input.key}`}
            control={control}
            name={input.key}
            render={({ field, fieldState }) => {
              return (
                <InputByType
                  schema={input}
                  field={field}
                  fieldState={fieldState}
                  autoFocus={false}
                  customOnChange={(value) => {
                    if (type === "multiplechoice") onChange(value);
                  }}
                />
              );
            }}
          />
        );
      });
      break;
  }
  return quickInput;
}
