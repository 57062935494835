import { getReq, postReq } from "./instance";

export const getAnalytics = async () => {
  return getReq("/searches/dashanal");
};

export const getDataEntryState = async () => {
  return getReq("/searches/dataentrystate");
};

export const getNodeRelationsById = async (properties: {}) => {
  return postReq(`/searches/graphnodes`, properties);
};

export const getAllLabelsAndTypes = async () => {
  return getReq("/searches/labelstypes");
};
export const findIngFromMedSE = async (properties: {}) => {
  return postReq("/searches/infrommedse", properties);
};

export const findMedFromIngGrp = async (properties: {}) => {
  return postReq("/searches/medfrominggrp", properties);
};

export const findMedFromIngTrg = async (properties: {}) => {
  return postReq("/searches/medfromingtrg", properties);
};

export const findMedFromIngGrpTrg = async (properties: {}) => {
  return postReq("/searches/medfrominggrptrg", properties);
};

export const findIncompatMedFromList = async (properties: {}) => {
  return postReq("/searches/incompatmedlist", properties);
};

export const findIncompatIngFromList = async (properties: {}) => {
  return postReq("/searches/incompatinglist", properties);
};

export const findAlternateTreatement= async (properties: {}) => {
  return postReq("/searches/alternatetreatment", properties);
};

export const findMoAGrpForIngredient= async (properties: {}) => {
  return postReq("/searches/listmoagrp", properties);
};

export const findTreatment = async (properties: {}) => {
  return postReq("/searches/treatnoinactive", properties);
};

export const sideEffectFind = async (properties: {}) => {
  return postReq("/searches/sideeffectfind", properties);
};
export const allSideEffects = async (properties: {}) => {
  return postReq("/searches/allsideeffects", properties); 
}
export const findFdaLabelWithInactive = async (properties: {}) => {
  return postReq("/searches/labelwithinactive", properties);
};

export const findAllIncompatMed = async (properties: {}) => {
  return postReq("/searches/allincompatmed", properties);
};

export const findMedWithIng = async (properties: {}) => {
  return postReq("/searches/medwithing", properties);
};

export const perscriptionSequence = async (properties: {}) => {
  return postReq("/searches/perscriptionsequence", properties);
};

export const findBy = async (properties: {}) => {
  return postReq("/searches/findby", properties);
};

export const findByWithFilter = (filter: string[]) => {
  return async (properties: {}) => {
    return postReq("/searches/findby", { ...properties, filter });
  };
};
export const getLabelFile = () => {
  return getReq("searches/getmodifiedlabelfile");
};
export const createLabelFile = (properties: {}) => {
  return postReq("searches/savemodifiedlabelfile", properties);
};

export const generalSearch = (route: string, properties: {}) => {
  return postReq(`searches/${route}`, properties);
};
